import lightTheme from './LightTheme';
import { createTheme, hex2rgba } from './utils';
import { Theme } from './types';
import * as tokens from '@bloomberg/crescent-tokens/build/js/dark/tokens';

const darkTheme = createTheme<Theme>(lightTheme, {
    name: 'dark',
    colors: {
        text: tokens.TokenColorTextPrimary,
        disabledText: tokens.TokenColorTextInsensitive,
        link: ({ colors }) => colors.grey[50],
        linkVisited: ({ colors }) => colors.grey[300],
        linkHoverBackground: ({ colors }) => colors.grey[650],
        background: tokens.TokenColorBackgroundPrimary,
        primary: {
            text: ({ colors }) => colors.grey[50],
            disabledBackground: ({ colors }) => colors.grey[650],
            disabledText: ({ colors }) => colors.grey[450],
        },
        error: {
            background: ({ colors }) => colors.red[100],
            graphic: ({ colors }) => colors.red[400],
            text: ({ colors }) => colors.red[400],
            hover: ({ colors }) => colors.red[300],
        },
        success: {
            background: ({ colors }) => colors.green[300],
        },
        warning: {
            background: ({ colors }) => colors.orange[300],
            text: ({ colors }) => colors.orange[500],
        },
        info: {
            background: ({ colors }) => colors.blue[200],
            graphic: ({ colors }) => colors.blue[500],
        },
        progress: {
            background: ({ colors }) => colors.purple[300],
        },
        default: {
            background: ({ colors }) => colors.grey[500],
            graphic: ({ colors }) => colors.grey[500],
            text: ({ colors }) => colors.grey[800],
            textContrast: ({ colors }) => colors.black,
            hover: ({ colors }) => colors.grey[400],
        },
        backgroundShades: {
            softest: ({ colors }) => colors.grey[800],
            soft: ({ colors }) => colors.grey[750],
            medium: ({ colors }) => colors.grey[650],
            strong: ({ colors }) => colors.grey[500],
        },
    },
    shadows: {
        default: '0 2px 4px 0 rgba(0,0,0,0.20)',
    },
    Button: {
        colors: {
            primary: {
                disabledText: tokens.TokenColorTextInsensitive,
                disabledBorder: tokens.TokenColorBackgroundInsensitive,
                disabledBackground: tokens.TokenColorBackgroundInsensitive,
            },
            secondary: {
                text: tokens.TokenColorTextPrimary,
                border: tokens.TokenColorBorder01,
                hoverText: tokens.TokenColorTextPrimary,
                hoverBorder: tokens.TokenColorBorder01,
                hoverBackground: tokens.TokenColorBackgroundHover,
                activeText: tokens.TokenColorTextPrimary,
                activeBorder: tokens.TokenColorBorder01,
                activeBackground: tokens.TokenColorBackgroundPressed,
                disabledText: tokens.TokenColorTextInsensitive,
                disabledBorder: tokens.TokenColorBorderInsensitive,
            },
            tertiary: {
                text: tokens.TokenColorTextPrimary,
                hoverText: tokens.TokenColorTextPrimary,
                hoverBorder: tokens.TokenColorBackgroundHover,
                hoverBackground: tokens.TokenColorBackgroundHover,
                activeText: tokens.TokenColorTextPrimary,
                activeBorder: tokens.TokenColorBackgroundPressed,
                activeBackground: tokens.TokenColorBackgroundPressed,
                disabledText: tokens.TokenColorTextInsensitive,
            },
            destruction: {
                disabledText: tokens.TokenColorTextInsensitive,
                disabledBorder: tokens.TokenColorBackgroundInsensitive,
                disabledBackground: tokens.TokenColorBackgroundInsensitive,
            },
        },
    },
    dropdowns: {
        colors: {
            background: ({ colors }) => colors.grey[750],
            text: ({ colors }) => colors.grey[200],
            selected: ({ colors }) => colors.white,
            hover: ({ colors }) => colors.grey[650],
            active: ({ colors }) => colors.grey[500],
            border: ({ colors }) => colors.black,
            groupHeader: ({ colors }) => colors.grey[350],
            groupDivider: ({ colors }) => colors.grey[500],
        },
    },
    tables: {
        colors: {
            background: ({ colors }) => colors.grey[800],
            backgroundHover: ({ colors }) => colors.grey[550],
            border: ({ colors }) => colors.grey[600],
            highlight: ({ colors }) => colors.grey[500],
            headerBackground: ({ colors }) => colors.black,
            headerBackgroundHover: ({ colors }) => colors.grey[550],
            resizeHandle: ({ colors }) => colors.grey[200],
            resizingCellBorder: ({ colors }) => colors.grey[500],
            sortIndicatorDisabled: ({ colors }) => colors.grey[500],
            sortIndicatorGhost: ({ colors }) => colors.grey[400],
            text: ({ colors }) => colors.white,
        },
    },
    formElements: {
        colors: {
            hover: ({ colors }) => colors.blue[400],
            background: ({ colors }) => colors.black,
            disabledBackground: ({ colors }) => colors.grey[700],
            border: ({ colors }) => colors.grey[600],
            readOnly: ({ colors }) => colors.grey[600],
            placeholder: ({ colors }) => colors.grey[350],
            addon: ({ colors }) => colors.grey[600],
            highlight: ({ colors }) => colors.blue[500],
        },
        options: {
            colors: {
                background: ({ colors }) => colors.grey[750],
                disabledText: ({ colors }) => colors.grey[450],
                text: ({ colors }) => colors.grey[250],
                highlight: ({ colors }) => colors.grey[550],
                selectedText: ({ colors }) => colors.white,
                indicator: ({ colors }) => colors.grey[100],
            },
        },
    },
    header: {
        colors: {
            background: ({ colors }) => colors.black,
            text: ({ colors }) => colors.white,
            titleBar: {
                border: ({ colors }) => colors.grey[500],
            },
            navBar: {
                border: ({ colors }) => colors.grey[400],
            },
            menu: {
                active: ({ colors }) => colors.blue[500],
                background: ({ colors }) => colors.grey[700],
                hover: ({ colors }) => colors.grey[700],
            },
            link: {
                default: ({ colors }) => colors.grey[200],
                active: ({ colors }) => colors.blue[400],
                hover: 'rgba(255, 255, 255, 0.13)',
                selected: 'rgba(0, 115, 255, 0.14)',
                hoverSelected: 'rgba(0, 115, 255, 0.22)',
            },
        },
    },
    popovers: {
        colors: {
            text: ({ colors }) => colors.white,
            hover: ({ colors }) => colors.grey[600],
            border: ({ colors }) => colors.grey[750],
            background: ({ colors }) => colors.grey[700],
            divider: ({ colors }) => colors.grey[550],
        },
    },
    tabs: {
        colors: {
            background: 'rgba(225,225,225,0.04)',
            hover: 'rgba(225,225,225,0.13)',
            pressed: 'rgba(225,225,225,0.32)',
            border: ({ colors }) => colors.grey[150],
            disabledText: ({ colors }) => colors.grey[400],
            supplementaryValues: ({ colors }) => colors.grey[350],
        },
    },
    Card: {
        colors: {
            hoverBackground: ({ colors }) => colors.grey[800],
        },
    },
    DatePicker: {
        colors: {
            text: ({ colors }) => colors.grey[200],
            background: ({ colors }) => colors.grey[750],
            hoverBackground: ({ colors }) => colors.grey[550],
            pressedBackground: ({ colors }) => colors.grey[650],
            selectedBorder: ({ colors }) => colors.white,
            disabledText: ({ colors }) => colors.grey[500],
            persistentBorder: ({ colors }) => colors.grey[500],
        },
    },
    Dropzone: {
        colors: {
            border: ({ colors }) => `2px solid ${colors.grey[400]}`,
            hoverBackground: ({ colors }) => colors.blue[1000],
            hoverText: ({ colors }) => colors.blue[200],
            text: ({ colors }) => colors.white,
        },
    },
    GlobalStyles: {
        colors: {
            scrollbarThumb: 'rgba(255,255,255,0.4)',
        },
    },
    NavList: {
        colors: {
            activeBackground: ({ colors }) => hex2rgba(colors.blue[500], 0.14),
            activeBorder: ({ colors }) => colors.blue[600],
            background: ({ colors }) => colors.background,
            hover: ({ colors }) => hex2rgba(colors.white, 0.13),
            hoverSelected: ({ colors }) => hex2rgba(colors.blue[500], 0.22),
            secondaryBackground: ({ colors }) => hex2rgba(colors.white, 0.08),
            secondaryBorder: ({ colors }) => colors.grey[500],
            secondaryHoverSelected: ({ colors }) => hex2rgba(colors.white, 0.13),
        },
    },
    Overlay: {
        colors: {
            background: ({ colors }) => hex2rgba(colors.black, 0.74),
        },
    },
    ProgressMeter: {
        colors: {
            emptyTrack: ({ colors }) => colors.grey[550],
        },
    },
    Slider: {
        colors: {
            emptyTrack: ({ colors }) => colors.grey[600],
            activeTrack: ({ colors }) => colors.primary.action,
            handleBackground: ({ colors }) => colors.white,
            handleBorder: ({ colors }) => colors.primary.hover,
            disabledHandleBorder: ({ colors }) => colors.primary.disabledText,
        },
    },
    Stepper: {
        colors: {
            normal: ({ colors }) => colors.text,
            border: ({ colors }) => colors.grey[150],
            disabled: ({ colors }) => colors.grey[400],
            hover: ({ colors }) => hex2rgba(colors.white, 0.13),
            pressed: ({ colors }) => colors.grey[600],
            activeBackground: ({ colors }) => hex2rgba(colors.blue[500], 0.14),
        },
    },
    Switch: {
        colors: {
            inactiveBorder: ({ colors }) => colors.grey[150],
            activeBorder: ({ colors }) => colors.grey[150],
            disabledBorder: ({ colors }) => colors.grey[550],
            track: ({ colors }) => colors.grey[850],
            active: ({ colors }) => colors.blue[500],
            inactive: ({ colors }) => colors.grey[200],
            hover: ({ colors }) => colors.grey[650],
            disabled: ({ colors }) => colors.grey[550],
        },
    },
    Tile: {
        colors: {
            border: ({ colors }) => colors.black,
            background: ({ colors }) => colors.grey[850],
            divider: ({ colors }) => colors.white,
        },
    },
    TimePicker: {
        colors: {
            background: ({ colors }) => colors.grey[750],
            selectedBackground: ({ colors }) => colors.grey[500],
            hoverBackground: ({ colors }) => colors.grey[500],
        },
    },
    ToggleButtonBar: {
        colors: {
            background: ({ colors }) => colors.background,
            border: ({ colors }) => colors.grey[150],
            text: ({ colors }) => colors.grey[150],
            hoverBackground: ({ colors }) => colors.grey[700],
            hoverBorder: ({ colors }) => colors.grey[150],
            hoverText: ({ colors }) => colors.grey[150],
            activeBackground: ({ colors }) => colors.grey[600],
            activeBorder: ({ colors }) => colors.grey[150],
            activeText: ({ colors }) => colors.grey[150],
            selectedBackground: ({ colors }) => colors.grey[50],
            selectedBorder: ({ colors }) => colors.grey[50],
            selectedText: ({ colors }) => colors.grey[700],
        },
    },
    Token: {
        colors: {
            background: ({ colors }) => colors.grey[650],
            progressBackground: ({ colors }) => colors.grey[750],
            activeBackground: ({ colors }) => colors.grey[400],
        },
    },
    ContextualMessage: {
        colors: {
            info: {
                graphic: ({ colors }) => colors.grey[700],
                textContrast: ({ colors }) => colors.white,
                hover: ({ colors }) => colors.grey[600],
            },
            warning: {
                graphic: ({ colors }) => colors.yellow[300],
                textContrast: ({ colors }) => colors.black,
                hover: ({ colors }) => colors.yellow[600],
            },
        },
    },
});
export default darkTheme;
