import styled, { css } from 'styled-components';
import { SwitchProps } from './Switch.types';
import { MOUSE_MODE_CLASS } from '../FocusManager';

export const SwitchContainer = styled.label<SwitchProps>`
    position: relative;
    display: inline-flex;
    vertical-align: middle;

    ${({ labelPosition }) =>
        labelPosition === 'right' &&
        css`
            flex-direction: row-reverse;
        `}
    ${({ labelPosition }) =>
        labelPosition === 'top' &&
        css`
            flex-direction: column;
        `}

    /*  The checkbox is hidden. It is replaced by the switch styles. */
    > input[type='checkbox'] {
        opacity: 0;
        height: 0;
        width: 0;
        position: absolute;
        background-color: transparent;

        ~ .bbui-switch__track {
            border: 1px solid ${({ theme }) => theme.Switch.colors.inactiveBorder};
            background-color: ${({ theme }) => theme.Switch.colors.track};
        }

        &:disabled ~ .bbui-switch__track {
            border-color: ${({ theme }) => theme.Switch.colors.disabledBorder};
        }

        &:not(:disabled) ~ .bbui-switch__track:hover {
            background-color: ${({ theme }) => theme.Switch.colors.hover};
        }

        &:checked ~ .bbui-switch__track {
            border-color: ${({ theme }) => theme.Switch.colors.activeBorder};
        }

        body:not(.${MOUSE_MODE_CLASS}) &:not(:disabled):focus ~ .bbui-switch__track {
            border-color: ${({ theme }) => theme.formElements.colors.focus};
            box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.formElements.colors.focus};
        }

        ~ .bbui-switch__track .bbui-switch__thumb {
            color: transparent;
            background: ${({ theme }) => theme.Switch.colors.inactive};
        }

        &:checked ~ .bbui-switch__track .bbui-switch__thumb {
            transform: translateX(16px);
            color: ${({ theme }) => theme.Switch.colors.track};
            background-color: ${({ theme }) => theme.Switch.colors.active};
        }

        &:disabled {
            ~ .bbui-switch__track {
                border-color: ${({ theme }) => theme.Switch.colors.disabledBorder};
                > .bbui-switch__thumb {
                    background-color: ${({ theme }) => theme.Switch.colors.disabled};
                }
            }
        }

        &:checked:disabled ~ .bbui-switch__track .bbui-switch__thumb {
            color: ${({ theme }) => theme.Switch.colors.track};
        }
    }

    > .bbui-switch__track {
        position: relative;
        user-select: none;
        width: 34px;
        height: 18px;
        border-radius: 18px;
        padding: 2px;
        line-height: 0;
        > .bbui-switch__thumb {
            height: 12px;
            width: 12px;
            border-radius: 12px;
            font-size: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 60ms ease-out;
        }
    }

    .bbui-switch__label {
        display: inline-block;
        align-self: center;
        flex: none;
        color: ${({ theme }) => theme.colors.text};
        font-size: ${({ theme }) => theme.fontSizes.base};

        &.labelTop {
            margin-bottom: 4px;
        }

        &.labelRight {
            margin-left: 12px;
        }

        &.labelLeft {
            margin-right: 12px;
        }
    }
`;
