import { css, createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const Normalize = css`
    ${normalize}
`;

const Typography = css`
    body {
        font-family: 'AvenirNextP2ForBBG', Arial, Helvetica, sans-serif;
    }
    /*
  Define font-family AvenirNextP2ForBBG

  Each weight has both normal and italic styles

  Font family weights:
    Heavy  - 900
    Bold   - 700
    Demi   - 600
    Medium - 500
    Normal - 400
    Light  - 300
    Thin   - 100
  */

    /* AvenirNextP2ForBBG-Heavy*/
    @font-face {
        font-family: 'AvenirNextP2ForBBG';
        font-display: fallback;
        font-weight: 900;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Heavy-5b0822db83.woff2')
                format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Heavy-3b108864bd.woff') format('woff');
    }

    /* AvenirNextP2ForBBG-HeavyItalic */
    @font-face {
        font-family: 'AvenirNextP2ForBBG';
        font-display: fallback;
        font-weight: 900;
        font-style: italic;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-HeavyItalic-38cab08278.woff2')
                format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-HeavyItalic-ad5430020f.woff')
                format('woff');
    }

    /* AvenirNextP2ForBBG-Bold */
    @font-face {
        font-family: 'AvenirNextP2ForBBG';
        font-display: fallback;
        font-weight: 700;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Bold-848b534204.woff2') format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Bold-845494278e.woff') format('woff');
    }

    /* AvenirNextP2ForBBG-BoldItalic */
    @font-face {
        font-family: 'AvenirNextP2ForBBG';
        font-display: fallback;
        font-weight: 700;
        font-style: italic;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-BoldItalic-f577e39577.woff2')
                format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-BoldItalic-860a65428e.woff')
                format('woff');
    }

    /* AvenirNextP2ForBBG-Demi */
    @font-face {
        font-family: 'AvenirNextP2ForBBG';
        font-display: fallback;
        font-weight: 600;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Demi-d3cb04a057.woff2') format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Demi-f362a7c4ad.woff') format('woff');
    }

    /* AvenirNextP2ForBBG-DemiItalic */
    @font-face {
        font-family: 'AvenirNextP2ForBBG';
        font-display: fallback;
        font-weight: 600;
        font-style: italic;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-DemiItalic-ce112bb955.woff2')
                format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-DemiItalic-166b289109.woff')
                format('woff');
    }

    /* AvenirNextP2ForBBG-Medium */
    @font-face {
        font-family: 'AvenirNextP2ForBBG';
        font-display: fallback;
        font-weight: 500;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Medium-dc5d08072d.woff2')
                format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Medium-72dcf6a6c2.woff') format('woff');
    }

    /* AvenirNextP2ForBBG-MediumItalic */
    @font-face {
        font-family: 'AvenirNextP2ForBBG';
        font-display: fallback;
        font-weight: 500;
        font-style: italic;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-MediumItalic-8a13467cd3.woff2')
                format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-MediumItalic-71db2048c8.woff')
                format('woff');
    }

    /* AvenirNextP2ForBBG-Regular */
    @font-face {
        font-family: 'AvenirNextP2ForBBG';
        font-display: fallback;
        font-weight: 400;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Regular-517a851989.woff2')
                format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Regular-80b65cbe3a.woff') format('woff');
    }

    /* AvenirNextP2ForBBG-Italic */
    @font-face {
        font-family: 'AvenirNextP2ForBBG';
        font-display: fallback;
        font-weight: 400;
        font-style: italic;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Italic-7107f3ab96.woff2')
                format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Italic-226936a4cb.woff') format('woff');
    }

    /* AvenirNextP2ForBBG-Light */
    @font-face {
        font-family: 'AvenirNextP2ForBBG';
        font-display: fallback;
        font-weight: 300;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Light-5175406f53.woff2')
                format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Light-ba85d34cc2.woff') format('woff');
    }

    /* AvenirNextP2ForBBG-LightItalic */
    @font-face {
        font-family: 'AvenirNextP2ForBBG';
        font-display: fallback;
        font-weight: 300;
        font-style: italic;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-LightItalic-f57090c0e2.woff2')
                format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-LightItalic-fe355e220d.woff')
                format('woff');
    }

    /* AvenirNextP2ForBBG-Thin */
    @font-face {
        font-family: 'AvenirNextP2ForBBG';
        font-display: fallback;
        font-weight: 100;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Thin-a559885f35.woff2') format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Thin-2f48f00020.woff') format('woff');
    }

    /* AvenirNextP2ForBBG-ThinItalic */
    @font-face {
        font-family: 'AvenirNextP2ForBBG';
        font-display: fallback;
        font-weight: 100;
        font-style: italic;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-ThinItalic-b8376f71a8.woff2')
                format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-ThinItalic-3792ff2042.woff')
                format('woff');
    }

    /* Roboto Monospace Font for Code Blocks and Inline Code */
    /* RobotoMono-Bold */
    @font-face {
        font-family: 'RobotoMono';
        font-display: fallback;
        font-weight: 700;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-BoldItalic-b8868a8a86.woff2') format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-BoldItalic-c363eb5209.woff') format('woff');
    }

    /* RobotoMono-BoldItalic */
    @font-face {
        font-family: 'RobotoMono';
        font-display: fallback;
        font-weight: 700;
        font-style: italic;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-BoldItalic-f577e39577.woff2')
                format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-BoldItalic-860a65428e.woff')
                format('woff');
    }

    /* RobotoMono-Medium */
    @font-face {
        font-family: 'RobotoMono';
        font-display: fallback;
        font-weight: 500;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Medium-f2563bb64f.woff2') format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Medium-210d82da68.woff') format('woff');
    }

    /* RobotoMono-MediumItalic */
    @font-face {
        font-family: 'RobotoMono';
        font-display: fallback;
        font-weight: 500;
        font-style: italic;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-MediumItalic-f543e48a0d.woff2') format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-MediumItalic-3ffa114322.woff') format('woff');
    }

    /* RobotoMono-Regular */
    @font-face {
        font-family: 'RobotoMono';
        font-display: fallback;
        font-weight: 400;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Regular-9b83813218.woff2') format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Regular-62db283fd7.woff') format('woff');
    }

    /* RobotoMono-Italic */
    @font-face {
        font-family: 'RobotoMono';
        font-display: fallback;
        font-weight: 400;
        font-style: italic;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Italic-231d18af59.woff2') format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Italic-ada1a06cff.woff') format('woff');
    }

    /* RobotoMono-Light */
    @font-face {
        font-family: 'RobotoMono';
        font-display: fallback;
        font-weight: 300;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Light-e5e77c869b.woff2') format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Light-7b9d8444f2.woff') format('woff');
    }

    /* RobotoMono-LightItalic */
    @font-face {
        font-family: 'RobotoMono';
        font-display: fallback;
        font-weight: 300;
        font-style: italic;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-LightItalic-3def77df5a.woff2') format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-LightItalic-286a84c78c.woff') format('woff');
    }

    /* RobotoMono-Thin */
    @font-face {
        font-family: 'RobotoMono';
        font-display: fallback;
        font-weight: 100;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Thin-e15b1b8579.woff2') format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Thin-845ca1f2eb.woff') format('woff');
    }

    /* RobotoMono-ThinItalic */
    @font-face {
        font-family: 'RobotoMono';
        font-display: fallback;
        font-weight: 100;
        font-style: italic;
        src: url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-ThinItalic-5f877bbef7.woff2') format('woff2'),
            url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-ThinItalic-c7f0e5e612.woff') format('woff');
    }
`;

const Scrollbar = css`
    body {
        scrollbar-width: thin;
        scrollbar-color: ${({ theme }) =>
            `${theme.GlobalStyles.colors.scrollbarThumb} ${theme.GlobalStyles.colors.scrollbarTrack}`};
    }

    /* Webkit scrollbar definitions */
    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar:horizontal {
        height: 8px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: ${({ theme }) => theme.GlobalStyles.colors.scrollbarThumb};
    }
    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-corner {
        background-color: ${({ theme }) => theme.GlobalStyles.colors.scrollbarTrack};
    }
`;

const Base = css`
    /* https://www.paulirish.com/2012/box-sizing-border-box-ftw/
    BB-UI Specific reset that cascades to all components */
    html {
        box-sizing: border-box;
        color: ${({ theme }) => theme.colors.text};
        background-color: ${({ theme }) => theme.colors.background};
    }
    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
    /** 
      elements with the class bbui-link, or any anchor under an element with
      the class bbui-link-wrapper gets this styling
     */
    .bbui-link,
    [data-bbui-link='true'],
    .bbui-link-wrapper a:not(.bbui-button),
    [data-bbui-link-wrapper='true'] a:not(.bbui-button) {
        color: ${({ theme }) => theme.colors.link};
        text-decoration: underline;
        &:visited {
            color: ${({ theme }) => theme.colors.linkVisited};
        }
        &:hover,
        &:active {
            background: ${({ theme }) => theme.colors.linkHoverBackground};
            outline: solid 2px ${({ theme }) => theme.colors.linkHoverBackground};
            outline-offset: 0;
        }
    }
`;

export const BaseStyles = createGlobalStyle`
    ${Base}
`;

export const NormalizeStyles = createGlobalStyle`
    ${Normalize}
`;

export const TypographyStyles = createGlobalStyle`
    ${Typography}
`;

export const ScrollbarStyles = createGlobalStyle`
    ${Scrollbar}
`;

export default createGlobalStyle`
    ${Normalize}
    ${Typography}
    ${Scrollbar}
    ${Base}
`;
