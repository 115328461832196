import React from 'react';

import { FCC } from '../../__utils__/types';

namespace FooterLink {
    export type Props = React.HTMLAttributes<HTMLLIElement>;
}

/**
 * Thin wrapper around a `<li />`, meant for rendering a link in the footer.
 *
 * @visibleName Footer.Link
 */
const FooterLink: FCC<FooterLink.Props> = ({ children, ...rest }) => {
    return (
        <li className="bbui-footer-links-list-item" {...rest}>
            {children}
        </li>
    );
};

export default FooterLink;
