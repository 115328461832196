import React, { Fragment } from 'react';
import compact from 'lodash/compact';

import {
    DropdownOption,
    DropdownValueOption,
    DropdownGroupOption,
    DropdownSpecialOption,
    DropdownOptionWithActions,
    DropdownAction,
} from './BaseDropdown.types';
import escapeForRegExp from '../__utils__/escapeForRegExp';

// regex must use a capturing group
export function mapLabel(labelValue: string, regex: RegExp) {
    const substringArray = compact(labelValue.split(regex));
    return (
        <Fragment>
            {substringArray.map((substring, index) =>
                regex.test(substring) ? (
                    <strong key={index}>{substring}</strong>
                ) : (
                    <Fragment key={index}>{substring}</Fragment>
                )
            )}
        </Fragment>
    );
}

export function stringToHighlightedLabel(str: string, filterText: string) {
    if (filterText && str.toLowerCase().includes(filterText.toLowerCase())) {
        const regex = new RegExp(`(${escapeForRegExp(filterText)})`, 'i');
        return mapLabel(str, regex);
    }
    return str;
}

export function defaultOptionRenderer<Value, Meta>(option: DropdownValueOption<Value, Meta>, filterText: string) {
    return stringToHighlightedLabel(option.label, filterText);
}

export function defaultGroupHeaderRenderer<Value, Meta>(group: DropdownGroupOption<Value, Meta>) {
    return (
        <div>
            {group.label}
            <span className="bbui-basedropdown-group-counter">{group.options.length}</span>
        </div>
    );
}

export function defaultSpecialOptionRenderer(option: DropdownSpecialOption) {
    return <div>{option.label}</div>;
}

export function defaultFilter<Value, Meta>(text: string, option: DropdownValueOption<Value, Meta>): boolean {
    return option.label.toLowerCase().includes(text);
}

export function defaultItemToString<Value, Meta>(item: DropdownOptionWithActions<Value, Meta> | null): string {
    return item?.label || '';
}

// Checks if an option object is an action or not
export function isAction(option?: DropdownOptionWithActions<unknown, unknown> | null): option is DropdownAction {
    return Boolean(option && 'onClick' in option);
}

// Checks if an option object is special or not
export function isSpecial(
    option?: DropdownOptionWithActions<unknown, unknown> | null
): option is DropdownSpecialOption {
    return Boolean(option && 'special' in option);
}

export function filterAndFlatten<Value, Meta>(
    options: Readonly<DropdownOption<Value, Meta>[]>,
    filterText = '',
    filter?: (filterText: string, option: DropdownValueOption<Value, Meta>) => boolean
): [DropdownOption<Value, Meta>[], DropdownValueOption<Value, Meta>[]] {
    const filtered: DropdownOption<Value, Meta>[] = filter ? [] : [...options];
    const flattened: DropdownValueOption<Value, Meta>[] = [];
    options.forEach((option) => {
        if ('options' in option) {
            if (filter) {
                const filteredOptions = option.options.filter((o) => filter(filterText, o));
                if (filteredOptions.length) {
                    filtered.push({ ...option, options: filteredOptions });
                    flattened.push(...filteredOptions);
                }
            } else {
                flattened.push(...option.options);
            }
        } else {
            if (filter) {
                if (filter(filterText, option)) {
                    filtered.push(option);
                    flattened.push(option);
                }
            } else {
                flattened.push(option);
            }
        }
    });
    return [filtered, flattened];
}

export function filterAndFlattenWithMax<Value, Meta>(
    options: Readonly<DropdownOption<Value, Meta>[]>,
    maxResults: number
): [DropdownOption<Value, Meta>[], DropdownValueOption<Value, Meta>[]] {
    const filtered: DropdownOption<Value, Meta>[] = [];
    const flattened: DropdownValueOption<Value, Meta>[] = [];
    for (const option of options) {
        // 'option' is a group option
        if ('options' in option) {
            const limitedOptions = option.options.slice(0, maxResults - flattened.length);
            filtered.push({
                ...option,
                options: limitedOptions,
            });
            flattened.push(...limitedOptions);
        } else {
            filtered.push(option);
            flattened.push(option);
        }
        if (flattened.length === maxResults) {
            return [filtered, flattened];
        }
    }

    return [filtered, flattened];
}
