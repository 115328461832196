import styled from 'styled-components';

export const StyledFooter = styled.footer<{ $width: string }>`
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.grey[350]};
    font-size: ${({ theme }) => theme.fontSizes.small};
    padding: 5px 20px;
    display: flex;
    small {
        font-size: ${({ theme }) => theme.fontSizes.small};
    }

    .bbui-footer-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: auto;
        width: ${({ $width }) => $width};
    }

    .bbui-footer-additional-content {
        margin: 20px 0;
        text-align: justify;
    }

    .bbui-footer-links {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .bbui-footer-links-list-item {
        display: inline;
        padding: 0 20px;
        border-right: ${({ theme }) => `1px solid ${theme.colors.grey[350]}`};

        &:first-of-type {
            padding-left: 0;
        }

        &:last-of-type {
            border-right: 0;
            padding-right: 0;
        }

        a {
            color: ${({ theme }) => theme.colors.grey[350]};
            text-decoration: none;

            &:visited,
            &:active {
                background: none;
                outline: none;
                color: ${({ theme }) => theme.colors.grey[350]};
            }
            &:focus,
            &:hover {
                color: ${({ theme }) => theme.colors.grey[200]};
                outline: none;
                background: none;
                text-decoration: underline;
            }
        }
    }
`;
