import React from 'react';
import styled from 'styled-components';
import cn from 'clsx';

import { CodeBlock, Code } from './Code';

export const LargeTitle = styled.h1`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.xxxlarge};
    line-height: 38px;
`;

export const PageTitle = styled.h1`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.xxlarge};
    line-height: 38px;
`;

export const SectionTitle = styled.h2`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.large};
    line-height: 28px;
`;

export const SubSectionTitle = styled.h3`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.medium};
    line-height: 24px;
`;

export const GroupTitle = styled.h4`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.base};
    line-height: 20px;
`;

export const SelectionLabel = styled.h5`
    font-weight: ${({ theme }) => theme.fontWeights.demi};
    font-size: ${({ theme }) => theme.fontSizes.small};
    line-height: 18px;
    text-transform: uppercase;
`;

export const Label = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.base};
    line-height: 20px;
    text-transform: capitalize;
`;

export const FormSectionLabel = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.small};
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
`;

export const FormLabel = styled.label`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.base};
    line-height: 20px;
`;

export const Text = styled.p`
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    font-size: ${({ theme }) => theme.fontSizes.medium};
    line-height: 24px;
`;

export const LargeText = styled.p`
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    font-size: ${({ theme }) => theme.fontSizes.large};
    line-height: 28px;
`;

export const Caption = styled.p`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.small};
    line-height: 18px;
`;

export const Quotation = styled.blockquote`
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    font-size: ${({ theme }) => theme.fontSizes.large};
    line-height: 28px;
`;

export const Hint = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    font-size: ${({ theme }) => theme.fontSizes.base};
    line-height: 20px;
`;

export const Hero = styled.h2`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.xxxlarge};
    line-height: 38px;
`;

export const SubHero = styled.h2`
    font-weight: ${({ theme }) => theme.fontWeights.demi};
    font-size: ${({ theme }) => theme.fontSizes.xxlarge};
    line-height: 38px;
`;

export const Link = ({
    className,
    ...rest
}: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) => (
    <a className={cn(className, 'bbui-link')} {...rest} />
);

export { CodeBlock, Code };
