import styled from 'styled-components';
import Popover from '../Popover';

export const StyledPopover = styled(Popover)`
    padding: 4px 12px;

    .tippy-content {
        font-size: ${({ theme }) => theme.fontSizes.base};
    }
`;
