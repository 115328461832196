import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import cn from 'clsx';
import { useCombobox } from 'downshift';

import Input from '../Input';
import InputGroup from '../InputGroup';
import {
    DropdownValueOption,
    DropdownOptionWithActions,
    DropdownSpecialOption,
    DropdownAction,
    DropdownOption,
} from '../BaseDropdown/BaseDropdown.types';
import ToggleArrow from '../BaseDropdown/components/ToggleArrow';
import BaseDropdown from '../BaseDropdown/BaseDropdown';
import { DropdownProps } from './Dropdown.types';
import ClearIcon from '../BaseDropdown/components/ClearIcon';
import { StyledInputGroup } from './Dropdown.styles';
import {
    defaultFilter,
    defaultItemToString,
    filterAndFlatten,
    isAction,
    isSpecial,
} from '../BaseDropdown/BaseDropdown.utils';
import { useFieldId } from '../__utils__/useFieldId';

namespace Dropdown {
    export type Props<Value = React.ReactText, Meta = Record<string, unknown>> = DropdownProps<Value, Meta>;
    export type ValueOption<Value = React.ReactText, Meta = Record<string, unknown>> = DropdownValueOption<Value, Meta>;
    export type Option<Value = React.ReactText, Meta = Record<string, unknown>> = DropdownOption<Value, Meta>;
    export type Action = DropdownAction;
}

// This item will be used to show "None selected" item in the list, but since consumers will not be able to access it we'll cast `null` as `any`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function makeUnselectItem(label: string) {
    return { label, special: true } as DropdownSpecialOption;
}

function Dropdown<Value = React.ReactText, Meta = Record<string, unknown>>({
    className,
    options = [],
    label,
    onItemSelect = () => {},
    actions,
    searchable,
    filter = defaultFilter,
    name,
    defaultIsOpen = false,
    defaultInputValue = '',
    itemToString = defaultItemToString,
    noResultsNode = 'No results',
    clearable = false,
    disabled,
    placeholder = 'Select',
    filterPlaceholder = 'Filter',
    menuHeaderNode,
    unselectLabel = 'None selected',
    inputProps,
    downshiftProps,
    validation,
    defaultValue,
    value,
    dropdownPortalSelector,
    filterInputValue,
    onFilterInputChange,
    ...rest
}: DropdownProps<Value, Meta>) {
    const inputRef = useRef<HTMLInputElement>(null);
    const inputDisplayRef = useRef<HTMLInputElement>(null);
    const [inputValueState, setInputValueState] = useState(defaultInputValue);
    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [selectedItem, setSelectedItem] = useState<DropdownValueOption<Value, Meta> | null>(() => {
        if (defaultValue) {
            const [, flat] = filterAndFlatten(options);
            const option = flat.find((o) => o.value === defaultValue);
            return option || null;
        }
        return null;
    });
    const inputValue = typeof filterInputValue === 'string' ? filterInputValue : inputValueState;
    const setInputValue = (newInputValue: string) => {
        onFilterInputChange?.(newInputValue);
        setInputValueState(newInputValue);
    };
    const isValueControlled = value !== undefined;
    const showUnselect = clearable && !inputValue;
    const fallbackInputId = useFieldId(inputProps?.id);

    // Safely set selected item even if the value is controlled.
    const selectItem = (item: DropdownValueOption<Value, Meta> | null) => {
        !isValueControlled && setSelectedItem(item);
        onItemSelect(item);
    };

    // Outside click closes the menu
    const handleOutsideClick = useCallback(() => setIsOpen(false), []);

    // Filter and flatten options based on the filter input.
    const [filteredOptions, flattenedOptions] = useMemo(() => {
        if (inputValue) {
            return filterAndFlatten(options, inputValue.toLowerCase(), filter);
        }
        return filterAndFlatten(options);
    }, [options, inputValue, filter]);

    // Set selected item if controlled
    useEffect(() => {
        if (value === undefined) return; // uncontrolled
        if (value === null) {
            // Controlled unselected option
            setSelectedItem(null);
        }
        const itemIndex = flattenedOptions.findIndex((o) => o.value === value);
        if (itemIndex !== -1) {
            setSelectedItem(flattenedOptions[itemIndex]);
        }
    }, [value, flattenedOptions]);

    // When clearable == true and there's a selected item, add a special item to "Unselect" at the beginning
    const flattenedOptionsWithUnselect = useMemo(() => {
        return showUnselect && flattenedOptions.length
            ? [makeUnselectItem(unselectLabel), ...flattenedOptions]
            : flattenedOptions;
    }, [flattenedOptions, showUnselect, unselectLabel]);

    // Determine what the index of a selected item is.
    const selectedIndex = useMemo(() => {
        return [flattenedOptionsWithUnselect.findIndex((o) => o.value === selectedItem?.value)];
    }, [flattenedOptionsWithUnselect, selectedItem]);

    // Append actions to the list of items so that Downshift can properly index them for keyboard a11y
    const optionsWithActions: DropdownOptionWithActions<Value, Meta>[] = actions
        ? [...flattenedOptionsWithUnselect, ...actions]
        : flattenedOptionsWithUnselect;

    // Downshift initializations
    const {
        highlightedIndex,
        setHighlightedIndex,

        getLabelProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        getToggleButtonProps,
        getItemProps,
    } = useCombobox<DropdownOptionWithActions<Value, Meta>>({
        ...downshiftProps,
        items: optionsWithActions,
        selectedItem: null,
        isOpen,
        onSelectedItemChange: ({ selectedItem: newSelected }) => {
            if (isAction(newSelected)) {
                newSelected.onClick();
            } else if (isSpecial(newSelected)) {
                selectItem(null);
            } else {
                selectItem(newSelected || null);
            }
            // reset filter and close
            setInputValue('');
            setIsOpen(false);
        },
    });

    const parsedInputProps = searchable
        ? { ref: inputDisplayRef, onBlur: () => inputDisplayRef.current && (inputDisplayRef.current.scrollLeft = 0) }
        : getInputProps({
              ...inputProps,
              ref: inputDisplayRef,
              onBlur: (e) => {
                  inputDisplayRef.current && (inputDisplayRef.current.scrollLeft = 0);
                  inputProps?.onBlur?.(e);
              },
          });
    const inputId = parsedInputProps?.id || fallbackInputId;

    const inputNode = (
        <StyledInputGroup
            className={cn({ searchable, open: isOpen, disabled })}
            tabIndex={searchable ? 0 : -1}
            disabled={disabled}
            validation={validation}
            onKeyDown={(e) => {
                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                    // Only open the dropdown with up/down arrow keys when focused.
                    e.preventDefault();
                    setIsOpen(true);
                    setTimeout(() => {
                        // Needs to focus after it opens
                        inputRef.current?.focus();
                    }, 0);
                } else if (e.key === 'Escape') {
                    setIsOpen(false);
                } else if (!searchable) {
                    // Highlight the first label with the typed character to mimic the native select behavior
                    const key = e.key;
                    if (key.length !== 1) return;
                    const index = flattenedOptions.findIndex((o) => o.label.toLowerCase()[0] === key);
                    if (index >= 0) {
                        setHighlightedIndex(index);
                    }
                }
            }}
        >
            <Input
                {...parsedInputProps}
                id={inputId}
                style={{ cursor: 'default' }}
                value={itemToString(selectedItem)}
                readOnly={true}
                tabIndex={searchable || disabled ? -1 : 0}
                placeholder={placeholder}
                name={name}
                onClick={() => {
                    setIsOpen(true);
                    setTimeout(() => {
                        inputRef.current?.focus();
                    }, 0);
                }}
            />
            <InputGroup.Addon>
                <ToggleArrow getToggleButtonProps={getToggleButtonProps} onClick={() => setIsOpen((prev) => !prev)} />
            </InputGroup.Addon>
        </StyledInputGroup>
    );
    const headNode = searchable ? (
        <>
            <StyledInputGroup>
                <Input
                    {...getInputProps({
                        ref: inputRef,
                        value: inputValue || '',
                        placeholder: filterPlaceholder,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value),
                        ...inputProps,
                    })}
                />

                <InputGroup.Addon>
                    <ClearIcon
                        isDisplayed={Boolean(inputValue)}
                        onClick={() => {
                            setInputValue('');
                            inputRef.current?.focus();
                        }}
                    />
                </InputGroup.Addon>
            </StyledInputGroup>
            {menuHeaderNode}
        </>
    ) : (
        menuHeaderNode
    );

    return (
        <BaseDropdown<Value, Meta>
            className={cn('bbui-dropdown', className)}
            options={filteredOptions}
            specialOption={showUnselect ? makeUnselectItem(unselectLabel) : undefined}
            label={label}
            filterText={inputValue}
            isOpen={!disabled && isOpen}
            inputNode={inputNode}
            inputId={inputId}
            menuHeaderNode={headNode}
            noResultsNode={noResultsNode}
            highlightedIndex={highlightedIndex}
            selectedIndices={selectedIndex}
            actions={actions}
            onOutsideClick={handleOutsideClick}
            getLabelProps={getLabelProps}
            getComboboxProps={getComboboxProps}
            getMenuProps={getMenuProps}
            getItemProps={getItemProps}
            dropdownPortalSelector={dropdownPortalSelector}
            {...rest}
        />
    );
}

export default Dropdown;
