import React from 'react';
import cn from 'clsx';

import { StyledInputGroupContainer } from './InputGroup.styles';
import InputGroupAddon from './InputGroupAddon';

import { FCC } from '../__utils__/types';

namespace InputGroup {
    export type Props = InputGroupProps;
    export namespace Addon {
        export type Props = InputGroupAddon.Props;
    }
}

export interface InputGroupProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Classes will be applied to the root tag.
     */
    className?: string;

    /**
     * Validation state
     */
    validation?: 'error' | 'warning' | 'success';

    /**
     * Flag to mark the input group as disabled
     */
    disabled?: boolean;

    /**
     * Components to group into a single input box.
     * Accepted types: Input, InputGroupAddon
     */
    children: React.ReactNode;
}

type StaticComponentType = { Addon: typeof InputGroupAddon };

/**
 * Input group container
 *
 * @visibleName InputGroup
 */
const InputGroup: FCC<InputGroupProps> & StaticComponentType = ({
    children,
    className = '',
    disabled = false,
    validation,
    ...rest
}) => {
    return (
        <StyledInputGroupContainer
            className={cn('bbui-input-group', className)}
            $disabled={disabled}
            $validation={validation}
            {...rest}
        >
            {React.Children.map(children, (child) =>
                React.isValidElement(child)
                    ? React.cloneElement(child, { disabled, validation, ...child.props })
                    : child
            )}
        </StyledInputGroupContainer>
    );
};

InputGroup.Addon = InputGroupAddon;

export default InputGroup;
