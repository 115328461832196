import React from 'react';
import ThemeProvider from '../ThemeProvider';
import NotificationProvider from '../Notification';
import FocusManager from '../FocusManager';
import GlobalStyles from '../GlobalStyles';
import { App } from '../Layout';
import { BBUIAppProps } from './BBUIApp.types';
import { FCC } from '../__utils__/types';

namespace BBUIContextProvider {
    export type Props = BBUIAppProps;
}

const BBUIContextProvider: FCC<BBUIAppProps> = ({
    notificationProviderProps,
    focusManagerProps,
    appProps,
    disableFocusManager = false,
    disableGlobalStyles = false,
    disableApp = false,
    children,
    ...themeProps
}) => {
    return (
        <ThemeProvider {...themeProps}>
            {!disableFocusManager && <FocusManager {...focusManagerProps} />}
            {!disableGlobalStyles && <GlobalStyles />}
            <NotificationProvider {...notificationProviderProps}>
                {!disableApp ? <App {...appProps}>{children}</App> : children}
            </NotificationProvider>
        </ThemeProvider>
    );
};

export default BBUIContextProvider;
