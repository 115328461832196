import React, { useRef } from 'react';
import cn from 'clsx';
import Icon from '../Icon/Icon';
import { SwitchContainer } from './Switch.styles';
import { SwitchProps } from './Switch.types';
import { FCC } from '../__utils__/types';

namespace Switch {
    export type Props = SwitchProps;
}

const Switch: FCC<SwitchProps> = ({
    className = '',
    disabled = false,
    onChange = () => {},
    label,
    labelPosition = 'left',
    ...rest
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!disabled) {
            onChange(event, event.target.checked);
        }
    };

    const handleLabelClick = (event: React.MouseEvent<HTMLInputElement> | React.MouseEvent<HTMLLabelElement>) => {
        // Clicking the label also triggers an onClick event on the input, meaning that it kicks off two onClick events
        // This can cause problems with wrapping components which control the state of the Switch based on click events,
        // as it'll make the Switch toggle twice in quick succession.
        if (event.target !== inputRef.current) {
            event.stopPropagation();
        }
    };

    const generateLabelText = () => {
        return (
            label && (
                <span
                    className={cn('bbui-switch__label', {
                        labelTop: labelPosition === 'top',
                        labelRight: labelPosition === 'right',
                        labelLeft: labelPosition === 'left',
                    })}
                >
                    {label}
                </span>
            )
        );
    };

    return (
        <SwitchContainer
            className={cn('bbui-switch', className)}
            labelPosition={labelPosition}
            onClick={handleLabelClick}
        >
            {generateLabelText()}
            <input type="checkbox" onChange={handleChange} disabled={disabled} ref={inputRef} {...rest} />
            <span className="bbui-switch__track">
                <Icon name="checkmark" className="bbui-switch__thumb" />
            </span>
        </SwitchContainer>
    );
};

export default Switch;
