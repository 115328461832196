import styled, { css } from 'styled-components';
import { NotificationKind } from './Notification.types';
import { MOUSE_MODE_CLASS } from '../FocusManager';

const resetButtonStyles = css`
    appearance: none !important;
    border: none;
    background: none;
    color: inherit;

    .${MOUSE_MODE_CLASS} &:focus {
        outline: none;
    }
`;

export const StyledNotificationGroup = styled.div<{ $kind: NotificationKind }>`
    position: relative;
    width: 100%;
    color: ${({ theme, $kind }) => theme.feedback.colors[$kind].textContrast};
    background-color: ${({ theme, $kind }) => theme.feedback.colors[$kind].graphic};
    box-shadow: ${({ theme }) => theme.shadows.default};

    height: auto;

    /* animation */
    will-change: transform, height, margin-top, visibility, opacity;
    transform: translateY(-20px);
    visibility: hidden;
    opacity: 0;
    min-height: 0;

    &[aria-hidden='false'] {
        min-height: 40px;
        margin-top: 10px;
        transform: translateY(30px);
        opacity: 1;
        height: auto;
        visibility: visible;
    }
    transition: all 0.2s ease-in-out;

    a {
        color: ${({ theme, $kind }) => theme.feedback.colors[$kind].textContrast};
        text-decoration: underline;

        &:visited,
        &:hover,
        &:active {
            outline: none;
            background: none;
            color: ${({ theme, $kind }) => theme.feedback.colors[$kind].textContrast};
        }
    }
`;

export const NotificationContainer = styled.div`
    position: fixed;
    height: 0;
    overflow: visible;
    width: 600px;
    max-width: 90%;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: ${({ theme }) => theme.depths.dialog + 1};
`;

export const NotificationMessageView = styled.div<{ $kind: NotificationKind }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 60px;
    width: 100%;
    max-width: 100%;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSizes.base};
    line-height: ${({ theme }) => theme.lineHeight.base};
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.has-dropdown:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    .bbui-icon {
        display: inline-block;
    }

    .bbui-notification-notif-icon {
        margin-right: 8px;
        font-size: ${({ theme }) => theme.fontSizes.medium};
    }

    .bbui-notification-dropdown-toggle {
        ${resetButtonStyles}

        margin-left: 4px;

        .bbui-notifications-details-label {
            text-decoration: underline;
            font-weight: 500;
        }

        .bbui-icon {
            margin-left: 4px;
            font-size: ${({ theme }) => theme.fontSizes.small};
            transition: transform 0.2s ease-in-out;
        }
    }
    &[aria-expanded='true'] .bbui-notification-dropdown-toggle .bbui-icon {
        transform: rotate(180deg);
    }

    .bbui-notification-close-icon {
        ${resetButtonStyles}

        &[data-notification-kind=${({ $kind }) => $kind}] {
            .${MOUSE_MODE_CLASS} &:hover, &:hover, &:focus {
                color: ${({ theme, $kind }) => theme.feedback.colors[$kind].textContrast};
                background-color: ${({ theme, $kind }) => theme.feedback.colors[$kind].hover};
            }
        }
        
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
    }

    .${MOUSE_MODE_CLASS} &:focus {
        outline: none;
    }
`;

export const NotificationMessageDropdown = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;

    ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
    }

    will-change: height, max-height;
    max-height: 0;
    visibility: hidden;
    transition: max-height 0.2s ease-in-out, visibility 0.2s;

    &[aria-hidden='false'] {
        visibility: visible;
        &.is-header {
            max-height: 157px;
            border-top: 1px solid rgba(0, 0, 0, 0.7);
        }
        &:not(.is-header) {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    .bbui-notification-message {
        text-align: left;
        margin: 0;
        height: 52px;
        font-size: ${({ theme }) => theme.fontSizes.base};
        line-height: 20px;
        border-top: 1px solid rgba(0, 0, 0, 0.3);
        &:first-of-type {
            border-top: none;
        }
        &:hover,
        &[aria-expanded='true'] {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    .bbui-notification-details {
        padding: 20px 60px;
    }
`;

export const NotificationLiveStatusContainer = styled.div`
    position: absolute;
    left: -99999px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
`;
