import React, { forwardRef, InputHTMLAttributes } from 'react';
import cn from 'clsx';

import { StyledInput } from './Input.styles';
import { useInputTouched } from '../__utils__/useInputTouched';

namespace Input {
    export type Props = InputProps & React.RefAttributes<HTMLInputElement>;
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    /**
     * Classes will be applied to the root tag
     */
    className?: string;
    /**
     * Input validation state
     */
    validation?: 'error' | 'warning' | 'success';
}

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, onBlur, ...rest }, ref) => {
    const { touchedClasses, touchedProps } = useInputTouched<HTMLInputElement>(onBlur);
    return (
        <StyledInput className={cn('bbui-input', className, touchedClasses)} ref={ref} {...touchedProps} {...rest} />
    );
});

Input.displayName = 'Input';

export default Input;
