import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import cn from 'clsx';

import Button from '../../Button';
import Icon from '../../Icon';
import Tooltip from '../../Tooltip';

import { StyledIconLinkContainer } from './TitleBar.IconLink.styles';
import { TitleBarIconLinkProps, TitleBarMenuItem } from './TitleBar.IconLink.types';
import TitleBarIconLinkItem from './TitleBar.IconLinkItem';

namespace TitleBarIconLink {
    export type Props = TitleBarIconLinkProps;
    export type MenuItem = TitleBarMenuItem;
}

const getSetters = (lastIndex: number): Record<string, React.SetStateAction<number>> => ({
    ArrowUp: (prev) => (prev <= 0 ? lastIndex : prev - 1),
    ArrowDown: (prev) => (prev === lastIndex ? 0 : prev + 1),
    Home: 0,
    End: lastIndex,
});

let iconLinkIdCount = 0;

/**
 * A link with an associated Icon, to be rendered within the title bar. Can show/hide a sub-menu when hovered if
 * passed a series of `<li />` elements as children.
 * @visibleName TitleBar.IconLink
 */
const TitleBarIconLink = ({
    className = '',
    onClick = () => {},
    iconName,
    customIcon,
    active = false,
    text,
    menuItems = [],
    'aria-label': ariaLabelProp,
    ...rest
}: TitleBarIconLinkProps) => {
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const iconLinkId = useMemo(() => iconLinkIdCount++, []);

    const renderedIcon = iconName ? <Icon name={iconName} /> : customIcon;
    const hasDropdown = Boolean(menuItems.length);
    const menuId = `bbui-titlebar-iconlink-${iconLinkId}-menu`;
    const ariaLabel = ariaLabelProp || (typeof text === 'string' ? text : iconName) || `iconlink-${iconLinkId}`;

    const handleDocumentClick = useCallback(
        (e: Event) => {
            if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
                setIsOpen(false);
            }
        },
        [containerRef]
    );

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick, { capture: true });
        document.addEventListener('keyup', handleDocumentClick, { capture: true });
        return () => {
            document.removeEventListener('click', handleDocumentClick);
            document.removeEventListener('keyup', handleDocumentClick);
        };
    }, [handleDocumentClick]);

    let itemIndex = 0;
    const menuItemsWithIndex: (TitleBarMenuItem & { index?: number; id?: string })[] = menuItems
        .filter((item) => !item.hidden)
        .map((item) =>
            item.readonly
                ? item
                : {
                      ...item,
                      id: item.id || `bbui-titlebar-iconlink-${iconLinkId}-item-${itemIndex}`,
                      index: itemIndex++,
                  }
        );

    useEffect(() => {
        if (isOpen) {
            const focusedItem = menuItemsWithIndex.find((m) => m.index === focusedIndex);
            if (focusedItem?.id) {
                document.getElementById(focusedItem.id)?.focus();
            }
        }
    }, [isOpen, focusedIndex, menuItemsWithIndex]);

    function handleKeyDown(e: React.KeyboardEvent<HTMLElement>) {
        const setter = getSetters(itemIndex - 1)[e.key];
        if (setter !== undefined) {
            e.preventDefault();
            setFocusedIndex(setter);
        } else if (e.key === 'Escape') {
            setIsOpen(false);
        }
    }

    function handleOnClick(e: React.MouseEvent<HTMLButtonElement>) {
        buttonRef.current?.focus(); // Sometimes clicking won't focus it
        setFocusedIndex(-1);
        setIsOpen((prev) => !prev);
        onClick?.(e);
    }

    return (
        <StyledIconLinkContainer
            className={cn('bbui-titlebar-iconlink', className)}
            ref={containerRef}
            onKeyDown={handleKeyDown}
            {...rest}
        >
            <Tooltip content={text} placement="bottom">
                <Button
                    className={cn('bbui-titlebar-iconlink-button', {
                        open: isOpen,
                        active,
                    })}
                    ref={buttonRef}
                    kind="tertiary"
                    tabIndex={isOpen ? -1 : 0}
                    onClick={handleOnClick}
                    aria-expanded={hasDropdown ? isOpen : undefined}
                    aria-haspopup={hasDropdown ? 'menu' : 'false'}
                    aria-controls={menuId}
                    aria-label={ariaLabel}
                >
                    {renderedIcon}
                    {hasDropdown ? <Icon name="chevron-down" className="bbui-titlebar-iconlink-down" /> : null}
                </Button>
            </Tooltip>
            <div className="bbui-titlebar-iconlink-popper" aria-hidden={!hasDropdown} role="menu" id={menuId}>
                <div className={cn('bbui-titlebar-iconlink-menu', { open: isOpen })}>
                    {menuItemsWithIndex.map(({ label, id, ...props }, rawIndex) => (
                        <TitleBarIconLinkItem
                            key={rawIndex}
                            id={id}
                            focusedIndex={focusedIndex}
                            label={label}
                            setFocusedIndex={setFocusedIndex}
                            closeMenu={() => setIsOpen(false)}
                            {...props}
                        />
                    ))}
                </div>
            </div>
        </StyledIconLinkContainer>
    );
};

export const TitleBarIconlinkDivider = { label: 'divider', readonly: true, divider: true } as const;

export default TitleBarIconLink;
