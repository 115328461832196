import React from 'react';
import cn from 'clsx';
import { StyledTippy } from './Popover.styles';
import { PopoverProps } from './Popover.types';
import { FCC } from '../__utils__/types';

namespace Popover {
    export type Props = PopoverProps;
}

const Popover: FCC<PopoverProps> = ({
    className,
    placement = 'bottom',
    trigger = 'mouseenter',
    hoverDelay = 0,
    disabled = false,
    content = null,
    visible,
    children,
    ...rest
}) => {
    // Tippy only accepts ReactElement as children.
    const typeSafeChildren = React.isValidElement(children) ? children : <span>{children}</span>;

    // Don't render empty or disabled popover
    if (!content || disabled) return typeSafeChildren || null;
    return (
        <StyledTippy
            content={content}
            animation="fade"
            placement={placement}
            delay={hoverDelay}
            trigger={visible === undefined ? trigger : undefined}
            visible={visible}
            disabled={disabled}
            className={cn('bbui-popover', className)}
            {...rest}
        >
            {typeSafeChildren}
        </StyledTippy>
    );
};

export default Popover;
