import React, { useRef, useImperativeHandle, useEffect } from 'react';
import cn from 'clsx';

import { CheckboxProps } from './Checkbox.types';
import { StyledCheckboxContainer } from './Checkbox.styles';
import Icon from '../Icon/Icon';

namespace Checkbox {
    export type Props = CheckboxProps & React.RefAttributes<HTMLDivElement>;
}

const Checkbox = React.forwardRef<HTMLDivElement, CheckboxProps>((props, ref) => {
    const {
        checked,
        className,
        disabled = false,
        defaultChecked,
        label = '',
        indeterminate,
        inputProps = {},
        name,
        onClick = () => {},
        ...rest
    } = props;
    const { className: checkboxClassName, ...restCheckBoxInputProps } = inputProps;

    const checkBoxRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => checkBoxRef.current as HTMLInputElement);

    useEffect(() => {
        if (checkBoxRef.current) {
            // The checked state dominates indeterminate according to browser behavior
            checkBoxRef.current.indeterminate = Boolean(indeterminate) && !checkBoxRef.current.checked;
        }
    }, [indeterminate]);

    return (
        <StyledCheckboxContainer className={cn('bbui-checkbox', className)} {...rest}>
            <label className="bbui-checkbox-label">
                <input
                    type="checkbox"
                    name={name}
                    checked={checked}
                    disabled={disabled}
                    defaultChecked={defaultChecked}
                    onClick={onClick}
                    onChange={() => {}}
                    className={cn('bbui-checkbox-input', checkboxClassName)}
                    ref={checkBoxRef}
                    {...restCheckBoxInputProps}
                />
                <Icon name="checkbox-on" className="bbui-checkbox-icon bbui-checked-icon" />
                <Icon name="checkbox-multi" className="bbui-checkbox-icon bbui-indeterminate-icon" />
                <Icon name="checkbox-off" className="bbui-checkbox-icon bbui-unchecked-icon" />
                {label && <span className="bbui-checkbox-label-text">{label}</span>}
            </label>
        </StyledCheckboxContainer>
    );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
