import React from 'react';
import cn from 'clsx';

import { isIE } from '../__utils__/browserUtils';
import { ProgressSpinnerProps } from './ProgressSpinner.types';
import { getCircle, StyledProgressSpinner } from './ProgressSpinner.styles';
import SvgB from './b.svg';
import { FCC } from '../__utils__/types';

namespace ProgressSpinner {
    export type Props = ProgressSpinnerProps;
}

/**
 * A dynamic spinning animation to indicate loading/processing.
 */
const ProgressSpinner: FCC<ProgressSpinnerProps> = ({
    className,
    label = 'Loading…',
    size = 'small',
    labelPosition = 'bottom',
    // Aria attributes should be set according to WAI-ARIA spec, even though spinner is indeterminate
    'aria-valuemin': ariaValuemin = 0,
    'aria-valuemax': ariaValuemax = 1,
    ...rest
}) => {
    const isBrowserIE = isIE();

    return (
        <StyledProgressSpinner
            className={cn('bbui-progress-spinner', className)}
            size={size}
            role="progressbar"
            aria-valuemin={ariaValuemin}
            aria-valuemax={ariaValuemax}
            {...rest}
            data-label-position={labelPosition}
        >
            <div className="spinner">
                <div className="rotation">
                    <div className={cn('oscillation', { ie: isBrowserIE })}>{getCircle(size)}</div>
                </div>
            </div>
            {label && <div className="bbui-progress-spinner-label">{label}</div>}
            {size === 'xlarge' && <SvgB className="bbui-progress-spinner-b" />}
        </StyledProgressSpinner>
    );
};

export default ProgressSpinner;
