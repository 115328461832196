import React, { useRef, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import cn from 'clsx';
import { usePopper } from 'react-popper';

import { BaseDropdownProps, DropdownValueOption } from './BaseDropdown.types';
import {
    BaseDropdownWrapper,
    BasedropdownPopper,
    BasedropdownMenuHeader,
    BasedropdownMenu,
    BaseDropdownMenuAction,
    BaseDropdownOption,
    BaseDropdownGroupHeader,
    BaseDropdownOptionNoResults,
} from './BaseDropdown.styles';
import { defaultOptionRenderer, defaultGroupHeaderRenderer, defaultSpecialOptionRenderer } from './BaseDropdown.utils';
import { useElementWidth } from '../__utils__/useElementWidth';

function BaseDropdown<Value, Meta>({
    className,
    options,
    specialOption,
    actions,
    isOpen,
    label,
    inputId,
    filterText,
    highlightedIndex,
    selectedIndices,
    inputNode,
    menuHeaderNode,
    onOutsideClick,
    getLabelProps,
    getMenuProps,
    getComboboxProps,
    getItemProps,
    optionRenderer = defaultOptionRenderer,
    groupHeaderRenderer = defaultGroupHeaderRenderer,
    specialOptionRenderer = defaultSpecialOptionRenderer,
    noResultsNode,
    showDividers,
    noMaxHeight,
    dropdownPortalSelector,
    popperOptions = {},
    ...rest
}: BaseDropdownProps<Value, Meta>) {
    const containerRef = useRef<HTMLDivElement>(null);
    const referenceRef = useRef<HTMLDivElement>(null);
    const popperRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLUListElement>(null);
    const popperWidth = useElementWidth(referenceRef.current);

    // Handle outside clicks
    const handleDocumentClick = useCallback(
        (e: Event) => {
            if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
                onOutsideClick?.(e);
            }
        },
        [containerRef, onOutsideClick]
    );
    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        document.addEventListener('keyup', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
            document.removeEventListener('keyup', handleDocumentClick);
        };
    }, [handleDocumentClick]);

    const { styles, attributes } = usePopper(referenceRef.current, popperRef.current, {
        placement: 'bottom-start',
        ...popperOptions,
    });

    const isEmpty = options.length === 0;
    let selectableIndex = 0;

    const renderOption = (option: DropdownValueOption<Value, Meta>) => {
        const isSelected = selectedIndices.includes(selectableIndex);
        return (
            <BaseDropdownOption
                key={`${option.label}-${selectableIndex}`}
                {...getItemProps({
                    item: option,
                    className: cn('bbui-basedropdown-option', {
                        highlighted: selectableIndex === highlightedIndex,
                        selected: isSelected,
                    }),
                    index: selectableIndex++,
                })}
            >
                {optionRenderer(option, filterText || '', isSelected)}
            </BaseDropdownOption>
        );
    };

    // Always open with scroll at the top
    useEffect(() => {
        if (menuRef.current) menuRef.current.scrollTop = 0;
    }, [menuRef, isOpen]);

    const PopperWrapper = (
        <BasedropdownPopper
            className="bbui-basedropdown-popper"
            ref={popperRef}
            style={{ ...styles.popper, minWidth: popperWidth }}
            {...attributes.popper}
            aria-expanded={isOpen}
        >
            {menuHeaderNode && (
                <BasedropdownMenuHeader className="bbui-basedropdown-menu-header">
                    {menuHeaderNode}
                </BasedropdownMenuHeader>
            )}
            <BasedropdownMenu
                {...getMenuProps({
                    ref: menuRef,
                    className: cn('bbui-basedropdown-menu', {
                        dividers: showDividers,
                        'no-max': noMaxHeight,
                    }),
                })}
            >
                {!isEmpty && specialOption && (
                    <BaseDropdownOption
                        {...getItemProps({
                            item: specialOption,
                            className: cn('bbui-basedropdown-option', 'bbui-basedropdown-special', {
                                highlighted: selectableIndex === highlightedIndex,
                            }),
                            index: selectableIndex++,
                        })}
                    >
                        {specialOptionRenderer(specialOption)}
                    </BaseDropdownOption>
                )}
                {!isEmpty ? (
                    options.map((option, index) =>
                        'options' in option ? (
                            <React.Fragment key={index}>
                                <BaseDropdownGroupHeader
                                    key={`group-${option.label}-${index}`}
                                    className="bbui-basedropdown-group-header"
                                >
                                    {groupHeaderRenderer(option)}
                                </BaseDropdownGroupHeader>
                                {option.options.map(renderOption)}
                            </React.Fragment>
                        ) : (
                            renderOption(option)
                        )
                    )
                ) : (
                    <BaseDropdownOptionNoResults className="bbui-basedropdown-option-noresults">
                        {noResultsNode}
                    </BaseDropdownOptionNoResults>
                )}
            </BasedropdownMenu>
            {actions &&
                actions.map((action) => {
                    return (
                        <BaseDropdownMenuAction
                            key={action.label}
                            aria-label={`Action: ${action.label}`}
                            onClick={action.onClick}
                            kind="tertiary"
                            {...getItemProps({
                                item: action,
                                className: cn('bbui-basedropdown-menu-action', {
                                    highlighted: selectableIndex === highlightedIndex,
                                    selected: selectedIndices.includes(selectableIndex),
                                }),
                                index: selectableIndex++,
                            })}
                            role="button"
                        >
                            {action.label}
                        </BaseDropdownMenuAction>
                    );
                })}
        </BasedropdownPopper>
    );

    return (
        <BaseDropdownWrapper className={cn('bbui-basedropdown', className)} {...rest} ref={containerRef}>
            {label && (
                <label htmlFor={inputId} className="bbui-basedropdown-label">
                    {label}
                </label>
            )}
            <div
                {...getComboboxProps({
                    ref: referenceRef,
                    className: 'bbui-basedropdown-input-container',
                })}
            >
                {inputNode}
            </div>
            {dropdownPortalSelector && typeof window !== 'undefined'
                ? ReactDOM.createPortal(PopperWrapper, document.querySelector(dropdownPortalSelector) || document.body)
                : PopperWrapper}
        </BaseDropdownWrapper>
    );
}

export default BaseDropdown;
