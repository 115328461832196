import React from 'react';
import styled from 'styled-components';

import Button from '../../Button';

interface Props {
    className?: string;
    isDisplayed?: boolean;
    onClick?: (event: React.MouseEvent) => void;
    isFocusable?: boolean;
}

function ClearIcon({ className, isDisplayed, onClick, isFocusable = true }: Props) {
    return (
        <StyledButton
            icon="x"
            aria-label="clear"
            className={className}
            onClick={onClick}
            kind="tertiary"
            aria-hidden={!isDisplayed}
            tabIndex={isFocusable ? 0 : -1}
        />
    );
}

// Needs to use CSS to hide, otherwise it causes rerendering and the focus gets lost
const StyledButton = styled(Button)`
    &[aria-hidden='true'] {
        display: none;
    }
`;

export default ClearIcon;
