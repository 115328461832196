/* This file is generated by bin/gen-svgIcons.js */

import apps from './svg/apps.svg';
import arrowLeft from './svg/arrow-left.svg';
import arrowRight from './svg/arrow-right.svg';
import arrowTitle from './svg/arrow-title.svg';
import autofill from './svg/autofill.svg';
import award from './svg/award.svg';
import bell from './svg/bell.svg';
import bloombergLogo from './svg/bloomberg-logo.svg';
import bloombergTerminalAlt from './svg/bloomberg-terminal-alt.svg';
import bloombergTerminal from './svg/bloomberg-terminal.svg';
import calendar from './svg/calendar.svg';
import caretDown from './svg/caret-down.svg';
import chart from './svg/chart.svg';
import checkCircleAlt from './svg/check-circle-alt.svg';
import checkCircle from './svg/check-circle.svg';
import checkboxMulti from './svg/checkbox-multi.svg';
import checkboxOff from './svg/checkbox-off.svg';
import checkboxOn from './svg/checkbox-on.svg';
import checkmark from './svg/checkmark.svg';
import chevronDoubleDown from './svg/chevron-double-down.svg';
import chevronDoubleLeft from './svg/chevron-double-left.svg';
import chevronDoubleRight from './svg/chevron-double-right.svg';
import chevronDoubleUp from './svg/chevron-double-up.svg';
import chevronDown from './svg/chevron-down.svg';
import chevronLeft from './svg/chevron-left.svg';
import chevronRight from './svg/chevron-right.svg';
import chevronUp from './svg/chevron-up.svg';
import clock from './svg/clock.svg';
import close from './svg/close.svg';
import comment from './svg/comment.svg';
import compass from './svg/compass.svg';
import corp from './svg/corp.svg';
import courthouse from './svg/courthouse.svg';
import diagram from './svg/diagram.svg';
import dial from './svg/dial.svg';
import disk from './svg/disk.svg';
import documentListTwo from './svg/document-list-two.svg';
import documentList from './svg/document-list.svg';
import download from './svg/download.svg';
import drawer from './svg/drawer.svg';
import duplicate from './svg/duplicate.svg';
import dragHandle from './svg/drag-handle.svg';
import ellipsis from './svg/ellipsis.svg';
import envelopeOpen from './svg/envelope-open.svg';
import envelope from './svg/envelope.svg';
import envelopes from './svg/envelopes.svg';
import exclamationCircleAlt from './svg/exclamation-circle-alt.svg';
import exclamationCircle from './svg/exclamation-circle.svg';
import exclamationDiamond from './svg/exclamation-diamond.svg';
import exclamationTriangleAlt from './svg/exclamation-triangle-alt.svg';
import exclamationTriangle from './svg/exclamation-triangle.svg';
import expand from './svg/expand.svg';
import eye from './svg/eye.svg';
import fileCabinet from './svg/file-cabinet.svg';
import fileExcel from './svg/file-excel.svg';
import filePdf from './svg/file-pdf.svg';
import filePowerpoint from './svg/file-powerpoint.svg';
import fileWord from './svg/file-word.svg';
import file from './svg/file.svg';
import files from './svg/files.svg';
import filterClear from './svg/filter-clear.svg';
import filter from './svg/filter.svg';
import firstPage from './svg/first-page.svg';
import flag from './svg/flag.svg';
import folder from './svg/folder.svg';
import gear from './svg/gear.svg';
import headset from './svg/headset.svg';
import heartbeat from './svg/heartbeat.svg';
import home from './svg/home.svg';
import infoCircleAlt from './svg/info-circle-alt.svg';
import infoCircle from './svg/info-circle.svg';
import key from './svg/key.svg';
import lastPage from './svg/last-page.svg';
import lightning from './svg/lightning.svg';
import link from './svg/link.svg';
import list from './svg/list.svg';
import lists from './svg/lists.svg';
import lock from './svg/lock.svg';
import logout from './svg/logout.svg';
import magnify from './svg/magnify.svg';
import mediaClosedCaption from './svg/media-closed-caption.svg';
import mediaFullscreen from './svg/media-fullscreen.svg';
import mediaPause from './svg/media-pause.svg';
import mediaPlay from './svg/media-play.svg';
import mediaStop from './svg/media-stop.svg';
import mediaVolumeDown from './svg/media-volume-down.svg';
import mediaVolumeUp from './svg/media-volume-up.svg';
import menu from './svg/menu.svg';
import minimize from './svg/minimize.svg';
import mobile from './svg/mobile.svg';
import moveHorizontal from './svg/move-horizontal.svg';
import movePoint from './svg/move-point.svg';
import moveVertical from './svg/move-vertical.svg';
import no from './svg/no.svg';
import paperclip from './svg/paperclip.svg';
import pencil from './svg/pencil.svg';
import phone from './svg/phone.svg';
import plusCircleAlt from './svg/plus-circle-alt.svg';
import plusCircle from './svg/plus-circle.svg';
import plus from './svg/plus.svg';
import popin from './svg/popin.svg';
import popout from './svg/popout.svg';
import questionCircleOutline from './svg/question-circle-outline.svg';
import questionCircle from './svg/question-circle.svg';
import questionSquare from './svg/question-square.svg';
import radioOff from './svg/radio-off.svg';
import radioOn from './svg/radio-on.svg';
import realtime from './svg/realtime.svg';
import redo from './svg/redo.svg';
import refreshSquare from './svg/refresh-square.svg';
import refresh from './svg/refresh.svg';
import restricted from './svg/restricted.svg';
import scaleDown from './svg/scale-down.svg';
import scaleUpPanel from './svg/scale-up-panel.svg';
import scaleUp from './svg/scale-up.svg';
import share from './svg/share.svg';
import star from './svg/star.svg';
import stop from './svg/stop.svg';
import subscribe from './svg/subscribe.svg';
import talkBubble from './svg/talk-bubble.svg';
import thumbsDownFill from './svg/thumbs-down-fill.svg';
import thumbsDownOutline from './svg/thumbs-down-outline.svg';
import thumbsUpFill from './svg/thumbs-up-fill.svg';
import thumbsUpOutline from './svg/thumbs-up-outline.svg';
import timer from './svg/timer.svg';
import trash from './svg/trash.svg';
import undo from './svg/undo.svg';
import unlink from './svg/unlink.svg';
import unlock from './svg/unlock.svg';
import upload from './svg/upload.svg';
import userGear from './svg/user-gear.svg';
import userHeadset from './svg/user-headset.svg';
import userKey from './svg/user-key.svg';
import userLock from './svg/user-lock.svg';
import user from './svg/user.svg';
import xCircleAlt from './svg/x-circle-alt.svg';
import xCircle from './svg/x-circle.svg';
import x from './svg/x.svg';
import zoom from './svg/zoom.svg';

export default {
    apps: apps,
    'arrow-left': arrowLeft,
    'arrow-right': arrowRight,
    'arrow-title': arrowTitle,
    autofill: autofill,
    award: award,
    bell: bell,
    'bloomberg-logo': bloombergLogo,
    'bloomberg-terminal-alt': bloombergTerminalAlt,
    'bloomberg-terminal': bloombergTerminal,
    calendar: calendar,
    'caret-down': caretDown,
    chart: chart,
    'check-circle-alt': checkCircleAlt,
    'check-circle': checkCircle,
    'checkbox-multi': checkboxMulti,
    'checkbox-off': checkboxOff,
    'checkbox-on': checkboxOn,
    checkmark: checkmark,
    'chevron-double-down': chevronDoubleDown,
    'chevron-double-left': chevronDoubleLeft,
    'chevron-double-right': chevronDoubleRight,
    'chevron-double-up': chevronDoubleUp,
    'chevron-down': chevronDown,
    'chevron-left': chevronLeft,
    'chevron-right': chevronRight,
    'chevron-up': chevronUp,
    clock: clock,
    close: close,
    comment: comment,
    compass: compass,
    corp: corp,
    courthouse: courthouse,
    diagram: diagram,
    dial: dial,
    disk: disk,
    'document-list-two': documentListTwo,
    'document-list': documentList,
    download: download,
    'drag-handle': dragHandle,
    drawer: drawer,
    duplicate: duplicate,
    ellipsis: ellipsis,
    'envelope-open': envelopeOpen,
    envelope: envelope,
    envelopes: envelopes,
    'exclamation-circle-alt': exclamationCircleAlt,
    'exclamation-circle': exclamationCircle,
    'exclamation-diamond': exclamationDiamond,
    'exclamation-triangle-alt': exclamationTriangleAlt,
    'exclamation-triangle': exclamationTriangle,
    expand: expand,
    eye: eye,
    'file-cabinet': fileCabinet,
    'file-excel': fileExcel,
    'file-pdf': filePdf,
    'file-powerpoint': filePowerpoint,
    'file-word': fileWord,
    file: file,
    files: files,
    'filter-clear': filterClear,
    filter: filter,
    'first-page': firstPage,
    flag: flag,
    folder: folder,
    gear: gear,
    headset: headset,
    heartbeat: heartbeat,
    home: home,
    'info-circle-alt': infoCircleAlt,
    'info-circle': infoCircle,
    key: key,
    'last-page': lastPage,
    lightning: lightning,
    link: link,
    list: list,
    lists: lists,
    lock: lock,
    logout: logout,
    magnify: magnify,
    'media-closed-caption': mediaClosedCaption,
    'media-fullscreen': mediaFullscreen,
    'media-pause': mediaPause,
    'media-play': mediaPlay,
    'media-stop': mediaStop,
    'media-volume-down': mediaVolumeDown,
    'media-volume-up': mediaVolumeUp,
    menu: menu,
    minimize: minimize,
    mobile: mobile,
    'move-horizontal': moveHorizontal,
    'move-point': movePoint,
    'move-vertical': moveVertical,
    no: no,
    paperclip: paperclip,
    pencil: pencil,
    phone: phone,
    'plus-circle-alt': plusCircleAlt,
    'plus-circle': plusCircle,
    plus: plus,
    popin: popin,
    popout: popout,
    'question-circle-outline': questionCircleOutline,
    'question-circle': questionCircle,
    'question-square': questionSquare,
    'radio-off': radioOff,
    'radio-on': radioOn,
    realtime: realtime,
    redo: redo,
    'refresh-square': refreshSquare,
    refresh: refresh,
    restricted: restricted,
    'scale-down': scaleDown,
    'scale-up-panel': scaleUpPanel,
    'scale-up': scaleUp,
    share: share,
    star: star,
    stop: stop,
    subscribe: subscribe,
    'talk-bubble': talkBubble,
    'thumbs-down-fill': thumbsDownFill,
    'thumbs-down-outline': thumbsDownOutline,
    'thumbs-up-fill': thumbsUpFill,
    'thumbs-up-outline': thumbsUpOutline,
    timer: timer,
    trash: trash,
    undo: undo,
    unlink: unlink,
    unlock: unlock,
    upload: upload,
    'user-gear': userGear,
    'user-headset': userHeadset,
    'user-key': userKey,
    'user-lock': userLock,
    user: user,
    'x-circle-alt': xCircleAlt,
    'x-circle': xCircle,
    x: x,
    zoom: zoom,
};
