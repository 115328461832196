import styled, { css } from 'styled-components';
import { MOUSE_MODE_CLASS } from '../FocusManager';
import type { ButtonContainerProps } from './Button.types';

const defaultStyles = ({ $kind, theme: { Button } }: ButtonContainerProps) => css`
    color: ${Button.colors[$kind].text};
    background-color: ${Button.colors[$kind].background};
    border-color: ${Button.colors[$kind].border};
    &:visited {
        color: ${Button.colors[$kind].text};
    }
`;

const hoverStyles = ({ $kind, theme: { Button } }: ButtonContainerProps) => css`
    color: ${Button.colors[$kind].hoverText};
    background-color: ${Button.colors[$kind].hoverBackground};
    border-color: ${Button.colors[$kind].hoverBorder};
    outline: none;
`;

const activeStyles = ({ $kind, theme: { Button } }: ButtonContainerProps) => css`
    color: ${Button.colors[$kind].activeText};
    background-color: ${Button.colors[$kind].activeBackground};
    border-color: ${Button.colors[$kind].activeBorder};
    outline: none;
`;

const disabledStyles = ({ $kind, theme: { Button } }: ButtonContainerProps) => css`
    &,
    &:hover {
        color: ${Button.colors[$kind].disabledText};
        background-color: ${Button.colors[$kind].disabledBackground};
        border-color: ${Button.colors[$kind].disabledBorder};
    }
`;

export const ButtonContainer = styled.button<ButtonContainerProps>`
    /* Reset styling */
    appearance: none !important;
    border: none;
    font-family: inherit;

    /* Content alignment */
    height: 40px;
    min-width: 40px;
    padding: 6px 12px;
    box-sizing: border-box;

    font-size: ${({ theme }) => theme.fontSizes.base};
    font-weight: ${({ theme, $kind }) =>
        ['primary', 'destruction'].includes($kind) ? theme.fontWeights.demi : theme.fontWeights.medium};
    white-space: nowrap;

    border-width: 1px;
    border-style: solid;
    border-radius: 0;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    cursor: default;

    ${defaultStyles}

    &:focus {
        ${hoverStyles}
        outline: 2px solid ${({ theme }) => theme.colors.focus};
    }

    &:-moz-focusring,
    &[type='button']:-moz-focusring {
        outline: 2px solid ${({ theme }) => theme.colors.focus};
    }

    &:hover {
        ${hoverStyles}
    }

    &.active,
    &:active {
        ${activeStyles}
    }

    &:disabled {
        ${disabledStyles}
    }

    .${MOUSE_MODE_CLASS} && {
        &:focus {
            ${defaultStyles}
            outline: none;
        }

        &:hover {
            ${hoverStyles}
        }

        &.active,
        &:active {
            ${activeStyles}
        }

        &:disabled {
            ${disabledStyles}
        }
    }

    &.icon-only {
        padding: 0;
    }

    .bbui-button-icon:not(:only-child) {
        margin-right: 8px;
    }
`;
