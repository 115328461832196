import React from 'react';

import { PopoverProps } from '../Popover/Popover.types';
import { StyledPopover } from './Tooltip.styles';

import { FCC } from '../__utils__/types';

export type TooltipProps = Omit<PopoverProps, 'arrow' | 'interactive'>;

namespace Tooltip {
    export type Props = TooltipProps;
}

/**
 * A short, informational message that appears when hovering on a UI element.
 */
const Tooltip: FCC<TooltipProps> = ({
    maxWidth = 380,
    hoverDelay = 500,
    placement = 'bottom-end',
    children,
    ...rest
}) => {
    return (
        <StyledPopover
            {...rest}
            maxWidth={maxWidth}
            hoverDelay={hoverDelay}
            placement={placement}
            arrow={false}
            interactive={false}
        >
            {children}
        </StyledPopover>
    );
};

export default Tooltip;
