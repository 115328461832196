import styled from 'styled-components';
import { MOUSE_MODE_CLASS } from '../FocusManager';

export const TitleBarContainer = styled.div<{ width: string }>`
    width: ${({ width }) => width};
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledTitleBar = styled.header`
    background-color: ${({ theme }) => theme.header.colors.background};
    color: ${({ theme }) => theme.header.colors.text};

    padding-left: 32px;
    padding-right: 32px;
    height: 60px;
    display: flex;
    flex: 0 0 auto;
    position: relative;

    .bbui-titlebar-brand-wrapper {
        display: flex;
        align-items: center;
    }

    .bbui-skipnav {
        position: absolute;
        left: -9999px;
        &:focus,
        &:active {
            position: relative;
            left: 10px;
        }
    }

    a.bbui-titlebar-brand {
        &:hover {
            outline: none;
            background: ${({ theme }) => theme.header.colors.titleBar.hover};
        }
        &:focus {
            outline: 1px solid ${({ theme }) => theme.header.colors.link.active};
        }
    }

    .bbui-titlebar-brand {
        font-size: ${({ theme }) => theme.fontSizes.xlarge};
        padding: 2px 0px;
        text-decoration: none;
        color: inherit;
        user-select: none;
        .bbui-titlebar-brand-content {
            display: flex;
            align-items: center;
        }

        .${MOUSE_MODE_CLASS} &:focus {
            outline: none;
        }

        .bbui-titlebar-brand-company {
            border-right: 1px solid ${({ theme }) => theme.header.colors.titleBar.border};
            padding-right: 10px;
            display: flex;
            height: 27px;
            svg {
                height: 100%;
            }
        }
    }

    .bbui-titlebar-brand-company,
    .bbui-titlebar-brand-product {
        color: ${({ theme }) => theme.header.colors.text};
    }

    .bbui-titlebar-env {
        margin-top: 5px;
    }

    .bbui-titlebar-brand-product,
    .bbui-titlebar-env {
        white-space: pre;
        font-weight: ${({ theme }) => theme.fontWeights.normal};
        padding-left: 10px;
    }
`;
