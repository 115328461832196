import React, { forwardRef, useState } from 'react';
import cn from 'clsx';
import { ButtonContainer } from './Button.styles';
import Icon, { IconNames } from '../Icon';
import type { ButtonPropsWithAs, ButtonPropsWithNoAs, ButtonType } from './Button.types';
import { isTriggerKey } from './Button.utils';

namespace Button {
    export type Props<AsProp extends keyof JSX.IntrinsicElements = 'button'> =
        | ButtonPropsWithNoAs
        | ButtonPropsWithAs<AsProp>;
}

/**
 * @visibleName Button
 */
const ButtonWithoutRef: ButtonType = <AsProp extends keyof JSX.IntrinsicElements = 'button'>({
    // Certain aria-related properties, such as "role" and "tabindex", should be passed with explicit defaults
    // in case the `as` prop is used, and a different HTML tag that doesn't have acceptable defaults for these
    // attributes (such as a `div`) is specified.
    as,
    children,
    className,
    disabled,
    icon,
    onBlur,
    onClick,
    onKeyDown,
    onKeyUp,
    type,
    kind = 'secondary',
    tabIndex = 0,
    innerRef,
    ...restProps
}: ButtonPropsWithNoAs | ButtonPropsWithAs<AsProp>) => {
    const [isActive, setIsActive] = useState(false);
    // Can't use the `icon` prop from the closure because user-defined type guards only work with function
    // parameters
    const isIconShown = (icon: IconNames | undefined): icon is IconNames => Boolean(icon);

    const isOnlyIconShown = () => isIconShown(icon) && !children;

    function handleBlur(event: React.FocusEvent<HTMLButtonElement>) {
        setIsActive(false);
        // These `anys` are necessary because the type for these events are complicated due to the fact that
        // JSX.IntrisicElements includes both HTML and SVG elements. The type will be correctly inferred when
        // the component is used, so as long as this is type-safe for consumers, the potential danger here seems
        // minimal.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onBlur?.(event as any);
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLButtonElement>) {
        // See above comment for explanation of this usage of `any`
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onKeyDown?.(event as any);
        if (isTriggerKey(event.key)) {
            event.preventDefault();
            setIsActive(true);
        }
    }

    function handleKeyUp(event: React.KeyboardEvent<HTMLButtonElement>) {
        onKeyUp?.(event as any); // eslint-disable-line @typescript-eslint/no-explicit-any
        if (isTriggerKey(event.key)) {
            setIsActive(false);
            // Click unless defaultPrevented by user
            if (!event.defaultPrevented) {
                // See above comment for explanation of these usages of `any`
                onClick?.(event as any); // eslint-disable-line @typescript-eslint/no-explicit-any
            }
            event.preventDefault();
        }
    }

    return (
        <ButtonContainer
            $kind={kind}
            // aria `role="button"` should not be added here since <button> gets this by default
            // and becomes invalid when the "as" prop is used. Consumer can still override if desired.
            // Set a fallback aria-label for icon-only buttons. Consumers are encouraged to override this.
            aria-label={isOnlyIconShown() ? icon : undefined}
            // This `any` is necessary because otherwise `ButtonContainer` will throw an obtuse type error which
            // seems to be caused by the fact that our typing for the `as` prop is different from theirs. Our types
            // are different because we only need to support native HTML tag names, not other React components. I
            // tried adding support for other React components, just to avoid this usage of `any`, but it caused
            // type checking for the component to break.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            as={as as any}
            className={cn('bbui-button', kind, className, {
                active: isActive,
                'icon-only': isOnlyIconShown(),
            })}
            disabled={disabled}
            onBlur={handleBlur}
            onClick={onClick}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            type={!as && !type ? 'button' : type || null}
            ref={innerRef}
            tabIndex={tabIndex}
            {...restProps}
        >
            {isIconShown(icon) ? <Icon className="bbui-button-icon" name={icon} /> : null}
            {children ? <span className="bbui-button-contents">{children}</span> : null}
        </ButtonContainer>
    );
};

// Using ButtonPropsWithNoAs here because ButtonPropsWithAs requires a generic argument, and there's no need to get
// fancy when the type is going to be cast anyway.
const Button = forwardRef<HTMLElement, ButtonPropsWithNoAs>((props, ref) => (
    <ButtonWithoutRef {...props} innerRef={ref} />
));
Button.displayName = 'Button';

// This type assertion is necessary because the type normally returned by `forwardRef` will break the dynamic type
// inference required by the `as` prop.
export default Button as ButtonType;
