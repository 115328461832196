import React, { useState, useRef, useEffect, useCallback } from 'react';
import cn from 'clsx';
import Icon from '../Icon';
import { NotificationMessageView, NotificationMessageDropdown } from './Notification.styles';
import { NotificationMessageProps } from './Notification.types';
import useTruncatedHover from '../__utils__/useTruncatedHover';
import Tooltip from '../Tooltip';
import { FCC } from '../__utils__/types';

export const NotificationMessage: FCC<NotificationMessageProps> = ({
    isHeader,
    icon,
    dismissIcon,
    dropdownContent,
    kind,
    message,
    notification,
}) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [labelRef, labelIsTruncated] = useTruncatedHover<HTMLDivElement>();
    const [isOpen, setIsOpen] = useState(false);
    const hasDropdown = Boolean(notification?.details || dropdownContent);
    const dropdownId = `bbui-notification-${isHeader ? kind : notification?.id || ''}-dropdown`;

    const animateDrawer = useCallback(
        (open: boolean) => {
            const dropdown = dropdownRef.current;
            if (!dropdown) return;
            if (open) {
                dropdown.style.maxHeight = `${dropdown.scrollHeight + 1}px`;
                setTimeout(() => {
                    dropdown.style.maxHeight = 'none';
                }, 200);
            } else {
                dropdown.style.maxHeight = `${dropdown.scrollHeight + 1}px`;
                requestAnimationFrame(() => {
                    dropdown.scrollTop; // force reflow
                    dropdown.style.maxHeight = '0';
                });
            }
        },
        [dropdownRef]
    );

    useEffect(() => {
        animateDrawer(isOpen);
    }, [animateDrawer, isOpen]);

    const handleClick = () => {
        if (hasDropdown) {
            setIsOpen((p) => !p);
        }
        if (notification?.timeoutState) {
            clearTimeout(notification.timeoutState.id);
            notification.timeoutState = undefined;
        }
    };
    const messageId = `bbui-notification-${kind}-message-${notification?.id || 'header'}`;

    return (
        <>
            <NotificationMessageView
                className={cn('bbui-notification-message', kind, { 'has-dropdown': hasDropdown })}
                $kind={kind}
                onClick={handleClick}
                aria-expanded={hasDropdown ? isOpen : undefined}
                aria-owns={hasDropdown ? dropdownId : undefined}
                tabIndex={0}
                ref={labelRef}
                role="group"
                aria-labelledby={messageId}
            >
                {isHeader && icon}
                <Tooltip disabled={!labelIsTruncated} className="bbui-notification-overflow-tooltip" content={message}>
                    <span id={messageId} className="bbui-notification-message-content">
                        <span aria-label={`${kind} notification:`} />
                        {message}
                    </span>
                </Tooltip>
                {hasDropdown && (
                    <button
                        className="bbui-notification-dropdown-toggle"
                        aria-label={'toggle notification details'}
                        aria-pressed={isOpen}
                        aria-controls={dropdownId}
                        onClick={(e) => {
                            handleClick();
                            e.stopPropagation();
                        }}
                    >
                        {notification?.details && <span className="bbui-notifications-details-label"> Details</span>}
                        <Icon name="chevron-down" title={'dropdown'} />
                    </button>
                )}
                {isHeader && (!dropdownContent || isOpen) && dismissIcon}
            </NotificationMessageView>
            {hasDropdown && (
                <NotificationMessageDropdown
                    className={cn('bbui-notification-dropdown', { 'is-header': isHeader })}
                    aria-hidden={!isOpen}
                    id={dropdownId}
                    ref={dropdownRef}
                >
                    {notification?.details ? (
                        <div className="bbui-notification-details" tabIndex={0} aria-label="Notification Details">
                            {notification.details}
                        </div>
                    ) : (
                        dropdownContent
                    )}
                </NotificationMessageDropdown>
            )}
        </>
    );
};

export default NotificationMessage;
