import { useRef } from 'react';

const generateRandom = (prefix = 'bbui-field-') => {
    const unique = Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    return `${prefix}${unique}`;
};

/**
 * Generates a unique ID for fields.
 * This is used to couple a <label> to the <input> (or aria-labelledby relationships),
 * for when the whole input can't be wrapped in `<label>` (most likely because it would contain block elements).
 * It doesn't have to be pretty, but it can be set manually with the `override` parameter.
 */
export const useFieldId = (override?: string, prefixOverride?: string) => {
    const fieldId = useRef<string>(override || generateRandom(prefixOverride));
    return fieldId.current;
};
