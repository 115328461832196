import styled from 'styled-components';

import { MOUSE_MODE_CLASS } from '../../FocusManager';

export const StyledIconLinkContainer = styled.div`
    display: inline-block;
    position: relative;
    margin: 0 4px;

    .bbui-titlebar-iconlink-popper {
        position: absolute;
        right: 0;
        box-shadow: ${({ theme }) => theme.shadows.default};
        min-width: 100%;
        z-index: ${({ theme }) => theme.depths.tooltip};
        &[aria-hidden='true'] {
            display: none;
        }
    }

    .bbui-titlebar-iconlink-menu {
        background-color: ${({ theme }) => theme.header.colors.iconLink.background};
        display: none;
        list-style: none;
        margin: 0;
        outline: none;
        padding: 6px 0;
        width: 100%;

        &.open {
            display: block;
        }
    }

    .bbui-titlebar-iconlink-menuitem {
        align-items: center;
        color: ${({ theme }) => theme.header.colors.iconLink.text};
        cursor: pointer;
        display: flex;
        font-size: ${({ theme }) => theme.fontSizes.base};
        padding: 6px 16px;
        position: relative;
        text-align: left;
        white-space: nowrap;
        width: 100%;

        text-decoration: none;
        &:visited,
        &:hover,
        &:focus,
        &:active {
            outline: none;
            color: ${({ theme }) => theme.header.colors.iconLink.text};
        }

        &.highlighted {
            background-color: ${({ theme }) => theme.header.colors.iconLink.hover};
        }
        &.readonly {
            cursor: default;
            font-size: ${({ theme }) => theme.fontSizes.small};
            color: ${({ theme }) => theme.header.colors.iconLink.readonly};
        }
        &.divider {
            border-bottom: 2px solid ${({ theme }) => theme.header.colors.iconLink.border};
            padding: 0;
            margin: 3px 0;
        }
        &.selected {
            background-color: ${({ theme }) => theme.header.colors.menuItem.selected};

            ::before {
                content: '';
                position: absolute;
                background-color: ${({ theme }) => theme.header.colors.menuItem.border};
                width: 4px;
                height: 100%;
                bottom: 0;
                left: 0;
            }
        }
        .bbui-switch > span {
            color: inherit;
        }
    }

    .bbui-titlebar-iconlink-button {
        position: relative;
        color: ${({ theme }) => theme.header.colors.text};
        padding: 3px;
        max-height: 32px;
        min-width: 32px;

        .bbui-button-contents {
            display: inline-flex;
            align-items: center;
        }

        &:focus {
            outline: 1px solid ${({ theme }) => theme.colors.focus};
        }

        &.open,
        &.active {
            color: ${({ theme }) => theme.Button.colors.tertiary.activeText};
            background-color: ${({ theme }) => theme.Button.colors.tertiary.activeBackground} !important;
            border-color: ${({ theme }) => theme.Button.colors.tertiary.activeBorder};
            outline: 1px solid ${({ theme }) => theme.colors.focus};
            z-index: ${({ theme }) => theme.depths.tooltip + 1};
        }
        .${MOUSE_MODE_CLASS} & {
            &.open {
                outline: none;
                z-index: 0;
            }
        }

        &:hover {
            outline: none;
            background-color: ${({ theme }) => theme.header.colors.iconLink.hover};
        }

        .bbui-titlebar-iconlink-svg {
            margin: 4px;
        }

        .bbui-titlebar-iconlink-down {
            font-size: 7px;
            margin: auto 0 auto 3px;
        }

        .${MOUSE_MODE_CLASS} &:focus {
            outline: none;
        }
    }
`;
