import React from 'react';
import cn from 'clsx';
import Icon, { IconNames } from '../Icon/Icon';
import NotificationMessage from './NotificationMessage';
import { StyledNotificationGroup } from './Notification.styles';
import { NotificationKind, NotificationGroupProps } from './Notification.types';
import Button from '../Button';

function getIconNameForType(kind: NotificationKind): IconNames {
    const icons = {
        info: 'info-circle' as IconNames,
        success: 'check-circle' as IconNames,
        warning: 'exclamation-circle' as IconNames,
        error: 'exclamation-diamond' as IconNames,
    } as const;
    return icons[kind];
}

export function NotificationGroup({ kind, className, notifications, onDismiss }: NotificationGroupProps) {
    const isVisible = notifications.length > 0;
    const multiple = notifications.length > 1;

    const icon = (
        <Icon
            className="bbui-notification-notif-icon"
            name={getIconNameForType(kind)}
            title={`${kind} notification`}
            role="img"
        />
    );

    const close = (
        <Button
            data-notification-kind={kind}
            className="bbui-notification-close-icon"
            aria-label="dismiss notifications"
            onClick={onDismiss}
            icon="x"
            kind="tertiary"
        />
    );

    let mainNotificationMessage: JSX.Element | null = null;
    const notificationMessages = notifications.map((m) => (
        <NotificationMessage
            key={m.id}
            isHeader={!multiple}
            kind={kind}
            icon={icon}
            dismissIcon={close}
            notification={m}
            message={m.message}
        />
    ));
    if (multiple) {
        mainNotificationMessage = (
            <NotificationMessage
                isHeader={true}
                kind={kind}
                message={`${notifications.length} items`}
                icon={icon}
                dismissIcon={close}
                dropdownContent={notificationMessages}
            />
        );
    } else if (notifications.length === 1) {
        mainNotificationMessage = notificationMessages[0];
    }
    const handleMouseEnter = () => {
        const now = new Date().getTime();
        notifications.forEach((notif) => {
            const timeoutState = notif.timeoutState;
            if (timeoutState) {
                clearTimeout(timeoutState.id);
                timeoutState.timeLeft = timeoutState.timeLeft - (now - timeoutState.start);
            }
        });
    };
    const handleMouseLeave = () => {
        const now = new Date().getTime();
        notifications.forEach((notif) => {
            const timeoutState = notif.timeoutState;
            if (timeoutState) {
                window?.setTimeout(() => {
                    notif.removeSelf();
                }, timeoutState.timeLeft);
                timeoutState.start = now;
            }
        });
    };

    const handleClick = () => {
        notifications.forEach((notif) => {
            if (notif.timeoutState) {
                clearTimeout(notif.timeoutState.id);
                notif.timeoutState = undefined;
            }
        });
    };

    return (
        <StyledNotificationGroup
            className={cn('bbui-notification-group', className)}
            $kind={kind}
            aria-hidden={!isVisible}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
        >
            {mainNotificationMessage}
        </StyledNotificationGroup>
    );
}

export default NotificationGroup;
