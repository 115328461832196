import { useRef, useState, useEffect, useCallback } from 'react';

/*
 * This hook allows you to detect whether text width has overflowed (on hover)
 * its container by applying a ref to the element and an event listener for
 * mouseEnter/over. On mouseEnter/over, the hook check's the ref's offsetWidth
 * to see if it is less than its scrollWidth
 *
 * The scrollWidth value is equal to the minimum width the element would
 * require in order to fit all the content in the viewport without using a
 * horizontal scrollbar (see https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollWidth)
 *
 * The HTMLElement.offsetWidth read-only property returns the layout width of
 * an element as an integer. OffsetWidth includes borders/padding/vertical
 * scrollbars but *not* pseudo-elements (::after / ::before). (see https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetWidth)
 *
 */

const useTruncatedHover = <T extends HTMLElement = HTMLElement>(): [React.Ref<T>, boolean] => {
    const [value, setValue] = useState(false);
    const ref = useRef<T>(null);

    const handleMouseEnter = useCallback((node): void => {
        setValue(node.offsetWidth < node.scrollWidth);
    }, []);

    useEffect(() => {
        const node = ref.current;
        if (node) {
            node.addEventListener('mouseover', () => {
                handleMouseEnter(node);
            });
            return (): void => {
                node.removeEventListener('mouseover', handleMouseEnter);
            };
        }
    }, [handleMouseEnter]);
    return [ref, value];
};

export default useTruncatedHover;
