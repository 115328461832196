import styled, { css } from 'styled-components';
import { InputProps } from './Input';

export const StyledInput = styled.input<InputProps>`
    align-items: center;
    width: 100%;
    font-family: inherit;
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.base};
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.formElements.colors.background};
    border: 1px solid ${({ theme }) => theme.formElements.colors.border};
    height: 40px;
    min-width: 0;
    appearance: none !important;
    padding: 1px 12px;
    line-height: normal;
    -moz-appearance: textfield !important; // Firefox

    /* Hide the cancel button for Edge/IE*/
    &::-ms-clear {
        display: none;
    }

    /* Hide the default cancel button for Chrome/Safari */
    &[type='search']::-webkit-search-decoration,
    &[type='search']::-webkit-search-cancel-button,
    &[type='search']::-webkit-search-results-button,
    &[type='search']::-webkit-search-results-decoration {
        appearance: none !important;
    }

    &::placeholder {
        color: ${({ theme }) => theme.formElements.colors.placeholder};
    }

    &:focus {
        border-color: ${({ theme }) => theme.formElements.colors.focus};
        outline: none;
        padding: 0 11px;
        border-width: 2px;
    }

    &::selection {
        background: ${({ theme }) => theme.formElements.colors.selected};
        color: ${({ theme }) => theme.colors.white};
    }

    &.bbui-input-touched:invalid {
        border-color: ${({ theme }) => theme.colors.error.graphic};
        padding: 0 11px;
        border-width: 2px;
    }

    &:disabled {
        border: none;
        background-color: ${({ theme }) => theme.formElements.colors.disabledBackground};
        color: ${({ theme }) => theme.colors.disabledText};
        &::placeholder {
            color: ${({ theme }) => theme.colors.disabledText};
        }
    }

    ${({ theme, validation }) =>
        validation && theme.colors[validation]
            ? css`
                  padding: 0 11px;
                  border-width: 2px;
                  border-color: ${theme.colors[validation].graphic};

                  &:focus {
                      border-color: ${theme.colors[validation].graphic};
                  }
              `
            : ''}
`;
