import styled from 'styled-components';
import { MOUSE_MODE_CLASS } from '../FocusManager';

export const SplitButtonContainer = styled.div<{ $kind: 'primary' | 'secondary' | 'tertiary' }>`
    display: inline-block;
    min-height: 40px;
    position: relative;

    .bbui-splitbutton-reference {
        align-items: stretch;
        display: flex;
    }

    .bbui-splitbutton-buttonparent {
        .bbui-button {
            border-right-width: 0;
        }

        &:focus-within {
            z-index: 1;

            .bbui-button {
                border-right-width: 1px;
            }

            ~ .bbui-splitbutton-dividers {
                display: none;
            }

            ~ .bbui-splitbutton-togglebutton {
                z-index: 0;
            }
        }

        .${MOUSE_MODE_CLASS} &:focus-within {
            .bbui-button {
                border-right-width: 0;
            }

            ~ .bbui-splitbutton-dividers {
                display: flex;
            }
        }
    }

    .bbui-splitbutton-togglebutton {
        border-left-width: 0;
        /** Just a simple trick to not apply the CSS for IE11 */
        @supports (height: auto) {
            height: auto;
            min-height: 40px;
        }

        .bbui-icon {
            display: flex;
        }

        &.open {
            color: ${({ theme, $kind }) => theme.Button.colors[$kind].activeText};
            background-color: ${({ theme, $kind }) => theme.Button.colors[$kind].activeBackground};
            border-color: ${({ theme, $kind }) => theme.Button.colors[$kind].activeBorder};
            outline: 2px solid ${({ theme }) => theme.colors.focus};
            z-index: ${({ theme }) => theme.depths.tooltip + 1};
        }

        &:focus,
        &.open {
            border-left-width: 1px;
            margin-left: -1px;

            ~ .bbui-splitbutton-buttonparent .bbui-button {
                border-right-width: 0;
            }

            ~ .bbui-splitbutton-dividers {
                display: none;
            }
        }

        .${MOUSE_MODE_CLASS} & {
            &.open {
                outline: none;
                z-index: 0;
            }

            &:focus,
            &.open {
                border-left-width: 0;
                margin-left: 0;

                ~ .bbui-splitbutton-dividers {
                    display: flex;
                }
            }
        }
    }

    .bbui-splitbutton-dividers {
        background-color: ${({ theme, $kind }) =>
            $kind === 'primary' ? theme.SplitButton.colors.primary.divider : theme.Button.colors[$kind].background};
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .bbui-splitbutton-divider {
            border: 0;
            border-left: 1px;
            border-style: solid;
            border-color: ${({ theme, $kind }) => theme.SplitButton.colors[$kind].divider};
            height: 1.25rem;
            width: 0;
        }

        .bbui-splitbutton-bordercontinuation {
            border: 0;
            border-left: ${({ $kind }) => ($kind === 'tertiary' ? '0' : '1px')};
            border-style: solid;
            border-color: ${({ theme, $kind }) => theme.SplitButton.colors[$kind].divider};
            height: 1px;
            width: 0;
        }

        &.disabled {
            .bbui-splitbutton-divider,
            .bbui-splitbutton-bordercontinuation {
                border-color: ${({ theme, $kind }) => theme.SplitButton.colors[$kind].disabledDivider};
            }
        }
    }

    .bbui-splitbutton-popper {
        box-shadow: ${({ theme }) => theme.shadows.default};
        min-width: 100%;
        z-index: ${({ theme }) => theme.depths.tooltip};
    }

    .bbui-splitbutton-menu {
        background-color: ${({ theme }) => theme.popovers.colors.background};
        display: none;
        list-style: none;
        margin: 0;
        outline: none;
        padding: 6px 0;
        width: 100%;

        &.open {
            display: block;
        }
    }

    .bbui-splitbutton-menuitem {
        align-items: center;
        color: ${({ theme }) => theme.popovers.colors.text};
        cursor: pointer;
        display: flex;
        font-size: ${({ theme }) => theme.fontSizes.base};
        padding: 6px 16px;
        position: relative;
        text-align: left;
        white-space: nowrap;

        &.highlighted {
            background-color: ${({ theme }) => theme.formElements.options.colors.highlight};
        }

        &:active:not(.disabled) {
            color: ${({ theme }) => theme.formElements.options.colors.selectedText};
            background-color: ${({ theme }) => theme.formElements.options.colors.selectedHighlightBackground};
        }

        &.disabled {
            color: ${({ theme }) => theme.formElements.options.colors.disabledText};
            cursor: default;
        }
    }
`;
