import styled from 'styled-components';
import { stackStyles } from './Stack.styles';
import type { StackProps } from './Stack.types';

namespace Stack {
    export type Props = StackProps;
}

/**
 * Provides vertical or horizontal spacing between components which are arranged in a row or column.
 */
const Stack = styled.div
    .withConfig({ shouldForwardProp: (prop) => !['alignment', 'direction', 'space'].includes(prop) })
    .attrs<StackProps>(() => ({ className: 'bbui-stack' }))<StackProps>`
    ${(props) => stackStyles(props)}
`;

// These defaults are provided by stackStyles, and they need to be there because
// stackStyles may be used separately from the Stack component, but adding them
// here as well helps ensure they show up in Styleguidist
Stack.defaultProps = {
    alignment: 'start',
    direction: 'column',
    space: '1rem',
};

export default Stack;
