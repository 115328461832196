import React, { FieldsetHTMLAttributes, HTMLAttributes, LabelHTMLAttributes } from 'react';
import cn from 'clsx';
import { FormFieldProps } from './FormField.types';
import { StyledFormField, StyledFormFieldContent, ValidationContentContainer } from './FormField.styles';
import Typography from '../Typography';

import { FCC } from '../__utils__/types';

namespace FormField {
    export type Props = FormFieldProps;
}

const FormField: FCC<FormFieldProps> = ({
    className,
    validationContent,
    validationType,
    labelPosition = 'top',
    disabled,
    htmlFor,
    isGroup = false,
    disableAutoMargin = false,
    label: labelNode,
    labelDescription,
    children,
    ...rest
}) => {
    const derivedLabelPosition = isGroup ? 'top' : labelPosition;

    const fieldSetProps: { as?: keyof JSX.IntrinsicElements } & HTMLAttributes<HTMLDivElement> &
        FieldsetHTMLAttributes<HTMLFieldSetElement> = {};
    const labelProps: { as?: keyof JSX.IntrinsicElements } & LabelHTMLAttributes<HTMLLabelElement> &
        HTMLAttributes<HTMLLegendElement> = {
        htmlFor,
    };

    if (isGroup) {
        fieldSetProps.as = 'fieldset';
        fieldSetProps.disabled = disabled;

        labelProps.as = 'legend';
        labelProps.htmlFor = undefined;
    }

    return (
        <StyledFormField
            className={cn('bbui-formfield', className, { 'bbui-disable-vertical-margin': disableAutoMargin })}
            {...rest}
        >
            <StyledFormFieldContent
                className={cn('bbui-formfield-content', { 'bbui-formfield-group': isGroup })}
                data-label-position={derivedLabelPosition}
                {...fieldSetProps}
            >
                {labelNode && (
                    <Typography.FormLabel className={cn('bbui-formfield-label', { disabled })} {...labelProps}>
                        {labelNode}
                        {labelDescription && (
                            <span className="bbui-formfield-label-description">{labelDescription}</span>
                        )}
                    </Typography.FormLabel>
                )}
                <div className="bbui-formfield-content-field">
                    {React.Children.map(children, (child) =>
                        React.isValidElement(child)
                            ? React.cloneElement(child, {
                                  ...(validationType && { validation: validationType }),
                                  ...(typeof disabled === 'boolean' && { disabled }),
                              })
                            : child
                    )}
                </div>
            </StyledFormFieldContent>
            {validationContent ? (
                <ValidationContentContainer data-validation-type={validationType}>
                    {validationContent}
                </ValidationContentContainer>
            ) : null}
        </StyledFormField>
    );
};

export default FormField;
