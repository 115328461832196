import styled, { css } from 'styled-components';
import { MOUSE_MODE_CLASS } from '../FocusManager';
import Button from '../Button';

export const BasedropdownPopper = styled.div`
    line-height: 20px;

    background-color: ${({ theme }) => theme.dropdowns.colors.background};
    box-shadow: ${({ theme }) => theme.shadows.default};
    z-index: ${({ theme }) => theme.depths.tooltip};
    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
        clip: rect(0, 0, 0, 0);
    }
`;

export const BasedropdownMenuHeader = styled.div`
    padding: 8px 9px;
`;

export const BasedropdownMenu = styled.ul`
    color: ${({ theme }) => theme.dropdowns.colors.text};
    position: relative;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 6px 0;
    width: 100%;
    max-height: 262px;
    overflow-y: auto;
    overflow-x: hidden;
    &.no-max {
        max-height: unset;
    }

    li {
        font-size: ${({ theme }) => theme.fontSizes.base};
        cursor: default;
        user-select: none;
        padding: 6px 14px;
        white-space: nowrap;
        strong {
            font-weight: ${({ theme }) => theme.fontWeights.demi};
        }
    }
    &.dividers {
        li {
            border-top: 1px solid ${({ theme }) => theme.dropdowns.colors.hover};
            &:first-of-type {
                border-top: none;
            }
        }
    }
`;

export const BaseDropdownMenuAction = styled(Button)`
    border-top: 1px solid ${({ theme }) => theme.dropdowns.colors.border};
    background-color: ${({ theme }) => theme.dropdowns.colors.background};
    padding: 12px 14px;
    bottom: 0;
    display: block;
    width: 100%;
    text-align: left;
    &.highlighted,
    &:hover {
        background-color: ${({ theme }) => theme.dropdowns.colors.hover};
    }
`;

export const BaseDropdownOption = styled.li`
    &[aria-selected='true'] {
        background: ${({ theme }) => theme.dropdowns.colors.hover};
    }
    &:active {
        background: ${({ theme }) => theme.dropdowns.colors.active};
    }
    &.selected {
        color: ${({ theme }) => theme.dropdowns.colors.selected};
        font-weight: ${({ theme }) => theme.fontWeights.demi};
    }
`;

export const BaseDropdownGroupHeader = styled.li`
    color: ${({ theme }) => theme.dropdowns.colors.groupHeader};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.small};
    text-transform: uppercase;
    letter-spacing: 1px;
    border-top: 1px solid ${({ theme }) => theme.dropdowns.colors.groupDivider};
    margin-top: 12px;

    .bbui-basedropdown-group-counter {
        float: right;
        padding: 2px 5px;
        background: ${({ theme }) => theme.dropdowns.colors.hover};
        border-radius: 5px;
    }
`;

export const BaseDropdownOptionNoResults = styled.li`
    color: ${({ theme }) => theme.colors.disabledText};
`;

export const BaseDropdownWrapper = styled.div`
    line-height: 20px;
    position: relative;
`;

export const BaseInputGroupStyles = css`
    position: relative;
    input {
        font-size: ${({ theme }) => theme.fontSizes.base};
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .bbui-input-group-addon .bbui-button {
        &:hover,
        &:active,
        &:focus {
            background: none;
            border-color: transparent;
        }
    }
    &.disabled:focus-within {
        padding: 1px;
    }

    .${MOUSE_MODE_CLASS} &.searchable:focus,
    &.searchable:focus-within {
        border-color: ${({ theme }) => theme.formElements.colors.border};
        border-width: 1px;
        padding: 1px;
    }

    &.searchable:focus,
    &.open:not(.searchable) {
        border-color: ${({ theme }) => theme.formElements.colors.focus};
        outline: none;
        border-width: 2px;
        padding: 0;
    }
`;
