import styled from 'styled-components';

export const StyledSpan = styled.span`
    display: inline-block;
    position: relative;
    vertical-align: -0.125em;

    svg {
        display: block;
        fill: currentColor;
        height: 1em;
        width: 1em;
    }
`;
