import React from 'react';
import cn from 'clsx';

import { StyledTitleBarLinks } from './TitleBar.Links.styles';
import { FCC } from '../../__utils__/types';

namespace TitleBarLinks {
    export type Props = React.HTMLAttributes<HTMLUListElement>;
}

/**
 * Thin wrapper around a `<StyledTitleBarLinks />`, meant for rendering a series of links in the navigation bar.
 * @visibleName TitleBar.Links
 */
const TitleBarLinks: FCC<TitleBarLinks.Props> = ({ className = '', children, ...rest }) => {
    return (
        <StyledTitleBarLinks
            className={cn('bbui-titlebar-links', className)}
            role="list"
            aria-label={'TitleBar Links'}
            {...rest}
        >
            {children}
        </StyledTitleBarLinks>
    );
};

export default TitleBarLinks;
