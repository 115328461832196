import React from 'react';

import { FCC } from '../../__utils__/types';

namespace FooterLinks {
    export type Props = React.HTMLAttributes<HTMLUListElement>;
}

/**
 * Thin wrapper around a `<ul />`, meant for rendering a series of links in the footer.
 *
 * @visibleName Footer.Links
 */
const FooterLinks: FCC<FooterLinks.Props> = ({ children, ...rest }) => {
    if (!children) {
        return null;
    }

    return (
        <ul className="bbui-footer-links" {...rest}>
            {children}
        </ul>
    );
};

export default FooterLinks;
