import styled from 'styled-components';
import Tippy from '@tippyjs/react';

// Partially taken from "tippy.js/dist/tippy.css"
export const StyledTippy = styled(Tippy)`
    &[data-animation='fade'][data-state='hidden'] {
        opacity: 0;
    }

    position: relative;
    font-size: ${({ theme }) => theme.fontSizes.base};
    line-height: 1.4;
    outline: 0;
    transition-property: transform, visibility, opacity;
    z-index: ${({ theme }) => theme.depths.tooltip};
    border-radius: 0;
    color: ${({ theme }) => theme.popovers.colors.text};
    background-color: ${({ theme }) => theme.popovers.colors.background};
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);

    .tippy-content {
        position: relative;
        padding: 0;
        z-index: 1;
    }

    .tippy-arrow {
        width: 16px;
        height: 16px;
        &::before {
            content: '';
            position: absolute;
            border-color: transparent;
            border-style: solid;
        }
    }

    .tippy-arrow::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        z-index: -1;
        left: 50%;
        box-sizing: border-box;
        transform-origin: 0 0;
        box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.15);
    }

    &[data-placement^='top'] > .tippy-arrow {
        bottom: 0;
    }
    &[data-placement^='bottom'] > .tippy-arrow {
        top: 0;
    }
    &[data-placement^='left'] > .tippy-arrow {
        right: 0;
    }
    &[data-placement^='right'] > .tippy-arrow {
        left: 0;
    }

    &[data-inertia][data-state='visible'] {
        transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
    }

    &[data-placement^='top'] .tippy-arrow::before,
    &[data-placement^='top'] .tippy-arrow::after {
        border-top-color: ${({ theme }) => theme.popovers.colors.background};
    }
    &[data-placement^='bottom'] .tippy-arrow::before,
    &[data-placement^='bottom'] .tippy-arrow::after {
        border-bottom-color: ${({ theme }) => theme.popovers.colors.background};
    }
    &[data-placement^='left'] .tippy-arrow::before,
    &[data-placement^='left'] .tippy-arrow::after {
        border-left-color: ${({ theme }) => theme.popovers.colors.background};
    }
    &[data-placement^='right'] .tippy-arrow::before,
    &[data-placement^='right'] .tippy-arrow::after {
        border-right-color: ${({ theme }) => theme.popovers.colors.background};
    }

    &[data-placement^='top'] > .tippy-arrow:before {
        bottom: -7px;
        left: 0;
        border-width: 8px 8px 0;
        transform-origin: center top;
    }
    &[data-placement^='bottom'] > .tippy-arrow:before {
        top: -7px;
        left: 0;
        border-width: 0 8px 8px;
        transform-origin: center bottom;
    }
    &[data-placement^='left'] > .tippy-arrow:before {
        border-width: 8px 0 8px 8px;
        right: -7px;
        transform-origin: center left;
    }

    &[data-placement^='right'] > .tippy-arrow:before {
        left: -7px;
        border-width: 8px 8px 8px 0;
        transform-origin: center right;
    }

    &[data-placement^='top'] .tippy-arrow::after {
        box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.2);
        transform: rotate(-45deg);
        left: 1px;
        top: 16px;
    }
    &[data-placement^='bottom'] .tippy-arrow::after {
        box-shadow: -1px 1px 2px 0px rgba(0, 0, 0, 0.1);
        transform: rotate(135deg);
        left: 15px;
    }
    &[data-placement^='left'] .tippy-arrow::after {
        transform: rotate(-135deg);
        top: 15px;
        left: 16px;
    }
    &[data-placement^='right'] .tippy-arrow::after {
        transform: rotate(45deg);
        left: 1px;
        top: 1px;
    }
`;
