import styled from 'styled-components';

export const BannerMessage = styled.p<{ compact: boolean }>`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme, compact }) => (compact ? theme.fontSizes.small : theme.fontSizes.base)};
    line-height: ${({ theme, compact }) => (compact ? theme.lineHeight.small : theme.lineHeight.base)};
`;

export const BannerContainer = styled.div<{ width: string }>`
    width: ${({ width }) => width};
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledBanner = styled.div<{ kind: 'info' | 'warning' | 'error' | 'environment'; compact: boolean }>`
    position: relative;
    box-sizing: border-box;
    min-height: ${({ compact }) => (compact ? '20px' : '40px')};
    padding: ${({ compact }) => (compact ? '4px 20px' : '10px 20px')};
    background-color: ${({ theme, kind }) => theme.Banner.colors[kind].graphic};
    color: ${({ theme, kind }) => theme.Banner.colors[kind].textContrast};

    p {
        margin: 0 8px 0 0;
    }

    .bbui-banner-icon {
        margin-right: 8px;
    }

    .bbui-banner-content {
        align-items: center;
        display: flex;
    }

    .bbui-banner-close-icon {
        position: absolute;
        right: 0;
        top: 0;
        color: ${({ theme, kind }) => theme.Banner.colors[kind].textContrast};
        &[data-banner-kind=${({ kind }) => kind}] {
            &:hover, &:focus {
                color: ${({ theme, kind }) => theme.Banner.colors[kind].textContrast};
                background-color: ${({ theme, kind }) => theme.Banner.colors[kind].hover};
                border-color: ${({ theme, kind }) => theme.Banner.colors[kind].hover};
            }
        }
    }

    a {
        color: ${({ theme, kind }) => theme.Banner.colors[kind].textContrast};
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        font-size: ${({ theme, compact }) => (compact ? theme.fontSizes.small : theme.fontSizes.base)};
        text-decoration: underline;
        &:hover,
        &:active {
            outline: none;
            background: none;
            color: ${({ theme, kind }) => theme.Banner.colors[kind].textContrast};
            text-decoration: underline;
        }
        &:visited {
            color: ${({ theme, kind }) => theme.Banner.colors[kind].textContrast};
        }
    }
`;
