import React, { useContext, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeControlContext } from '../ThemeProvider/ThemeProvider';

export type ThemeBoundaryTheme = string | ((activeTheme: string) => string);

interface ThemeBoundaryProps {
    children: ReactNode;
    theme: ThemeBoundaryTheme;
}

namespace ThemeBoundary {
    export type Props = ThemeBoundaryProps;
}

function getThemeName(themeProp: ThemeBoundaryTheme, activeTheme: string) {
    if (themeProp === 'unset') {
        return activeTheme;
    } else if (typeof themeProp === 'function') {
        return themeProp(activeTheme);
    } else {
        return themeProp;
    }
}

function ThemeBoundary(props: ThemeBoundaryProps) {
    const { activeTheme, themes } = useContext(ThemeControlContext);
    const themeName = getThemeName(props.theme, activeTheme);
    const theme = themes.find((theme) => theme.name === themeName);
    if (!theme) throw Error(`Theme not found: "${themeName}"`);
    return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}

export default ThemeBoundary;
