import { useEffect, useState } from 'react';

export type UseElementWidthGetter<T extends HTMLElement> = (element: T) => number;

export function useElementWidth<T extends HTMLElement>(
    element?: T | null,
    getter: UseElementWidthGetter<T> = (element) => element.offsetWidth
) {
    const [width, setWidth] = useState(0);

    useEffect(() => {
        if (!element) {
            return;
        }

        if (typeof window.ResizeObserver === 'undefined') {
            const listener = () => {
                if (element) {
                    setWidth(getter(element));
                }
            };

            window.addEventListener('resize', listener);
            return () => window.removeEventListener('resize', listener);
        }

        const observer = new ResizeObserver(([entry]) => {
            if (entry) {
                setWidth(getter(entry.target as T));
            }
        });

        observer.observe(element);
        return () => observer.disconnect();
    }, [getter, element]);

    return width;
}
