import React from 'react';
import styled from 'styled-components';
import cn from 'clsx';
import { FCC } from '../__utils__/types';

export const App: FCC<React.HTMLAttributes<HTMLDivElement>> = ({ className, children, ...rest }) => (
    <StyledApp className={cn('bbui-app', className)} {...rest}>
        {children}
    </StyledApp>
);
export const Main: FCC<React.HTMLAttributes<HTMLElement>> = ({ className, children, ...rest }) => (
    <StyledMain className={cn('bbui-main', className)} {...rest}>
        {children}
    </StyledMain>
);
export const Content: FCC<React.HTMLAttributes<HTMLDivElement>> = ({ className, children, ...rest }) => (
    <StyledContent className={cn('bbui-content', className)} {...rest}>
        {children}
    </StyledContent>
);

const StyledApp = styled.div`
    min-height: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

const StyledMain = styled.main`
    display: flex;
    flex: 1;
    overflow-y: auto;
`;

const StyledContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
`;
