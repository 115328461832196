import { Theme, ThemeConfig } from './types';
import { hex2rgba } from './utils';
import * as tokens from '@bloomberg/crescent-tokens/build/js/light/tokens';

export const colorPalette = {
    black: tokens.TokenColorCoreBlack,
    white: tokens.TokenColorCoreWhite,
    red: {
        50: tokens.TokenColorCoreRed050,
        100: tokens.TokenColorCoreRed100,
        200: tokens.TokenColorCoreRed200,
        300: tokens.TokenColorCoreRed300,
        400: tokens.TokenColorCoreRed400,
        500: tokens.TokenColorCoreRed500,
        600: tokens.TokenColorCoreRed600,
        700: tokens.TokenColorCoreRed700,
        800: tokens.TokenColorCoreRed800,
        900: tokens.TokenColorCoreRed900,
        1000: tokens.TokenColorCoreRed1000,
    },
    orange: {
        50: tokens.TokenColorCoreOrange050,
        100: tokens.TokenColorCoreOrange100,
        200: tokens.TokenColorCoreOrange200,
        300: tokens.TokenColorCoreOrange300,
        400: tokens.TokenColorCoreOrange400,
        500: tokens.TokenColorCoreOrange500,
        600: tokens.TokenColorCoreOrange600,
        700: tokens.TokenColorCoreOrange700,
        800: tokens.TokenColorCoreOrange800,
        900: tokens.TokenColorCoreOrange900,
        1000: tokens.TokenColorCoreOrange1000,
    },
    amber: {
        100: tokens.TokenColorCoreAmber100,
        200: tokens.TokenColorCoreAmber200,
        300: tokens.TokenColorCoreAmber300,
        400: tokens.TokenColorCoreAmber400,
        500: tokens.TokenColorCoreAmber500,
        600: tokens.TokenColorCoreAmber600,
        700: tokens.TokenColorCoreAmber700,
        800: tokens.TokenColorCoreAmber800,
        900: tokens.TokenColorCoreAmber900,
        1000: tokens.TokenColorCoreAmber1000,
    },
    yellow: {
        100: tokens.TokenColorCoreYellow100,
        200: tokens.TokenColorCoreYellow200,
        300: tokens.TokenColorCoreYellow300,
        400: tokens.TokenColorCoreYellow400,
        500: tokens.TokenColorCoreYellow500,
        600: tokens.TokenColorCoreYellow600,
        700: tokens.TokenColorCoreYellow700,
        800: tokens.TokenColorCoreYellow800,
        900: tokens.TokenColorCoreYellow900,
        1000: tokens.TokenColorCoreYellow1000,
    },
    green: {
        50: tokens.TokenColorCoreGreen050,
        100: tokens.TokenColorCoreGreen100,
        200: tokens.TokenColorCoreGreen200,
        300: tokens.TokenColorCoreGreen300,
        400: tokens.TokenColorCoreGreen400,
        500: tokens.TokenColorCoreGreen500,
        600: tokens.TokenColorCoreGreen600,
        700: tokens.TokenColorCoreGreen700,
        800: tokens.TokenColorCoreGreen800,
        900: tokens.TokenColorCoreGreen900,
        1000: tokens.TokenColorCoreGreen1000,
    },
    teal: {
        100: tokens.TokenColorCoreTeal100,
        200: tokens.TokenColorCoreTeal200,
        300: tokens.TokenColorCoreTeal300,
        400: tokens.TokenColorCoreTeal400,
        500: tokens.TokenColorCoreTeal500,
        600: tokens.TokenColorCoreTeal600,
        700: tokens.TokenColorCoreTeal700,
        800: tokens.TokenColorCoreTeal800,
        900: tokens.TokenColorCoreTeal900,
        1000: tokens.TokenColorCoreTeal1000,
    },
    blue: {
        50: tokens.TokenColorCoreBlue050,
        100: tokens.TokenColorCoreBlue100,
        200: tokens.TokenColorCoreBlue200,
        300: tokens.TokenColorCoreBlue300,
        400: tokens.TokenColorCoreBlue400,
        500: tokens.TokenColorCoreBlue500,
        600: tokens.TokenColorCoreBlue600,
        700: tokens.TokenColorCoreBlue700,
        800: tokens.TokenColorCoreBlue800,
        900: tokens.TokenColorCoreBlue900,
        1000: tokens.TokenColorCoreBlue1000,
        1100: tokens.TokenColorCoreBlue1100,
    },
    purple: {
        50: tokens.TokenColorCorePurple050,
        100: tokens.TokenColorCorePurple100,
        200: tokens.TokenColorCorePurple200,
        300: tokens.TokenColorCorePurple300,
        400: tokens.TokenColorCorePurple400,
        500: tokens.TokenColorCorePurple500,
        600: tokens.TokenColorCorePurple600,
        700: tokens.TokenColorCorePurple700,
        800: tokens.TokenColorCorePurple800,
        900: tokens.TokenColorCorePurple900,
        1000: tokens.TokenColorCorePurple1000,
    },
    magenta: {
        50: tokens.TokenColorCoreMagenta050,
        100: tokens.TokenColorCoreMagenta100,
        200: tokens.TokenColorCoreMagenta200,
        300: tokens.TokenColorCoreMagenta300,
        400: tokens.TokenColorCoreMagenta400,
        500: tokens.TokenColorCoreMagenta500,
        600: tokens.TokenColorCoreMagenta600,
        700: tokens.TokenColorCoreMagenta700,
        800: tokens.TokenColorCoreMagenta800,
        900: tokens.TokenColorCoreMagenta900,
        1000: tokens.TokenColorCoreMagenta1000,
    },
    grey: {
        50: tokens.TokenColorCoreGrey050,
        100: tokens.TokenColorCoreGrey100,
        125: tokens.TokenColorCoreGrey125,
        150: tokens.TokenColorCoreGrey150,
        200: tokens.TokenColorCoreGrey200,
        250: tokens.TokenColorCoreGrey250,
        300: tokens.TokenColorCoreGrey300,
        350: tokens.TokenColorCoreGrey350,
        400: tokens.TokenColorCoreGrey400,
        450: tokens.TokenColorCoreGrey450,
        500: tokens.TokenColorCoreGrey500,
        550: tokens.TokenColorCoreGrey550,
        600: tokens.TokenColorCoreGrey600,
        650: tokens.TokenColorCoreGrey650,
        700: tokens.TokenColorCoreGrey700,
        750: tokens.TokenColorCoreGrey750,
        800: tokens.TokenColorCoreGrey800,
        850: tokens.TokenColorCoreGrey850,
        900: tokens.TokenColorCoreGrey900,
    },
};

const lightTheme: ThemeConfig<Theme> = {
    name: 'light',
    animations: { durationMS: 300, timingFunction: 'ease-in-out' },
    breakpoints: { xsm: '37.5em', sm: '61.25em', md: '67.5em', lg: '90em' },
    depths: { base: 1, header: 10, navDropdown: 100, tooltip: 1050, overlay: 1100, dialog: 1200 },
    fontFamilies: {
        default: tokens.TokenFontBaseFamily,
        monospace: tokens.TokenFontCodeFamily,
    },
    fontSizes: {
        xsmall: tokens.TokenSizeCoreScale10,
        small: tokens.TokenSizeCoreScale12,
        base: tokens.TokenSizeCoreScale14,
        medium: tokens.TokenSizeCoreScale16,
        large: tokens.TokenSizeCoreScale18,
        xlarge: tokens.TokenSizeCoreScale24,
        xxlarge: tokens.TokenSizeCoreScale28,
        xxxlarge: tokens.TokenSizeCoreScale38,
    },
    lineHeight: {
        xsmall: tokens.TokenSizeCoreScale14,
        small: tokens.TokenSizeCoreScale16,
        base: tokens.TokenSizeCoreScale20,
        medium: tokens.TokenSizeCoreScale14,
        large: tokens.TokenSizeCoreScale28,
        xlarge: tokens.TokenSizeCoreScale32,
        xxlarge: tokens.TokenSizeCoreScale36,
        xxxlarge: tokens.TokenSizeCoreScale48,
    },
    fontWeights: {
        thin: tokens.TokenFontCoreWeightThin,
        light: tokens.TokenFontCoreWeightLight,
        normal: tokens.TokenFontCoreWeightRegular,
        medium: tokens.TokenFontCoreWeightMedium,
        demi: tokens.TokenFontCoreWeightSemibold,
        bold: tokens.TokenFontCoreWeightBold,
        heavy: tokens.TokenFontCoreWeightHeavy,
    },
    colors: {
        ...colorPalette,
        text: tokens.TokenColorTextPrimary,
        disabledText: tokens.TokenColorTextInsensitive,
        link: ({ colors }) => colors.grey[750],
        linkVisited: ({ colors }) => colors.grey[500],
        linkHoverBackground: ({ colors }) => colors.grey[200],
        focus: ({ colors }) => colors.blue[500],
        background: tokens.TokenColorBackgroundPrimary,
        primary: {
            text: ({ colors }) => colors.white,
            hover: ({ colors }) => colors.blue[400],
            action: ({ colors }) => colors.blue[600],
            active: ({ colors }) => colors.blue[700],
            disabledBackground: ({ colors }) => colors.grey[100],
            disabledText: ({ colors }) => colors.grey[500],
        },
        error: {
            background: ({ colors }) => colors.red[50],
            graphic: ({ colors }) => colors.red[600],
            text: ({ colors }) => colors.red[600],
            textContrast: ({ colors }) => colors.white,
            hover: ({ colors }) => colors.red[400],
        },
        success: {
            background: ({ colors }) => colors.green[100],
            graphic: ({ colors }) => colors.green[600],
            text: ({ colors }) => colors.green[600],
            textContrast: ({ colors }) => colors.black,
            hover: ({ colors }) => colors.green[400],
        },
        warning: {
            background: ({ colors }) => colors.orange[100],
            graphic: ({ colors }) => colors.orange[500],
            text: ({ colors }) => colors.orange[600],
            textContrast: ({ colors }) => colors.black,
            hover: ({ colors }) => colors.orange[400],
        },
        info: {
            background: ({ colors }) => colors.blue[100],
            graphic: ({ colors }) => colors.blue[400],
            text: ({ colors }) => colors.blue[600],
            textContrast: ({ colors }) => colors.black,
            hover: ({ colors }) => colors.blue[400],
        },
        progress: {
            background: ({ colors }) => colors.purple[50],
            graphic: ({ colors }) => colors.purple[500],
            text: ({ colors }) => colors.purple[500],
            textContrast: ({ colors }) => colors.black,
            hover: ({ colors }) => colors.purple[400],
        },
        default: {
            background: ({ colors }) => colors.grey[300],
            graphic: ({ colors }) => colors.grey[300],
            text: ({ colors }) => colors.grey[800],
            textContrast: ({ colors }) => colors.black,
            hover: ({ colors }) => colors.grey[400],
        },
        backgroundShades: {
            softest: ({ colors }) => colors.grey[50],
            soft: ({ colors }) => colors.grey[100],
            medium: ({ colors }) => colors.grey[100],
            strong: ({ colors }) => colors.grey[500],
        },
    },
    shadows: {
        default: '0 2px 4px 0 rgba(0,0,0,0.35)',
    },
    Button: {
        colors: {
            primary: {
                text: tokens.TokenColorTextInverse,
                border: tokens.TokenColorBackgroundAccentIdle,
                background: tokens.TokenColorBackgroundAccentIdle,
                hoverText: tokens.TokenColorTextInverse,
                hoverBorder: tokens.TokenColorBackgroundAccentHover,
                hoverBackground: tokens.TokenColorBackgroundAccentHover,
                activeText: tokens.TokenColorTextInverse,
                activeBorder: tokens.TokenColorBackgroundAccentPressed,
                activeBackground: tokens.TokenColorBackgroundAccentPressed,
                disabledText: tokens.TokenColorTextInsensitive,
                disabledBorder: tokens.TokenColorBackgroundInsensitive,
                disabledBackground: tokens.TokenColorBackgroundInsensitive,
            },
            secondary: {
                text: tokens.TokenColorTextPrimary,
                border: tokens.TokenColorBorder01,
                background: 'transparent',
                hoverText: tokens.TokenColorTextPrimary,
                hoverBorder: tokens.TokenColorBorder01,
                hoverBackground: tokens.TokenColorBackgroundHover,
                activeText: tokens.TokenColorTextPrimary,
                activeBorder: tokens.TokenColorBorder01,
                activeBackground: tokens.TokenColorBackgroundPressed,
                disabledText: tokens.TokenColorTextInsensitive,
                disabledBorder: tokens.TokenColorBorderInsensitive,
                disabledBackground: 'transparent',
            },
            tertiary: {
                text: tokens.TokenColorTextPrimary,
                border: 'transparent',
                background: 'transparent',
                hoverText: tokens.TokenColorTextPrimary,
                hoverBorder: tokens.TokenColorBackgroundHover,
                hoverBackground: tokens.TokenColorBackgroundHover,
                activeText: tokens.TokenColorTextPrimary,
                activeBorder: tokens.TokenColorBackgroundPressed,
                activeBackground: tokens.TokenColorBackgroundPressed,
                disabledText: tokens.TokenColorTextInsensitive,
                disabledBorder: 'transparent',
                disabledBackground: 'transparent',
            },
            destruction: {
                text: tokens.TokenColorTextInverse,
                border: tokens.TokenColorBackgroundDestructionIdle,
                background: tokens.TokenColorBackgroundDestructionIdle,
                hoverText: tokens.TokenColorTextInverse,
                hoverBorder: tokens.TokenColorBackgroundDestructionHover,
                hoverBackground: tokens.TokenColorBackgroundDestructionHover,
                activeText: tokens.TokenColorTextInverse,
                activeBorder: tokens.TokenColorBackgroundDestructionPressed,
                activeBackground: tokens.TokenColorBackgroundDestructionPressed,
                disabledText: tokens.TokenColorTextInsensitive,
                disabledBorder: tokens.TokenColorBackgroundInsensitive,
                disabledBackground: tokens.TokenColorBackgroundInsensitive,
            },
        },
    },
    dropdowns: {
        colors: {
            background: ({ colors }) => colors.white,
            text: ({ colors }) => colors.grey[750],
            selected: ({ colors }) => colors.black,
            hover: ({ colors }) => colors.grey[200],
            active: ({ colors }) => colors.grey[350],
            border: ({ colors }) => colors.black,
            groupHeader: ({ colors }) => colors.grey[500],
            groupDivider: ({ colors }) => colors.grey[300],
        },
    },
    feedback: {
        colors: {
            info: {
                graphic: ({ colors }) => colors.blue[200],
                hover: ({ colors }) => colors.blue[300],
                textContrast: ({ colors }) => colors.black,
            },
            success: {
                graphic: ({ colors }) => colors.green[300],
                hover: ({ colors }) => colors.green[400],
                textContrast: ({ colors }) => colors.black,
            },
            warning: {
                graphic: ({ colors }) => colors.yellow[300],
                hover: ({ colors }) => colors.yellow[600],
                textContrast: ({ colors }) => colors.black,
            },
            error: {
                graphic: ({ colors }) => colors.red[500],
                hover: ({ colors }) => colors.red[300],
                textContrast: ({ colors }) => colors.white,
            },
        },
    },
    tables: {
        colors: {
            background: ({ colors }) => colors.white,
            backgroundHover: ({ colors }) => colors.grey[150],
            border: ({ colors }) => colors.grey[200],
            focus: ({ colors }) => colors.blue[500],
            headerBackground: ({ colors }) => colors.white,
            headerBackgroundHover: ({ colors }) => colors.grey[150],
            highlight: ({ colors }) => colors.grey[150],
            resizeHandle: ({ colors }) => colors.grey[750],
            resizingCellBorder: ({ colors }) => colors.grey[300],
            selectedStrip: ({ colors }) => colors.blue[400],
            sortIndicatorDisabled: ({ colors }) => colors.grey[300],
            sortIndicatorGhost: ({ colors }) => colors.grey[400],
            text: ({ colors }) => colors.black,
        },
    },
    formElements: {
        colors: {
            action: ({ colors }) => colors.blue[600],
            selected: ({ colors }) => colors.blue[400],
            hover: ({ colors }) => colors.blue[200],
            background: ({ colors }) => colors.white,
            disabledBackground: ({ colors }) => colors.grey[100],
            border: ({ colors }) => colors.grey[400],
            focus: ({ colors }) => colors.blue[400],
            readOnly: ({ colors }) => colors.grey[400],
            placeholder: ({ colors }) => colors.grey[500],
            addon: ({ colors }) => colors.grey[200],
            highlight: ({ colors }) => colors.blue[100],
        },
        options: {
            colors: {
                background: ({ colors }) => colors.grey[50],
                text: ({ colors }) => colors.grey[600],
                disabledText: ({ colors }) => colors.grey[400],
                highlight: ({ colors }) => colors.grey[150],
                selectedBackground: ({ colors }) => colors.blue[600],
                selectedHighlightBackground: ({ colors }) => colors.blue[400],
                selectedText: ({ colors }) => colors.black,
                indicator: ({ colors }) => colors.grey[850],
            },
        },
    },
    header: {
        colors: {
            background: ({ colors }) => colors.black,
            text: ({ colors }) => colors.white,
            titleBar: {
                border: ({ colors }) => colors.grey[200],
                hover: ({ colors }) => colors.grey[650],
            },
            navBar: {
                border: ({ colors }) => colors.grey[500],
            },
            menu: {
                active: ({ colors }) => colors.blue[600],
                background: ({ colors }) => colors.white,
                hover: ({ colors }) => colors.grey[100],
            },
            link: {
                default: ({ colors }) => colors.grey[750],
                active: ({ colors }) => colors.blue[400],
                border: ({ colors }) => colors.blue[600],
                hover: 'rgba(0, 0, 0, 0.12)',
                selected: 'rgba(0, 115, 255, 0.08)',
                hoverSelected: 'rgba(0, 115, 255, 0.12)',
            },
            iconLink: {
                background: ({ colors }) => colors.grey[700],
                border: ({ colors }) => colors.grey[550],
                hover: ({ colors }) => colors.grey[650],
                text: ({ colors }) => colors.white,
                readonly: ({ colors }) => colors.grey[350],
                selected: ({ colors }) => colors.white,
            },
            menuItem: {
                border: ({ colors }) => colors.blue[600],
                selected: 'rgba(0, 115, 255, 0.14)',
            },
        },
    },
    popovers: {
        colors: {
            text: ({ colors }) => colors.grey[900],
            hover: ({ colors }) => colors.grey[100],
            border: ({ colors }) => colors.grey[150],
            background: ({ colors }) => colors.white,
            divider: ({ colors }) => colors.grey[300],
        },
    },
    tabs: {
        colors: {
            background: 'rgba(0,0,0,0.06)',
            hover: 'rgba(0,0,0,0.12)',
            pressed: 'rgba(0,0,0,0.35)',
            border: ({ colors }) => colors.grey[500],
            disabled: ({ tabs }) => tabs.colors.background,
            disabledText: ({ colors }) => colors.grey[350],
            active: ({ colors }) => colors.background,
            activeBorder: ({ colors }) => colors.blue[600],
            success: ({ colors }) => colors.green[600],
            warning: ({ colors }) => colors.orange[300],
            error: ({ colors }) => colors.red[300],
            hoverBorder: ({ colors }) => colors.grey[400],
            supplementaryValues: ({ colors }) => colors.grey[500],
        },
    },
    Banner: {
        colors: {
            info: {
                graphic: ({ colors }) => colors.grey[200],
                textContrast: ({ colors }) => colors.black,
                hover: ({ colors }) => colors.grey[400],
            },
            warning: {
                graphic: ({ colors }) => colors.yellow[300],
                textContrast: ({ colors }) => colors.black,
                hover: ({ colors }) => colors.yellow[600],
            },
            error: {
                graphic: ({ colors }) => colors.red[600],
                textContrast: ({ colors }) => colors.white,
                hover: ({ colors }) => colors.red[300],
            },
            environment: {
                graphic: ({ colors }) => colors.teal[900],
                textContrast: ({ colors }) => colors.white,
                hover: ({ colors }) => colors.teal[700],
            },
        },
    },
    ContextualMessage: {
        colors: {
            info: {
                graphic: ({ colors }) => colors.grey[100],
                textContrast: ({ colors }) => colors.black,
                hover: ({ colors }) => colors.grey[150],
            },
            warning: {
                graphic: ({ colors }) => colors.yellow[300],
                textContrast: ({ colors }) => colors.black,
                hover: ({ colors }) => colors.yellow[600],
            },
        },
    },
    Card: {
        colors: {
            hoverBackground: ({ colors }) => colors.white,
        },
    },
    DatePicker: {
        colors: {
            text: ({ colors }) => colors.black,
            background: ({ colors }) => colors.white,
            hoverBackground: ({ colors }) => colors.grey[200],
            pressedBackground: ({ colors }) => colors.grey[350],
            selectedColor: ({ colors }) => colors.blue[400],
            selectedBorder: ({ colors }) => colors.black,
            disabledText: ({ colors }) => colors.grey[250],
            persistentBorder: ({ colors }) => colors.grey[300],
        },
    },
    Dropzone: {
        colors: {
            activeBorder: ({ colors }) => `2px dotted ${colors.blue[400]}`,
            border: ({ colors }) => `2px solid ${colors.grey[400]}`,
            errorBorder: ({ colors }) => `2px solid ${colors.red[500]}`,
            hoverBackground: ({ colors }) => colors.blue[100],
            hoverText: ({ colors }) => colors.blue[600],
            text: ({ colors }) => colors.black,
        },
    },
    GlobalStyles: {
        colors: {
            scrollbarThumb: 'rgba(0,0,0,0.4)',
            scrollbarTrack: 'transparent',
        },
    },
    NavList: {
        colors: {
            activeBackground: ({ colors }) => hex2rgba(colors.blue[500], 0.08),
            activeBorder: ({ colors }) => colors.blue[600],
            background: ({ colors }) => colors.background,
            hover: ({ colors }) => hex2rgba(colors.black, 0.12),
            hoverSelected: ({ colors }) => hex2rgba(colors.blue[500], 0.15),
            secondaryBackground: ({ colors }) => hex2rgba(colors.black, 0.06),
            secondaryBorder: ({ colors }) => colors.grey[400],
            secondaryHoverSelected: ({ colors }) => hex2rgba(colors.black, 0.12),
        },
    },
    Overlay: {
        colors: {
            background: ({ colors }) => hex2rgba(colors.white, 0.74),
        },
    },
    Pagination: {
        colors: {
            focusIcon: ({ colors }) => colors.blue[300],
        },
    },
    ProgressMeter: {
        colors: {
            emptyTrack: ({ colors }) => colors.grey[100],
            activeTrack: ({ colors }) => colors.amber[500],
        },
    },
    Slider: {
        colors: {
            emptyTrack: ({ colors }) => colors.grey[200],
            activeTrack: ({ colors }) => colors.primary.action,
            handleBackground: ({ colors }) => colors.white,
            handleBorder: ({ colors }) => colors.primary.hover,
            disabledHandleBorder: ({ colors }) => colors.primary.disabledText,
        },
    },
    SplitButton: {
        colors: {
            primary: {
                divider: ({ colors }) => colors.background,
                disabledDivider: ({ colors }) => colors.background,
            },
            secondary: {
                divider: ({ Button }) => Button.colors.secondary.border,
                disabledDivider: ({ Button }) => Button.colors.secondary.disabledBorder,
            },
            tertiary: {
                divider: ({ Button }) => Button.colors.secondary.border,
                disabledDivider: ({ Button }) => Button.colors.secondary.disabledBorder,
            },
        },
    },
    Stepper: {
        borders: {
            normal: '1px solid',
            active: '4px solid',
        },
        colors: {
            normal: ({ colors }) => colors.grey[750],
            border: ({ colors }) => colors.grey[500],
            disabled: ({ colors }) => colors.grey[350],
            hover: ({ colors }) => hex2rgba(colors.black, 0.12),
            active: ({ colors }) => colors.blue[600],
            activeBackground: ({ colors }) => hex2rgba(colors.blue[500], 0.08),
            complete: ({ colors }) => colors.info.graphic,
            pressed: ({ colors }) => colors.grey[350],
        },
        font: {
            normal: ({ fontSizes }) => fontSizes.medium,
            marker: ({ fontSizes }) => fontSizes.medium,
            markerIcon: ({ fontSizes }) => fontSizes.medium,
            error: ({ colors }) => colors.red[300],
            success: ({ colors }) => colors.green[600],
        },
    },
    Switch: {
        colors: {
            inactiveBorder: ({ colors }) => colors.grey[500],
            activeBorder: ({ colors }) => colors.grey[500],
            disabledBorder: ({ colors }) => colors.grey[350],
            track: ({ colors }) => colors.white,
            active: ({ colors }) => colors.blue[600],
            inactive: ({ colors }) => colors.grey[750],
            hover: ({ colors }) => colors.grey[150],
            disabled: ({ colors }) => colors.grey[350],
        },
    },
    Tile: {
        colors: {
            border: ({ colors }) => colors.grey[200],
            background: ({ colors }) => colors.white,
            divider: ({ colors }) => colors.black,
        },
    },
    TimePicker: {
        colors: {
            background: ({ colors }) => colors.white,
            selectedBackground: ({ colors }) => colors.grey[150],
            hoverBackground: ({ colors }) => colors.grey[150],
        },
    },
    ToggleButtonBar: {
        colors: {
            background: ({ colors }) => colors.background,
            border: ({ colors }) => colors.grey[750],
            text: ({ colors }) => colors.grey[750],
            hoverBackground: ({ colors }) => colors.grey[150],
            hoverBorder: ({ colors }) => colors.grey[750],
            hoverText: ({ colors }) => colors.grey[750],
            activeBackground: ({ colors }) => colors.grey[350],
            activeBorder: ({ colors }) => colors.grey[750],
            activeText: ({ colors }) => colors.grey[750],
            selectedBackground: ({ colors }) => colors.grey[750],
            selectedBorder: ({ colors }) => colors.grey[750],
            selectedText: ({ colors }) => colors.white,
        },
    },
    Token: {
        borders: {
            iconFocus: '2px solid',
        },
        colors: {
            background: ({ colors }) => colors.grey[200],
            progressBackground: ({ colors }) => colors.grey[100],
            activeBackground: ({ colors }) => colors.grey[350],
            iconFocusBorder: ({ colors }) => colors.blue[400],
        },
    },
};

export default lightTheme;
