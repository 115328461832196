import React from 'react';
import cn from 'clsx';

import { StyledInputGroupAddonContainer } from './InputGroup.styles';

import { FCC } from '../__utils__/types';

namespace InputGroupAddon {
    export type Props = InputGroupAddonProps;
}

export interface InputGroupAddonProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Classes will be applied to the root tag.
     */
    className?: string;

    /** @ignore */
    disabled?: boolean;
}

const InputGroupAddon: FCC<InputGroupAddonProps> = ({ children, className, disabled, ...rest }) => {
    return (
        <StyledInputGroupAddonContainer className={cn('bbui-input-group-addon', className)} {...rest}>
            {typeof children === 'string' ? (
                <span className="text">{children}</span>
            ) : (
                React.Children.map(children, (child) =>
                    React.isValidElement(child) ? React.cloneElement(child, { disabled, ...child.props }) : child
                )
            )}
        </StyledInputGroupAddonContainer>
    );
};

export default InputGroupAddon;
