interface Key {
    code: string;
    key: string;
    charCode: number;
    keyCode: number;
    which: number;
    metaKey?: boolean;
    ctrlKey?: boolean;
    shiftKey?: boolean;
}

const makeKey = (text: string, num: number, overrides?: Partial<Key>): Key => ({
    code: text,
    key: text,
    charCode: num,
    keyCode: num,
    which: num,
    ...overrides,
});

export default {
    arrowDown: makeKey('ArrowDown', 40),
    arrowLeft: makeKey('ArrowLeft', 37),
    arrowRight: makeKey('ArrowRight', 39),
    arrowUp: makeKey('ArrowUp', 38),
    cmd: makeKey('Meta', 91, { code: 'MetaLeft', charCode: 0, metaKey: true }),
    ctrl: makeKey('Control', 17, { code: 'ControlLeft', charCode: 0, ctrlKey: true }),
    shift: makeKey('Shift', 16, { code: 'ShiftLeft', charCode: 0, shiftKey: true }),
    end: makeKey('End', 35),
    enter: makeKey('Enter', 13),
    home: makeKey('Home', 36),
    space: makeKey('Space', 32, { key: ' ' }),
    tab: makeKey('Tab', 9),
    escape: makeKey('Escape', 27),
};
