import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'clsx';
import { useSelect, UseSelectState, UseSelectStateChange, UseSelectStateChangeOptions } from 'downshift';
import { usePopper } from 'react-popper';
import Button, { isTriggerKey } from '../Button';
import Icon from '../Icon';
import useForceUpdate from '../__utils__/useForceUpdate';
import { MenuProps, MenuItem } from './Menu.types';
import { MenuContainer } from './Menu.styles';
import { useElementWidth } from '../__utils__/useElementWidth';

namespace Menu {
    export type Props = MenuProps;
}

/**
 * A hybrid of a button and a menu which contains additional actions.
 */
function Menu({
    className,
    menuItems,
    hideIcon = false,
    triggerText = undefined,
    disabled = false,
    icon = 'menu',
    popperOptions = {},
}: MenuProps) {
    const [isOpen, setIsOpen] = useState(false);
    const referenceRef = useRef<HTMLDivElement>(null);
    const popperRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLUListElement>(null);
    const isClosing = useRef(false);
    const forceUpdate = useForceUpdate();
    const popperWidth = useElementWidth(referenceRef.current);
    const kind = 'tertiary';

    const handleDocumentClick = useCallback(
        (e: Event) => {
            if (popperRef.current) {
                if (!popperRef.current.contains(e.target as Node)) {
                    setIsOpen(false);
                } else {
                    const interactive = (e.target as HTMLElement).dataset.interactive;
                    if (interactive !== null && interactive === 'false') {
                        setIsOpen(false);
                    }
                }
            }
        },
        [popperRef]
    );

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick, { capture: true });
        document.addEventListener('keyup', handleDocumentClick, { capture: true });
        return () => {
            document.removeEventListener('click', handleDocumentClick);
            document.removeEventListener('keyup', handleDocumentClick);
        };
    }, [handleDocumentClick]);

    let itemIndex = 0;
    const menuItemsWithIndex: (MenuItem & { index?: number; id?: string })[] = menuItems.map((item) => ({
        ...item,
        id: item.id || `bbui-menu-menuitem-${itemIndex}`,
        index: itemIndex++,
    }));

    const { styles, attributes } = usePopper(referenceRef.current, popperRef.current, {
        placement: 'bottom-start',
        ...popperOptions,
    });

    function handleSelect(changes: UseSelectStateChange<string>) {
        const selectedMenuItem = menuItemsWithIndex.find((item) => item.label === changes.selectedItem);
        selectedMenuItem?.onSelect?.();
    }

    function stateReducer(state: UseSelectState<string>, actionAndChanges: UseSelectStateChangeOptions<string>) {
        const updatedState = actionAndChanges.changes;
        switch (actionAndChanges.type) {
            case useSelect.stateChangeTypes.MenuKeyDownArrowDown: {
                // if next item is readonly, skip highlight index by one;
                const tempState = { ...updatedState, highlightedIndex: updatedState.highlightedIndex || 0 };
                if (state.highlightedIndex === itemIndex - 1) {
                    tempState.highlightedIndex = 0;
                }

                while (menuItemsWithIndex[tempState.highlightedIndex].readonly) {
                    tempState.highlightedIndex++;
                }

                return tempState;
            }
            case useSelect.stateChangeTypes.MenuKeyDownArrowUp: {
                // if next item is readonly, skip highlight index by one;
                const tempState = {
                    ...updatedState,
                    highlightedIndex:
                        updatedState.highlightedIndex !== undefined ? updatedState.highlightedIndex : itemIndex - 1,
                };
                if (state.highlightedIndex === 0) {
                    tempState.highlightedIndex = itemIndex - 1;
                }

                while (menuItemsWithIndex[tempState.highlightedIndex].readonly) {
                    tempState.highlightedIndex =
                        tempState.highlightedIndex - 1 < 0 ? itemIndex - 1 : tempState.highlightedIndex - 1;
                }

                return tempState;
            }
            default: {
                return updatedState;
            }
        }
    }

    const { getItemProps, getMenuProps, getToggleButtonProps, highlightedIndex } = useSelect({
        items: menuItemsWithIndex.map((item) => item.label),
        onSelectedItemChange: handleSelect,
        // We don't want Downshift to keep track of the selectedItem, because it would prevent the item's onSelect
        // handler from being triggered if that item was selected multiple times in a row
        selectedItem: null,
        stateReducer,
    });

    // We want to force a rerender whenever the `isOpen` state changes, because changes to `isOpen` are accompanied
    // by a change in the focused element, and need to re-render the component when the focus changes in order to
    // ensure updated styles are applied.
    useEffect(() => {
        forceUpdate();
    }, [isOpen, forceUpdate]);

    return (
        <MenuContainer className={cn('bbui-menu', className, kind)} $kind={kind} $triggerText={Boolean(triggerText)}>
            <div ref={referenceRef} className="bbui-menu-reference">
                <Button
                    className={cn('bbui-menu-togglebutton', {
                        disabled,
                        // Checking that the menu is focused here in addition to using `isOpen` because when the menu
                        // is closed, `isOpen` changes before the focused element does, resulting in a moment where the
                        // toggle button's outline is removed is removed because `isOpen` is false but the toggle button
                        // is not yet the focused element. However, if we only check the focus state, a similar flash
                        // occurs when clicking the button to open the menu, due to the menu being focused slightly
                        // after the mouse is released.
                        open: isOpen || document.activeElement === menuRef.current,
                    })}
                    disabled={disabled}
                    kind={kind}
                    tabIndex={document.activeElement === menuRef.current ? -1 : 0}
                    title="toggle menu"
                    {...getToggleButtonProps({
                        onKeyUp: (e) => {
                            if (isClosing.current) {
                                e.preventDefault(); // Prevent Button from calling onClick when keyUp is fired right after closing the menu by selecting an item
                                isClosing.current = false;
                            }
                        },
                        onKeyDown: (e) => {
                            if (!isOpen && e.key === 'ArrowDown') {
                                setIsOpen(true);
                            } else if (!isOpen && e.key === 'ArrowUp') {
                                setIsOpen(true);
                            }
                        },
                        onClick: () => {
                            setIsOpen(!isOpen);
                        },
                    })}
                >
                    {!hideIcon && <Icon name={icon} />}
                    {triggerText}
                    <Icon className="bbui-menu-togglebutton-caret-down" name="caret-down" />
                </Button>
            </div>
            <div
                className="bbui-menu-popper"
                ref={popperRef}
                style={{ ...styles.popper, minWidth: popperWidth }}
                {...attributes.popper}
            >
                <ul
                    className={cn('bbui-menu-submenu', { open: isOpen })}
                    {...getMenuProps({
                        ref: menuRef,
                        onKeyDown: (e) => {
                            const highlightedItem = menuItemsWithIndex.find((item) => item.index === highlightedIndex);
                            if (isTriggerKey(e.key) && !highlightedItem?.interactive) {
                                isClosing.current = true; // onKeyDown causes downshift to close the menu
                            }
                        },
                    })}
                >
                    {menuItemsWithIndex.map(
                        ({
                            className,
                            disabled,
                            label,
                            readonly,
                            interactive = false,
                            divider,
                            renderer: Renderer = React.Fragment,
                            index,
                            ...props
                        }) => {
                            if (readonly) {
                                return (
                                    <span
                                        key={label}
                                        className={cn('bbui-menu-menuitem', className, {
                                            readonly,
                                            divider,
                                        })}
                                        aria-readonly={readonly}
                                    >
                                        {!divider && label}
                                    </span>
                                );
                            }

                            return (
                                <li
                                    key={label}
                                    className={cn('bbui-menu-menuitem', className, {
                                        disabled,
                                        highlighted: highlightedIndex === index,
                                        readonly,
                                        interactive,
                                    })}
                                    data-interactive={interactive ? 'true' : 'false'}
                                    {...getItemProps({ item: label, disabled, index, ...props })}
                                >
                                    <Renderer>{label}</Renderer>
                                </li>
                            );
                        }
                    )}
                </ul>
            </div>
        </MenuContainer>
    );
}

Menu.MenuDivider = { label: 'divider', readonly: true, divider: true } as const;

export default Menu;
