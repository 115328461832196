import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Size } from './ProgressSpinner.types';

const ANIMATION_INTERVAL = 1300; // milliseconds
const ARC_SIZE_MAX = 300; // degrees
const ARC_SIZE_MIN = 20; // degrees

const SIZING = {
    small: 22,
    medium: 40,
    large: 60,
    xlarge: 138,
} as const;

const calcRadius = (size: Size) => 9 * (SIZING[size] / 20.0);
const calcArcLength = (size: Size, angle: number) => (Math.PI * calcRadius(size) * (360 - angle)) / 180.0;

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const oscillate = keyframes`
    from {
        transform: rotate(200deg);
    }
    to {
        transform: rotate(10deg);
    }
`;

const makeInitialArcGrowthIntro = (size: Size) => keyframes`
    from {
        stroke-dashoffset: ${calcArcLength(size, 0)};
    }
    to {
        stroke-dashoffset: ${calcArcLength(size, ARC_SIZE_MAX)};
    }
`;

const makeGrowAndShrinkKeyframes = (size: Size) => keyframes`
    from {
        stroke-dashoffset: ${calcArcLength(size, ARC_SIZE_MIN)};
    }
    to {
        stroke-dashoffset: ${calcArcLength(size, ARC_SIZE_MAX)};
    }
`;

export const getCircle = (size: Size) => {
    const viewBoxSize = SIZING[size];
    return (
        <svg height={viewBoxSize} width={viewBoxSize} viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}>
            <circle
                className="arc"
                cx={viewBoxSize / 2}
                cy={viewBoxSize / 2}
                r={calcRadius(size)}
                fill="none"
                stroke="currentColor"
                strokeWidth={Math.min(viewBoxSize / 10, 7)}
            />
        </svg>
    );
};

export const StyledProgressSpinner = styled.div<{ size: Size }>`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    .bbui-progress-spinner-label {
        font-size: ${({ theme, size }) => theme.fontSizes[size]};
    }
    &[data-label-position='bottom'] .bbui-progress-spinner-label {
        margin-top: 0.75em;
    }
    &[data-label-position='right'] {
        flex-direction: row;
        .bbui-progress-spinner-label {
            margin-left: 0.75em;
        }
    }
    .bbui-progress-spinner-b {
        color: ${({ theme }) => theme.colors.amber[500]};
        position: absolute;
        top: 33px;
        margin: 0 auto;
    }

    .spinner {
        display: block;
        position: relative;
        overflow: hidden;

        width: ${({ size }) => SIZING[size]}px;
        height: ${({ size }) => SIZING[size]}px;

        .rotation {
            position: absolute;
            height: 100%;
            width: 100%;

            animation: ${spin} ${ANIMATION_INTERVAL}ms infinite linear;

            .oscillation {
                position: absolute;
                width: 100%;
                height: 100%;

                animation: ${oscillate} ${ANIMATION_INTERVAL}ms infinite ease-in-out;
                animation-direction: alternate;

                .arc {
                    stroke-dashoffset: ${({ size }) => calcArcLength(size, 10)};
                    stroke-dasharray: ${({ size }) => 2 * Math.PI * calcRadius(size)};

                    animation-name: ${({ size }) => makeInitialArcGrowthIntro(size)},
                        ${({ size }) => makeGrowAndShrinkKeyframes(size)};
                    animation-duration: ${ANIMATION_INTERVAL}ms, ${ANIMATION_INTERVAL}ms;
                    animation-delay: 0, ${ANIMATION_INTERVAL}ms;
                    animation-timing-function: ease-in-out, ease-in-out;
                    animation-iteration-count: 1, infinite;
                    animation-direction: normal, alternate;
                    color: ${({ theme }) => theme.colors.amber[500]};
                }

                &.ie {
                    animation: none;
                    .arc {
                        stroke-dashoffset: ${({ size }) => calcArcLength(size, 270)};
                    }
                }
            }
        }
    }
`;
