import React from 'react';
import cn from 'clsx';
import Overlay from '../Overlay';
import { SectionTitle } from '../Typography/Typography';
import { ModalContainer } from './Modal.styles';
import { ModalProps } from './Modal.types';
import Button from '../Button';
import { FCC } from '../__utils__/types';

namespace Modal {
    export type Props = ModalProps;
}

/**
 * A Dialog which takes over the whole screen, requiring the user's attention.
 */
const Modal: FCC<ModalProps> = ({
    children,
    className = '',
    isOpen,
    onClose,
    contentLabel,
    title,
    portalContainerId,
    primaryActions,
    secondaryActions,
    overlayProps,
    ...rest
}) => {
    const hasClose = Boolean(onClose);
    const hasHeader = Boolean(title) || hasClose;
    const hasFooter = Boolean(primaryActions) || Boolean(secondaryActions);

    const { modalProps = {} } = overlayProps || {};
    modalProps.contentLabel = contentLabel;

    return (
        <Overlay
            depth={2}
            closeOnEscKey={hasClose}
            closeOnOverlayClick={hasClose}
            onClose={onClose}
            isOpen={isOpen}
            portalContainerId={portalContainerId}
            modalProps={modalProps}
            {...overlayProps}
        >
            <ModalContainer className={cn('bbui-modal', className)} {...rest}>
                {hasHeader && (
                    <div className="bbui-modal-header">
                        <SectionTitle>{title}</SectionTitle>
                        {hasClose && (
                            <Button
                                className="bbui-modal-close-button"
                                icon="close"
                                kind="tertiary"
                                aria-label="Close Dialog"
                                onClick={onClose}
                            />
                        )}
                    </div>
                )}
                <div className="bbui-modal-content">{children}</div>
                {hasFooter && (
                    <div className="bbui-modal-footer">
                        {primaryActions && <div className="bbui-modal-primary">{primaryActions}</div>}
                        {secondaryActions && <div className="bbui-modal-secondary">{secondaryActions}</div>}
                    </div>
                )}
            </ModalContainer>
        </Overlay>
    );
};

export default Modal;
