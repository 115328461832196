import React from 'react';
import cn from 'clsx';
import { PropGetters } from 'downshift';

import Button from '../../Button';

interface Props {
    className?: string;
    disabled?: boolean;
    getToggleButtonProps?: PropGetters<unknown>['getToggleButtonProps'];
    name?: 'chevron-up' | 'chevron-down';
    onClick?: () => void;
}

function ToggleArrow({ className, disabled, getToggleButtonProps = () => {}, name = 'chevron-down', onClick }: Props) {
    return (
        <Button
            title={'toggle'}
            icon={name}
            kind="tertiary"
            {...getToggleButtonProps({
                className: cn(className, 'toggleArrow', { disabled }),
                disabled,
                onClick,
            })}
        />
    );
}

export default ToggleArrow;
