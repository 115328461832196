import styled from 'styled-components';

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px 20px 20px;
    width: 90vw;
    max-width: 800px;
    max-height: 90vh;

    .bbui-modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        padding-bottom: 8px;
        border-bottom: solid 1px ${({ theme }) => theme.colors.backgroundShades.strong};
        > * {
            margin: 0;
        }
    }

    .bbui-modal-close-button {
        font-size: ${({ theme }) => theme.fontSizes.xlarge};
    }

    .bbui-modal-content {
        flex: 1;
        overflow-y: auto;
        /* Magic number for AvenirNextP2ForBBG */
        line-height: 1.31;
        > :first-child {
            margin-top: 0;
        }
        > :last-child {
            margin-bottom: 0;
        }
    }

    .bbui-modal-footer {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        margin-top: 44px;
    }

    .bbui-modal-secondary {
    }

    .bbui-modal-primary {
        > * {
            margin-right: 10px;
        }
    }
`;
