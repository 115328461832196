import React from 'react';
import ReactModal from 'react-modal';
import cn from 'clsx';
import { OverlayProps } from './Overlay.types';
import styled from 'styled-components';
import { OverlayStyles } from './Overlay.styles';
import { FCC } from '../__utils__/types';

namespace Overlay {
    export type Props = OverlayProps;
}

/**
 * An accessible panel taking over the whole viewport
 * @see See Dialog for an applied use of the overlay
 */
const OverlayContainer: FCC<OverlayProps> = ({
    portalContainerId,
    isOpen,
    onClose,
    children,
    className,
    depth = 0,
    side = 'center',
    showShadow = true,
    closeOnOverlayClick = true,
    closeOnEscKey = true,
    modalProps,
}) => {
    // on server just render nothing (as you can't open overlays on server)
    const appElement = typeof document !== 'undefined' && document.getElementById(portalContainerId);
    if (!appElement) {
        return null;
    }

    return (
        <ReactModal
            appElement={appElement}
            isOpen={isOpen}
            onRequestClose={onClose}
            className={cn('bbui-overlay-container', `depth-${depth}`)}
            overlayClassName={cn('bbui-overlay', className, `side-${side}`, { shadow: showShadow })}
            shouldCloseOnOverlayClick={closeOnOverlayClick}
            shouldCloseOnEsc={closeOnEscKey}
            shouldFocusAfterRender={true}
            shouldReturnFocusAfterClose={true}
            {...modalProps}
        >
            {children}
        </ReactModal>
    );
};

// Apply styled components to generate a className that can be passed through
// since the ReactModal component is not itself a DOM element.
const Overlay = styled(OverlayContainer)`
    ${OverlayStyles};
`;

export default Overlay;
