import { FocusEvent, useState } from 'react';

/**
 * Tracks if an element has been touched (after first onBlur),
 * so that "invalid" states aren't applied until *after* the user has first interacted with an input.
 */
export const useInputTouched = <T extends HTMLElement>(originalOnBlur?: (event: FocusEvent<T>) => void) => {
    const [touched, setTouched] = useState(false);

    const onBlur = (event: FocusEvent<T>) => {
        setTouched(true);
        originalOnBlur?.(event);
    };

    return {
        touchedClasses: {
            'bbui-input-touched': touched,
        },
        touchedProps: {
            onBlur,
        },
    };
};
