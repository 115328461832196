import React, { useEffect, useState } from 'react';
import keys from '../__utils__/keys';

export const MOUSE_MODE_CLASS = 'bbui-mouse-mode';

const addMouseModeClass = (container: HTMLElement) => container.classList.add(MOUSE_MODE_CLASS);
const removeMouseModeClass = (container: HTMLElement) => container.classList.remove(MOUSE_MODE_CLASS);

export interface Props {
    container?: HTMLElement;
}

/**
 * Unlike what the name suggests, this doesn't technically manage focus. All it does is add a class
 * based on users' choice of navigation (mouse or keyboard). It's up to each component to decide
 * what to do with this information.
 */

export function withFocusManager(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    WrappedComponent: React.ComponentType<any>,
    onMouseMode: (container: HTMLElement) => void = addMouseModeClass,
    onKeyMode: (container: HTMLElement) => void = removeMouseModeClass
) {
    return function FocusManager({ container, ...rest }: Props) {
        const derivedContainer = container || (typeof document !== 'undefined' ? document.body : null);
        const [usingMouse, setUsingMouse] = useState(true);

        const handleKeyDown = (e: KeyboardEvent) => {
            if (Object.values(keys).some((key) => key.keyCode === e.which)) {
                setUsingMouse(false);
            }
        };

        const handleMouseDown = () => {
            setUsingMouse(true);
        };

        useEffect(() => {
            if (!derivedContainer) {
                // eslint-disable-next-line no-console
                console.error('Invalid container for BBUIFocusManager');
                return;
            }
            if (usingMouse) {
                onMouseMode(derivedContainer);
                derivedContainer.addEventListener('keydown', handleKeyDown);
                return () => derivedContainer.removeEventListener('keydown', handleKeyDown);
            } else {
                onKeyMode(derivedContainer);
                derivedContainer.addEventListener('mousedown', handleMouseDown);
                return () => derivedContainer.removeEventListener('mousedown', handleMouseDown);
            }
        }, [derivedContainer, usingMouse]);

        return <WrappedComponent {...rest} />;
    };
}

const FocusManager = withFocusManager(() => null);

export default FocusManager;
