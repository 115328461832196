import React from 'react';
import cn from 'clsx';

import { StyledCode, StyledPre } from './Code.styles';
import { CodeBlockProps, CodeProps } from './Code.types';

import { FCC } from '../../__utils__/types';

export const CodeBlock: FCC<CodeBlockProps> = (props) => {
    const { className, children, ...rest } = props;

    return (
        <StyledPre className={cn('bbui-code-block', className)} {...rest}>
            <StyledCode>{children}</StyledCode>
        </StyledPre>
    );
};

export const Code: FCC<CodeProps> = (props) => {
    const { className, children, ...rest } = props;

    return (
        <StyledCode className={cn('bbui-code-inline', className)} {...rest}>
            {children}
        </StyledCode>
    );
};
