import React from 'react';
import cn from 'clsx';

import ThemeBoundary from '../ThemeBoundary';
import Button from '../Button';
import TitleBarIconLink, { TitleBarIconlinkDivider } from './TitleBarIconLink';
import TitleBarLinks from './TitleBarLinks';
import svgIcons from '../Icon/svgIcons';

import { TitleBarProps, StaticComponentType } from './TitleBar.types';
import { StyledTitleBar, TitleBarContainer } from './TitleBar.styles';
import { FCC } from '../__utils__/types';

namespace TitleBar {
    export type Props = TitleBarProps;
    export namespace IconLink {
        export type Props = TitleBarIconLink.Props;
        export type MenuItem = TitleBarIconLink.MenuItem;
    }
    export namespace Links {
        export type Props = TitleBarLinks.Props;
    }
}

const DefaultBrandTag: FCC<React.HTMLAttributes<HTMLSpanElement>> = (props) => <span {...props} tabIndex={-1} />;

function forceFocus(element: HTMLElement | null) {
    if (element) {
        const originalTabIndex = element.tabIndex;
        element.tabIndex = 0;
        element.focus();
        element.tabIndex = originalTabIndex;
        element.blur();
    }
}

const SkipNavigation: FCC<{ skipToId?: string }> = ({ skipToId }) => {
    if (!skipToId) return null;
    return (
        <Button
            kind="secondary"
            className="bbui-skipnav"
            onClick={() => forceFocus(document.getElementById(skipToId))}
            tabIndex={0}
        >
            Skip Navigation
        </Button>
    );
};

const TitleBar: FCC<TitleBarProps> & StaticComponentType = ({
    brandRenderer: BrandRenderer = DefaultBrandTag,
    brandLogoSvg,
    children,
    className = '',
    productName,
    envIndicator,
    skipToId,
    width = '100%',
    innerTheme = 'dark',
    ...rest
}) => {
    const Logo = brandLogoSvg || svgIcons['bloomberg-logo'];
    return (
        <ThemeBoundary theme={innerTheme}>
            <StyledTitleBar className={cn('bbui-titlebar', className)} role="banner" {...rest}>
                <TitleBarContainer className="bbui-titlebar-container" width={width}>
                    <div className="bbui-titlebar-brand-wrapper">
                        <BrandRenderer className="bbui-titlebar-brand" tabIndex={0}>
                            <div className="bbui-titlebar-brand-content">
                                <span className="bbui-titlebar-brand-company">
                                    <Logo />
                                </span>
                                <span className="bbui-titlebar-brand-product">{productName}</span>
                            </div>
                        </BrandRenderer>
                        {envIndicator && <span className="bbui-titlebar-env">{envIndicator}</span>}
                        <SkipNavigation skipToId={skipToId} />
                    </div>
                    {children}
                </TitleBarContainer>
            </StyledTitleBar>
        </ThemeBoundary>
    );
};

TitleBar.IconLink = TitleBarIconLink;
TitleBar.IconLinkDivider = TitleBarIconlinkDivider;
TitleBar.Links = TitleBarLinks;

export default TitleBar;
