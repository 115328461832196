import merge from 'lodash/merge';
import type { ThemeConfig } from './types';
import type { RecursivePartial } from '../__utils__/types';

/**
 * Creates a new theme by recursively merging the specified modifications into a copy of the provided base theme.
 */
export function createTheme<ThemeBase, ThemeAdditions = unknown>(
    base: ThemeConfig<ThemeBase>,
    modifications: RecursivePartial<ThemeConfig<ThemeBase>> & ThemeConfig<ThemeBase & ThemeAdditions, ThemeAdditions>
): ThemeConfig<ThemeBase & ThemeAdditions> {
    return (merge({}, base, modifications) as unknown) as ThemeConfig<ThemeBase & ThemeAdditions>;
}

export function hex2rgba(hex: string, opacity = 1) {
    hex = hex.replace('#', '');
    if (hex.length === 3) {
        hex = `${hex[0].repeat(2)}${hex[1].repeat(2)}${hex[2].repeat(2)}`;
    }
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    return `rgba(${r},${g},${b},${opacity})`;
}
