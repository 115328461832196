import React, { createContext, useEffect, useState, useMemo, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import mapValues from 'lodash/fp/mapValues';
import { ConsumerTheme, ThemeConfig } from '../__theme__/types';
import lightTheme from '../__theme__/LightTheme';
import darkTheme from '../__theme__/DarkTheme';

const isFunction = (val: unknown): val is (themeConfig: ThemeConfig<unknown>) => unknown => typeof val === 'function';
const isObject = (val: unknown): val is Record<string, unknown> => typeof val === 'object' && val !== null;

export function makeThemeFromConfig<T>(themeConfig: ThemeConfig<T>): T {
    function evaluateValue(val: unknown): unknown {
        if (isFunction(val)) {
            return evaluateValue(val(themeConfig));
        } else if (isObject(val)) {
            return mapValues(evaluateValue)(val);
        }
        return val;
    }

    return (mapValues(evaluateValue)(themeConfig) as unknown) as T;
}

export interface ThemeProviderProps {
    /**
     * All the components in children will be able to access the theme
     */
    children?: ReactNode;
    /**
     * Name of the active theme
     */
    activeTheme?: string;
    /**
     * Array of ThemeConfig
     */
    themes?: Readonly<ThemeConfig<ConsumerTheme>[]>;
    /**
     * Hanlder for theme change
     */
    onSetActiveTheme?: (newActiveTheme: string) => void;
}

namespace BBUIThemeProvider {
    export type Props = ThemeProviderProps;
}

export const ThemeControlContext = createContext<{
    activeTheme: string;
    themes: ConsumerTheme[];
    setActiveTheme: (newActiveTheme: string) => void;
}>({
    activeTheme: '',
    themes: [],
    setActiveTheme: () => {},
});

function BBUIThemeProvider({
    activeTheme: activeThemeProp,
    onSetActiveTheme,
    themes: themesProp,
    children,
}: ThemeProviderProps) {
    const [activeTheme, setActiveTheme] = useState(activeThemeProp || 'light');

    function handleSetActiveTheme(newActiveTheme: string) {
        onSetActiveTheme?.(newActiveTheme);
        setActiveTheme(newActiveTheme);
    }

    // BBUI version can be accessed on the browser console
    useEffect(() => {
        if (typeof window !== 'undefined' && typeof __BBUI_VERSION__ === 'string') {
            window['__BBUI_VERSION__'] = __BBUI_VERSION__;
        }
    }, []);

    useEffect(() => {
        if (activeThemeProp && activeThemeProp !== activeTheme) {
            handleSetActiveTheme(activeThemeProp);
        }
        // we purposefully only want to trigger this when the active theme prop changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeThemeProp]);

    const themes = useMemo(() => {
        const availableThemeConfigs = (themesProp || []).concat([lightTheme, darkTheme]);
        return availableThemeConfigs.map((themeConfig) => makeThemeFromConfig(themeConfig));
    }, [themesProp]);

    const theme = themes.find((theme) => theme.name === activeTheme);
    if (!theme) throw Error(`Theme not found: "${activeTheme}"`);

    return (
        <ThemeControlContext.Provider value={{ activeTheme, themes, setActiveTheme: handleSetActiveTheme }}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ThemeControlContext.Provider>
    );
}

export default BBUIThemeProvider;
