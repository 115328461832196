import styled from 'styled-components';
import { MOUSE_MODE_CLASS } from '../FocusManager';

export const MenuContainer = styled.div<{ $kind: 'tertiary'; $triggerText: boolean }>`
    display: inline-block;
    min-height: 40px;
    position: relative;

    .bbui-menu-reference {
        align-items: stretch;
        display: flex;
    }

    .bbui-menu-togglebutton {
        /** Just a simple trick to not apply the CSS for IE11 */
        @supports (height: auto) {
            height: auto;
            min-height: 40px;
        }

        .bbui-button-contents {
            display: flex;
        }

        .bbui-icon {
            display: flex;
            font-size: ${({ theme }) => theme.fontSizes.xsmall};
            line-height: 10px;
            margin-top: auto;
            margin-bottom: auto;
            margin-right: ${({ $triggerText }) => ($triggerText ? '7px' : '0px')};
        }

        .bbui-menu-togglebutton-caret-down {
            font-size: 5px;
            margin-left: 5px;
            margin-top: auto;
            margin-bottom: auto;
        }

        &.open {
            color: ${({ theme, $kind }) => theme.Button.colors[$kind].activeText};
            background-color: ${({ theme, $kind }) => theme.Button.colors[$kind].activeBackground};
            border-color: ${({ theme, $kind }) => theme.Button.colors[$kind].activeBorder};
            outline: 2px solid ${({ theme }) => theme.colors.focus};
            z-index: ${({ theme }) => theme.depths.tooltip + 1};
        }

        .${MOUSE_MODE_CLASS} & {
            &.open {
                outline: none;
                z-index: 0;
            }
        }
    }

    .bbui-menu-popper {
        box-shadow: ${({ theme }) => theme.shadows.default};
        z-index: ${({ theme }) => theme.depths.tooltip};
    }

    .bbui-menu-submenu {
        background-color: ${({ theme }) => theme.popovers.colors.background};
        display: none;
        list-style: none;
        margin: 0;
        outline: none;
        padding: 6px 0;
        width: 100%;

        &.open {
            display: block;
        }
    }

    .bbui-menu-menuitem {
        align-items: center;
        color: ${({ theme }) => theme.popovers.colors.text};
        cursor: default;
        display: flex;
        font-size: ${({ theme }) => theme.fontSizes.base};
        padding: 6px 16px;
        position: relative;
        text-align: left;
        white-space: nowrap;

        &.readonly {
            background-color: inherit !important;
            cursor: default;
            font-size: ${({ theme }) => theme.fontSizes.xsmall};
            text-transform: uppercase;
        }

        &.divider {
            border-bottom: 2px solid ${({ theme }) => theme.popovers.colors.divider};
            padding: 0;
            margin: 3px 0;
        }

        &.highlighted {
            background-color: ${({ theme }) => theme.formElements.options.colors.highlight};
        }

        &:active:not(.disabled) {
            color: ${({ theme }) => theme.formElements.options.colors.selectedText};
            background-color: ${({ theme }) => theme.formElements.options.colors.selectedHighlightBackground};
        }

        &.disabled {
            color: ${({ theme }) => theme.formElements.options.colors.disabledText};
            cursor: default;
        }
    }
`;
