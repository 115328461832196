import styled from 'styled-components';
import { stackStyles } from '../Stack';

export const ValidationContentContainer = styled.div`
    width: 100%;
    margin-top: 8px;
    font-size: ${({ theme }) => theme.fontSizes.base};

    &[data-validation-type='success'] {
        color: ${({ theme }) => theme.colors.success.text};
    }
    &[data-validation-type='warning'] {
        color: ${({ theme }) => theme.colors.warning.text};
    }
    &[data-validation-type='error'] {
        color: ${({ theme }) => theme.colors.error.text};
    }
`;

export const StyledFormField = styled.div`
    position: relative;

    & + &:not(.bbui-disable-vertical-margin) {
        margin-top: 20px;
    }

    .bbui-formfield-content {
        position: relative;
        width: 100%;
        display: flex;

        &[data-label-position='top'] {
            flex-direction: column;
            .bbui-formfield-label {
                margin-bottom: 8px;
            }
        }
        &[data-label-position='left'] {
            flex-direction: row;
            .bbui-formfield-label {
                margin-right: 8px;
            }
            .bbui-formfield-content-field {
                flex: 1;
            }
        }
        &[data-label-position='right'] {
            flex-direction: row-reverse;
            justify-content: flex-end;
            .bbui-formfield-label {
                margin-left: 8px;
            }
        }
        &[data-label-position='bottom'] {
            flex-direction: column-reverse;
            .bbui-formfield-label {
                margin-top: 8px;
            }
        }

        &[data-label-position='top'],
        &[data-label-position='bottom'] {
            .bbui-formfield-label {
                width: 100%;
            }
            .bbui-formfield-label-description {
                float: right;
                font-weight: ${({ theme }) => theme.fontWeights.normal};
            }
        }
        &[data-label-position='left'],
        &[data-label-position='right'] {
            .bbui-formfield-label {
                white-space: nowrap;
            }
            .bbui-formfield-label-description {
                display: none;
            }
        }

        .bbui-formfield-label {
            display: inline-block;
            align-self: center;
            flex: none;
            color: ${({ theme }) => theme.colors.text};
        }

        &.bbui-formfield-group {
            .bbui-formfield-label {
                margin-top: 0;
                margin-bottom: 4px;
            }
            .bbui-formfield-content-field {
                ${stackStyles({ space: '8px' })}
            }
        }
    }
`;

export const StyledFormFieldContent = styled.div`
    border: 0;
    padding: 0;
    margin: 0;
    min-width: 0;
    > legend {
        padding: 0;
    }
`;
