import React from 'react';
import cn from 'clsx';
import keys from '../../__utils__/keys';
import { TitleBarIconLinkItemProps } from './TitleBar.IconLink.types';
import Switch from '../../Switch';

const TRIGGER_KEYS = [keys.space.key, keys.enter.key] as const;

/**
 * An individual Button within the BarView
 */
export const TitleBarIconLinkItem = ({
    id,
    className,
    onClick,
    index,
    focusedIndex,
    setFocusedIndex,
    closeMenu,
    readonly,
    label,
    interactive,
    renderNode,
    renderer: Renderer = 'span',
    divider,
    selected,
    ...rest
}: TitleBarIconLinkItemProps) => {
    function handleClick(e: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>) {
        if (e.type === 'keyPress' && !TRIGGER_KEYS.includes((e as React.KeyboardEvent<HTMLElement>).key)) {
            return;
        }
        onClick?.(e);
        if (!readonly && !interactive) {
            closeMenu();
        }
    }

    function handleMouseOver() {
        typeof index === 'number' && setFocusedIndex(index);
    }

    const focused = focusedIndex === index;

    let isSwitch = false;
    let labelNode: string | React.ReactNode = label;

    if (Renderer === 'switch') {
        isSwitch = true;
        const { checked, onChange, defaultChecked, disabled, ...switchRest } = rest as TitleBarIconLinkItemProps & {
            checked?: boolean;
            onChange?: () => void;
            defaultChecked?: boolean;
            disabled?: boolean;
        };
        Renderer = 'div';
        rest = switchRest;
        labelNode = (
            <Switch
                id={id}
                label={label}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
                defaultChecked={defaultChecked}
            />
        );
    }

    return (
        <Renderer
            data-index={index}
            className={cn(className, 'bbui-titlebar-iconlink-menuitem', {
                focused,
                readonly,
                divider,
                selected,
                highlighted: focused,
            })}
            onClick={handleClick}
            onMouseEnter={handleMouseOver}
            onKeyPress={handleClick}
            aria-selected={focused}
            aria-hidden={readonly || divider}
            role={readonly || divider ? undefined : 'menuitem'}
            {...rest}
            id={isSwitch ? undefined : id} // Needs to use the generated id
            tabIndex={focused ? 0 : -1}
        >
            {!divider && labelNode}
        </Renderer>
    );
};

export default TitleBarIconLinkItem;
