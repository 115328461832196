import React, { useState } from 'react';
import { StyledPopover, FlyoutChildrenContainer } from './Flyout.styles';
import { FlyoutProps } from './Flyout.types';

import { FCC } from '../__utils__/types';

namespace Flyout {
    export type Props = FlyoutProps;
}

const FOCUSABLE_SELECTOR =
    'a,frame,iframe,input:not([type=hidden]):not(:disabled):not([style*="display: none"]),select:not(:disabled),textarea:not(:disabled),button:not(:disabled),*[tabindex]';

const getFirstFocusableElement = (element: HTMLDivElement) => {
    return element.querySelector(FOCUSABLE_SELECTOR) as HTMLElement;
};

const Flyout: FCC<FlyoutProps> = ({
    maxWidth = 400,
    duration = 100,
    placement = 'top',
    content,
    children,
    onCreate,
    disableTargetFocus = false,
    disableChildrenWrapper = false,
    ...rest
}) => {
    const [flyoutInstance, setFlyoutInstance] = useState({ hide: () => {}, show: () => {} });
    const flyoutContentRef = React.createRef<HTMLDivElement>();
    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            flyoutInstance.show();
            setTimeout(() => {
                if (flyoutContentRef.current) {
                    getFirstFocusableElement(flyoutContentRef.current).focus();
                }
            }, 0);
        } else if (event.key === 'Escape') {
            flyoutInstance.hide();
        }
    };
    return (
        <StyledPopover
            {...rest}
            interactive={true}
            arrow={true}
            trigger={'click'}
            maxWidth={maxWidth}
            placement={placement}
            duration={duration}
            onCreate={(instance) => {
                onCreate?.(instance);
                setFlyoutInstance(instance);
            }}
            content={
                <div
                    className="flyout-content"
                    tabIndex={-1}
                    ref={flyoutContentRef}
                    onKeyDown={(event) => {
                        if (event.key === 'Escape') {
                            flyoutInstance.hide();
                        }
                    }}
                >
                    {content}
                    <button
                        className="invisible-close"
                        onClick={() => flyoutInstance.hide()}
                        onKeyDown={(event) => {
                            if (event.key === 'Tab' && flyoutContentRef.current) {
                                flyoutContentRef.current.focus();
                            } else if (event.key === 'Enter' || event.key === ' ') {
                                flyoutInstance.hide();
                            }
                        }}
                    >
                        Close
                    </button>
                </div>
            }
        >
            {disableChildrenWrapper ? (
                React.isValidElement(children) ? (
                    React.cloneElement(children, { onKeyDown })
                ) : (
                    children
                )
            ) : (
                <FlyoutChildrenContainer
                    tabIndex={disableTargetFocus ? -1 : 0}
                    aria-label="Flyout"
                    onKeyDown={onKeyDown}
                >
                    {children}
                </FlyoutChildrenContainer>
            )}
        </StyledPopover>
    );
};

export default Flyout;
