import clsx from 'clsx';
import React, { forwardRef } from 'react';

import { StyledSpan } from './Icon.styles';
import svgIcons from './svgIcons';

export type IconNames = keyof typeof svgIcons;

export const ICONS = Object.keys(svgIcons) as IconNames[];

export interface IconProps extends React.HTMLAttributes<HTMLSpanElement> {
    /**
     * Icon name. This will determine which icon will be displayed.
     */
    name: IconNames;
}

namespace Icon {
    export type Props = IconProps & React.RefAttributes<HTMLSpanElement>;
    export type Names = IconNames;
}

/**
 * SVG based icons that do not require loading any other assets (like icon fonts or images).
 */
const Icon = forwardRef<HTMLSpanElement, IconProps>(({ className, name, ...rest }, ref) => {
    const Svg = svgIcons[name];
    if (!Svg) {
        console.error('Invalid icon name', name); // eslint-disable-line
        return null;
    }

    return (
        <StyledSpan className={clsx('bbui-icon', className)} role="img" ref={ref} {...rest}>
            <Svg />
        </StyledSpan>
    );
});

Icon.displayName = 'Icon';

export default Icon;
