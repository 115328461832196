import React from 'react';
import ReactDOM from 'react-dom';
import { NotificationLiveStatusContainer } from './Notification.styles';
import { InnerNotificationObject } from './Notification.types';

export const NotificationScreenReader = ({
    portalRoot,
    notifs,
}: {
    portalRoot: HTMLElement | null;
    notifs?: InnerNotificationObject[];
}) => {
    if (!portalRoot) return null;
    return ReactDOM.createPortal(
        <NotificationLiveStatusContainer
            id="bbui-notification-live-status-container"
            aria-live="polite"
            role="status"
            aria-relevant="additions"
        >
            {notifs?.map((notif) => (
                <span key={notif.id} id={`bbui-notification-live-${notif.id}`}>
                    {`${notif.kind} notification`}: {notif.message}
                    {notif.details && <span>Details: {notif.details}</span>}
                </span>
            ))}
        </NotificationLiveStatusContainer>,
        portalRoot
    );
};

export default NotificationScreenReader;
