import React from 'react';
import cn from 'clsx';
import ThemeBoundary from '../ThemeBoundary';
import FooterLink from './FooterLink';
import FooterLinks from './FooterLinks';
import { FooterProps } from './Footer.types';
import { StyledFooter } from './Footer.styles';

import { FCC } from '../__utils__/types';

namespace Footer {
    export type Props = FooterProps;
    export namespace Link {
        export type Props = FooterLink.Props;
    }
    export namespace Links {
        export type Props = FooterLinks.Props;
    }
}

type StaticComponentType = { Link: typeof FooterLink; Links: typeof FooterLinks };

/**
 * Footer to be displayed at the bottom of the page. It includes the copyright year and some links.
 */
const Footer: FCC<FooterProps> & StaticComponentType = ({
    className = '',
    innerTheme = 'dark',
    copyright,
    companyName = 'Bloomberg L.P.',
    width = '100%',
    children,
    additionalContent,
    ...rest
}) => (
    <ThemeBoundary theme={innerTheme}>
        <StyledFooter className={cn('bbui-footer', className)} $width={width} {...rest}>
            <div className="bbui-footer-content">
                {additionalContent && <div className="bbui-footer-additional-content">{additionalContent}</div>}
                <div className="bbui-footer-copyright">
                    <small>
                        &copy; {copyright} {companyName} All rights reserved.
                    </small>
                </div>
                {children}
            </div>
        </StyledFooter>
    </ThemeBoundary>
);

Footer.Link = FooterLink;
Footer.Links = FooterLinks;

export default Footer;
