import React, { useEffect, useRef } from 'react';
import cn from 'clsx';
import { useSelect, UseSelectState, UseSelectStateChange, UseSelectStateChangeOptions } from 'downshift';
import { usePopper } from 'react-popper';
import Button, { isTriggerKey } from '../Button';
import Icon from '../Icon';
import useForceUpdate from '../__utils__/useForceUpdate';
import type { SplitButtonProps, SplitButtonMenuItem } from './SplitButton.types';
import { SplitButtonContainer } from './SplitButton.styles';

namespace SplitButton {
    export type Props = SplitButtonProps;
    export type MenuItem = SplitButtonMenuItem;
}

/**
 * A hybrid of a button and a menu which contains additional actions.
 */
function SplitButton({
    buttonProps,
    className,
    menuItems,
    disabled = false,
    kind = 'secondary',
    onClick,
    children,
    ...rest
}: SplitButtonProps) {
    const referenceRef = useRef<HTMLDivElement>(null);
    const popperRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLUListElement>(null);
    const isClosing = useRef(false);
    const forceUpdate = useForceUpdate();

    const { styles, attributes } = usePopper(referenceRef.current, popperRef.current, { placement: 'bottom-start' });

    function handleSelect(changes: UseSelectStateChange<string>) {
        const selectedMenuItem = menuItems.find((item) => item.label === changes.selectedItem);
        selectedMenuItem?.onSelect?.();
    }

    function stateReducer(state: UseSelectState<string>, actionAndChanges: UseSelectStateChangeOptions<string>) {
        const updatedState = actionAndChanges.changes;
        switch (actionAndChanges.type) {
            case useSelect.stateChangeTypes.MenuKeyDownArrowDown: {
                return state.highlightedIndex === menuItems.length - 1
                    ? { ...updatedState, highlightedIndex: 0 }
                    : updatedState;
            }
            case useSelect.stateChangeTypes.MenuKeyDownArrowUp: {
                return state.highlightedIndex === 0
                    ? { ...updatedState, highlightedIndex: menuItems.length - 1 }
                    : updatedState;
            }
            default: {
                return updatedState;
            }
        }
    }

    const { getItemProps, getMenuProps, getToggleButtonProps, highlightedIndex, isOpen } = useSelect({
        items: menuItems.map((item) => item.label),
        onSelectedItemChange: handleSelect,
        // We don't want Downshift to keep track of the selectedItem, because it would prevent the item's onSelect
        // handler from being triggered if that item was selected multiple times in a row
        selectedItem: null,
        stateReducer,
    });

    // We want to force a rerender whenever the `isOpen` state changes, because changes to `isOpen` are accompanied
    // by a change in the focused element, and need to re-render the component when the focus changes in order to
    // ensure updated styles are applied.
    useEffect(() => {
        forceUpdate();
    }, [isOpen, forceUpdate]);

    const { parent: ButtonParent = 'div', ...restButtonProps } = { children, onClick, ...buttonProps };

    return (
        <SplitButtonContainer className={cn('bbui-splitbutton', className, kind)} $kind={kind} {...rest}>
            <div ref={referenceRef} className="bbui-splitbutton-reference">
                <ButtonParent className="bbui-splitbutton-buttonparent">
                    <Button {...restButtonProps} kind={kind} disabled={disabled} />
                </ButtonParent>
                <div className={cn('bbui-splitbutton-dividers', { disabled })}>
                    <div className="bbui-splitbutton-bordercontinuation" />
                    <div className="bbui-splitbutton-divider" />
                    <div className="bbui-splitbutton-bordercontinuation" />
                </div>
                <Button
                    className={cn('bbui-splitbutton-togglebutton', {
                        disabled,
                        // Checking that the menu is focused here in addition to using `isOpen` because when the menu
                        // is closed, `isOpen` changes before the focused element does, resulting in a moment where the
                        // toggle button's outline is removed is removed because `isOpen` is false but the toggle button
                        // is not yet the focused element. However, if we only check the focus state, a similar flash
                        // occurs when clicking the button to open the menu, due to the menu being focused slightly
                        // after the mouse is released.
                        open: isOpen || document.activeElement === menuRef.current,
                    })}
                    disabled={disabled}
                    kind={kind}
                    tabIndex={document.activeElement === menuRef.current ? -1 : 0}
                    title={`additional actions menu, ${menuItems.length} total items`}
                    {...getToggleButtonProps({
                        onKeyUp: (e) => {
                            if (isClosing.current) {
                                e.preventDefault(); // Prevent Button from calling onClick when keyUp is fired right after closing the menu by selecting an item
                                isClosing.current = false;
                            }
                        },
                    })}
                >
                    <Icon name="chevron-down" />
                </Button>
            </div>
            <div className="bbui-splitbutton-popper" ref={popperRef} style={styles.popper} {...attributes.popper}>
                <ul
                    className={cn('bbui-splitbutton-menu', { open: isOpen })}
                    {...getMenuProps({
                        ref: menuRef,
                        onKeyDown: (e) => {
                            if (isTriggerKey(e.key)) {
                                isClosing.current = true; // onKeyDown causes downshift to close the menu
                            }
                        },
                    })}
                >
                    {menuItems.map(
                        ({ className, disabled, label, renderer: Renderer = React.Fragment, ...props }, index) => (
                            <li
                                key={label}
                                className={cn('bbui-splitbutton-menuitem', className, {
                                    disabled,
                                    highlighted: highlightedIndex === index,
                                })}
                                aria-label={`${label}, ${index + 1} of ${menuItems.length}`}
                                {...getItemProps({ item: label, disabled, index, ...props })}
                            >
                                <Renderer>{label}</Renderer>
                            </li>
                        )
                    )}
                </ul>
            </div>
        </SplitButtonContainer>
    );
}

export default SplitButton;
