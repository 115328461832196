import styled, { css } from 'styled-components';
import { InputGroupProps } from './InputGroup';

/* eslint-disable-next-line no-unexpected-multiline */
export const StyledInputGroupContainer = styled.div<{
    $disabled?: InputGroupProps['disabled'];
    $validation?: InputGroupProps['validation'];
}>`
    display: flex;
    height: 40px;
    background-color: ${({ theme }) => theme.formElements.colors.background};
    border: 1px solid ${({ theme }) => theme.formElements.colors.border};
    padding: 1px;

    .bbui-input {
        background-color: transparent;
        border: none;
        height: 36px;
        flex: 1;
        padding: 0;

        &:first-child {
            padding: 0 11px;
        }
    }

    &:focus-within {
        border-color: ${({ theme }) => theme.formElements.colors.focus};
        outline: none;
        border-width: 2px;
        padding: 0;
    }

    ${({ $disabled }) =>
        $disabled
            ? css`
                  border: none;
                  background-color: ${({ theme }) => theme.formElements.colors.disabledBackground};
                  color: ${({ theme }) => theme.formElements.colors.readOnly};
              `
            : ''}

    ${({ $validation, theme }) =>
        $validation && theme.colors[$validation]
            ? css`
                  border-color: ${theme.colors[$validation].graphic};
                  border-width: 2px;
                  padding: 0;

                  &:focus-within {
                      border-color: ${theme.colors[$validation].graphic};
                  }
              `
            : ''}
`;

export const StyledInputGroupAddonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;

    > span.text,
    > div,
    > label,
    > .bbui-icon {
        padding: 0 12px;
    }

    > label > input[type='checkbox'] + span {
        margin-left: 0;
        margin-right: 5px;
    }

    > .bbui-button:not(.tertiary) {
        margin-right: -2px;
    }
`;
