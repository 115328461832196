import { css } from 'styled-components';
import type { StackProps } from './Stack.types';

const columnStackStyles = ({ space }: StackProps) => css`
    width: 100%;

    > * {
        margin-bottom: 0;
        margin-top: 0;
    }

    > * + * {
        margin-top: ${space};
    }

    > .bbui-stack-expand {
        margin-bottom: auto;
    }
`;

const rowStackStyles = ({ space }: StackProps) => css`
    height: 100%;

    > * {
        margin-left: 0;
        margin-right: 0;
    }

    > * + * {
        margin-left: ${space};
    }

    > .bbui-stack-expand {
        margin-right: auto;
    }
`;

const alignmentMap = {
    start: 'flex-start',
    middle: 'center',
    end: 'flex-end',
};

export const stackStyles = ({ alignment = 'start', direction = 'column', space = '1rem' }: StackProps = {}) => css`
    align-items: ${alignmentMap[alignment]};
    display: flex;
    flex-direction: ${direction};
    ${{ column: columnStackStyles, row: rowStackStyles }[direction]?.({ alignment, direction, space })}
`;
