import { css } from 'styled-components';

export const OverlayStyles = css`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: ${({ theme }) => theme.depths.overlay};
    transition: background-color 0.25s ease-in-out;

    &.shadow {
        background: ${({ theme }) => theme.Overlay.colors.background};
    }

    display: flex;
    justify-content: center;
    align-items: center;

    &.side-top {
        align-items: flex-start;
    }
    &.side-bottom {
        align-items: flex-end;
    }
    &.side-left {
        justify-content: flex-start;
    }
    &.side-right {
        justify-content: flex-end;
    }

    .bbui-overlay-container {
        color: ${({ theme }) => theme.colors.text};
        background-color: ${({ theme }) => theme.colors.background};
        z-index: ${({ theme }) => theme.depths.overlay + 1};

        border: 1px solid ${({ theme }) => theme.colors.backgroundShades.strong};

        &.depth-1 {
            border: none;
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
        }
        &.depth-2 {
            border: none;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
        }
        &.depth-3 {
            border: none;
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.4);
        }

        &:focus {
            outline: none;
        }
    }
`;
