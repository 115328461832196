import styled from 'styled-components';
import Popover from '../Popover/Popover';
import { MOUSE_MODE_CLASS } from '../FocusManager';

export const FlyoutChildrenContainer = styled.div`
    display: inline-block;

    .${MOUSE_MODE_CLASS} &:focus {
        outline: none;
    }
`;

export const StyledPopover = styled(Popover)`
    padding: 20px;
    text-align: left;
    line-height: 1.4;

    .invisible-close {
        color: ${({ theme }) => theme.colors.text};
        border: none;
        background: transparent;
        font-size: 0;
        display: inline-block;
        position: absolute;
        top: -14px;
        right: -10px;
        &:focus {
            font-size: 0.8em;
            outline: 2px solid ${({ theme }) => theme.colors.focus};
        }
    }

    .flyout-content {
        max-height: 600px;
        overflow-y: scroll;
        padding: 2px;
        font-size: ${({ theme }) => theme.fontSizes.base};
        &:focus {
            outline: none;
        }
    }
`;
