import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import range from 'lodash/range';

import NotificationGroup from './NotificationGroup';
import { NotificationContainer } from './Notification.styles';
import { NotificationKind, InnerNotificationObject } from './Notification.types';

const STEP = 0.05;
const INTERSECTION_THRESHOLDS = range(0, 1, STEP).concat(1);

function getHeader(customHeaderSelector?: string) {
    if (typeof window !== 'undefined' && document) {
        const titlebar = document.querySelector('header > .bbui-titlebar');
        const navbar = document.querySelector(customHeaderSelector || 'nav.bbui-navbar');
        return navbar || titlebar || null;
    }
    return null;
}

function initIntersectionObserver(callback: IntersectionObserverCallback, root?: Element | null) {
    // Not supported in IE
    if (!('IntersectionObserver' in window)) {
        return null;
    }
    const options: IntersectionObserverInit = {
        root: root || document.body,
        rootMargin: '0px',
        threshold: INTERSECTION_THRESHOLDS,
    };
    return new IntersectionObserver(callback, options);
}

export const Notifications = ({
    errors,
    warnings,
    successes,
    infos,
    handleDismissMessages,
    portalRoot,
    customHeaderSelector,
}: {
    errors: InnerNotificationObject[];
    warnings: InnerNotificationObject[];
    successes: InnerNotificationObject[];
    infos: InnerNotificationObject[];
    portalRoot: HTMLElement | null;
    handleDismissMessages: (kind: NotificationKind) => void;
    customHeaderSelector?: string;
}) => {
    const [headerBottom, setHeaderBottom] = useState(0);

    useEffect(() => {
        const callback: IntersectionObserverCallback = (entries) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                    setHeaderBottom(0);
                } else {
                    const bottom = entry.boundingClientRect.bottom;
                    setHeaderBottom(bottom > 0 ? bottom : 0);
                }
            });
        };
        const observer = initIntersectionObserver(callback, portalRoot);
        const header = getHeader(customHeaderSelector);
        if (header) {
            const bottom = header.getBoundingClientRect().bottom;
            setHeaderBottom(bottom > 0 ? bottom : 0);
            observer?.observe(header);
        }
        return () => observer?.disconnect();
    }, [portalRoot, customHeaderSelector]);

    if (!portalRoot) return null;
    return ReactDOM.createPortal(
        <NotificationContainer
            id="bbui-notification-container"
            style={{ top: `${headerBottom}px` }}
            aria-label="Notification Container"
        >
            <NotificationGroup
                kind="error"
                id="bbui-error-notification-container"
                notifications={errors}
                onDismiss={() => handleDismissMessages('error')}
            />
            <NotificationGroup
                kind="warning"
                id="bbui-warning-notification-container"
                notifications={warnings}
                onDismiss={() => handleDismissMessages('warning')}
            />
            <NotificationGroup
                kind="success"
                id="bbui-success-notification-container"
                notifications={successes}
                onDismiss={() => handleDismissMessages('success')}
            />
            <NotificationGroup
                kind="info"
                id="bbui-info-notification-container"
                notifications={infos}
                onDismiss={() => handleDismissMessages('info')}
            />
        </NotificationContainer>,
        portalRoot
    );
};

export default Notifications;
