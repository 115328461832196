import React from 'react';
import cn from 'clsx';

import Icon, { IconNames } from '../Icon/Icon';
import { BannerContainer, BannerMessage, StyledBanner } from './Banner.styles';
import { BannerProps } from './Banner.types';
import Button from '../Button';
import { FCC } from '../__utils__/types';

namespace Banner {
    export type Props = BannerProps;
}

const getIconNameForType = (kind: 'info' | 'warning' | 'error' | 'environment'): IconNames => {
    const icons: { [kind: string]: IconNames } = {
        info: 'exclamation-circle',
        warning: 'exclamation-triangle',
        error: 'exclamation-diamond',
    };
    return icons[kind];
};

const Banner: FCC<BannerProps> = ({
    className = '',
    kind = 'info',
    message,
    link,
    onClose,
    width = '100%',
    ...rest
}) => {
    const preventClose = ['error', 'environment'].includes(kind);
    const renderCompact = kind === 'environment';

    const handleEnter = (e: React.KeyboardEvent) => {
        if (onClose && e.key === 'Enter') {
            onClose();
        }

        return false;
    };

    return (
        <StyledBanner
            className={cn('bbui-banner', kind, className)}
            kind={kind}
            compact={renderCompact}
            aria-label={`${kind} message: ${typeof message === 'string' ? message : ''}`}
            aria-live="polite"
            role="alert"
            tabIndex={0}
            {...rest}
        >
            <BannerContainer className="bbui-banner-container" width={width}>
                <div className="bbui-banner-content">
                    {!renderCompact && <Icon className="bbui-banner-icon" name={getIconNameForType(kind)} />}
                    <BannerMessage className="bbui-banner-message" compact={renderCompact}>
                        {message}
                    </BannerMessage>
                    {link}
                </div>
                {!preventClose && onClose && (
                    <Button
                        aria-label="dismiss banner"
                        data-banner-kind={kind}
                        className="bbui-banner-close-icon"
                        icon="x"
                        kind="tertiary"
                        onClick={onClose}
                        onKeyPress={handleEnter}
                    />
                )}
            </BannerContainer>
        </StyledBanner>
    );
};

export default Banner;
