import styled from 'styled-components';

import { MOUSE_MODE_CLASS } from '../FocusManager/FocusManager';

export const StyledCheckboxContainer = styled.div`
    color: ${({ theme }) => theme.colors.text};

    .bbui-checkbox-label {
        cursor: default;
        position: relative;
        display: inline-block;
        height: 24px;
        padding: 4px 0;
    }

    .bbui-checkbox-icon {
        display: inline-block;
        height: 1em;
        width: 1em;
    }

    .bbui-checkbox-label-text {
        margin-left: 8px;
        font-size: ${({ theme }) => theme.fontSizes.base};
    }

    input[type='checkbox']:focus ~ .bbui-checkbox-icon {
        box-shadow: 0 0 0 2px ${({ theme }) => theme.formElements.colors.focus};
    }

    .${MOUSE_MODE_CLASS} && {
        input[type='checkbox']:focus ~ .bbui-checkbox-icon {
            box-shadow: none;
        }
    }

    input[type='checkbox']:disabled {
        & ~ .bbui-checkbox-label-text {
            color: ${({ theme }) => theme.colors.disabledText};
        }

        & ~ * {
            cursor: default;
        }

        & ~ .bbui-checked-icon,
        & ~ .bbui-indeterminate-icon,
        & ~ .bbui-unchecked-icon {
            color: ${({ theme }) => theme.colors.disabledText};
        }
    }

    input[type='checkbox'] ~ .bbui-checked-icon,
    input[type='checkbox'] ~ .bbui-indeterminate-icon {
        display: none;
    }

    input[type='checkbox'] ~ .bbui-unchecked-icon {
        display: inline-block;
    }

    input[type='checkbox']:checked:not(:indeterminate) ~ .bbui-checked-icon {
        display: inline-block;
    }

    input[type='checkbox']:indeterminate:not(:checked) ~ .bbui-indeterminate-icon {
        display: inline-block;
    }

    input[type='checkbox']:checked:not(:indeterminate):not(:disabled) ~ .bbui-checked-icon,
    input[type='checkbox']:indeterminate:not(:checked):not(:disabled) ~ .bbui-indeterminate-icon {
        color: ${({ theme }) => theme.formElements.colors.action};
    }

    input[type='checkbox']:checked ~ .bbui-unchecked-icon,
    input[type='checkbox']:indeterminate ~ .bbui-unchecked-icon {
        display: none;
    }

    input {
        opacity: 0;
        position: absolute;
    }
`;
